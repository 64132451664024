import { useState, useEffect } from "react";
import request from "../utils/request";


export const GetDoc = ({ children, reference, id }) => {
  const [record, setRecord] = useState(null);

  const getRefDocFromApi = () => {
      request({
          method: "get",
          url: `/${reference}/${id}`
      })
      .then(res => {
          setRecord(res);
      })
      .catch(error => {
          console.log("ERROR GETTING RECORD", error);
      });
  };  
          
  useEffect(() => {
      if (!record) {
          getRefDocFromApi();
      }    
  }, [record]);


  return record ? children(record) : '';
};