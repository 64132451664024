import React, { Component } from "react";
import TextField from "@material-ui/core/TextField";
import { translate } from "react-admin";
import logout from "../utils/session";
import { token } from '../utils/storage';
import {
    Grid,
    Card,
    CardContent,
    Button,
    Typography
} from "@material-ui/core";
import "../css/ChangePassword.css";
import req from "../utils/request";

const isPwdStrong = value => {
    var pass = value;

    var reg = new RegExp(
        "^(?=.*[a-zA-Z0-9])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})"
    );
    var test = reg.test(pass);

    if (!test) {
        return false;
    }
    return true;
};

class ChangePassword extends Component {
    state = {
        oldPassword: "",
        newPassword: "",
        confirmPass: "",
        isValid: true,
        error: ""
    };

    validatePwd = () => {
        if (this.state.newPassword.length < 8) {
            this.setState({ error: "pwd.min", isValid: false });
        } else if (this.state.newPassword.length > 30) {
            this.setState({ error: "pwd.max", isValid: false });
        } else if (!isPwdStrong(this.state.newPassword)) {
            this.setState({ error: "pwd.strong", isValid: false });
        } else if (this.state.newPassword !== this.state.confirmPass ) {
            this.setState({ error: "pwd.pass_not_match", isValid: false });
        } else if (this.state.newPassword === this.state.oldPassword ) {
            this.setState({ error: "pwd.pass_not_same", isValid: false });
        } else {
            this.setState({ error: "", isValid: true });
        }
    };

    resetPassword = () => {
        const { oldPassword, newPassword } = this.state;

        req({
            method: "put",
            url: `/users/change-password`,
            data: {
                oldPassword: oldPassword,
                newPassword: newPassword
            },
            config: { headers: { "Content-Type": "multipart/form-data" } }
        })
            .then(res => {
                console.log("OK: change password", res);
                alert(this.props.translate("changepassword_finish"));
                return logout(token);
            })
            .catch(err => {
                console.log("ERR: ", err);
                alert(this.props.translate("changepassword_fail"));
                err && err.error && err.error.message && alert(err.error.message);
            });
    };

    handleSubmit = event => {
        this.resetPassword();
        event.preventDefault();
    };

    handleChange = async event => {
        if(event.target.name === "newPassword"){
            await this.setState({ newPassword: event.target.value });
        } else if (event.target.name === "oldPassword") {
            await this.setState({ oldPassword: event.target.value });
        } else if (event.target.name === "confirmPassword") {
            await this.setState({ confirmPass: event.target.value });
        }
        await this.validatePwd();
    };

    componentDidMount() {
        const {
            match: { params }
        } = this.props;

        if(params.resetPassKey){
            this.setState({ resetPassKey: params.resetPassKey });
        } else {
            this.setState({ token: token });
        }
        this.validatePwd();
    }

    render() {
        const { isValid, error } = this.state;
        const { translate } = this.props;
        const styles = {
            card: {
                width: "30%",
                marginTop: "50px",
                paddingBottom: "10px"
            },
            tileCard: {
                paddingTop: 20,
                fontSize: "22px",
                fontFamily: "Roboto Slab",
                color: "#5e6ab1"
            },
            cardContent: {
                display: "grid",
                justifyContent: "center",
                alignItems: "center"
            }
        };

        return (
            <Grid
                container
                spacing={0}
                direction="column"
                alignItems="center"
                justify="center"
            >
                <Card style={styles.card}>
                    <CardContent style={styles.cardContent}>
                        <Typography style={styles.tileCard}>
                            {translate("new_password")}
                        </Typography>
                        <div className="formChangePassword">
                            <TextField
                                name="oldPassword"
                                label={translate("old_password")}
                                type="password"
                                onChange={this.handleChange}
                                margin="normal"
                            />
                            <TextField
                                name="newPassword"
                                label={translate("new_password")}
                                type="password"
                                onChange={this.handleChange}
                                margin="normal"
                            />
                            {error && (
                                <span style={{ color: "red" }}>{translate(error)}</span>
                            )}
                            <TextField
                                name="confirmPassword"
                                label={translate("confirm_password")}
                                type="password"
                                onChange={this.handleChange}
                                margin="normal"
                            />
                            <Button
                                variant="raised"
                                color="primary"
                                disabled={!isValid}
                                onClick={this.handleSubmit}
                            >
                                {translate("restore_passwod")}
                            </Button>
                        </div>
                    </CardContent>
                </Card>
            </Grid>
        );
    }
}

export default translate(ChangePassword);
