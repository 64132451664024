import React, { Component } from 'react';
import './css/Header.css';
import NavBar from './components/NavBar';

export default class Header extends Component {
    render() {
        return (
            <React.Fragment>
                <NavBar/>
            </React.Fragment>
        );
    }
}