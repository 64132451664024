import React, { Component } from "react";
import { Grid, Card, Typography } from "@material-ui/core";
import { Bar, Pie } from "react-chartjs-2";
import request from '../../../../utils/request';
import "./Visualizations.css";

class Visualizations extends Component {
    componentDidMount() {
        this.getVisualization();
    }

    state = {
        total: 0,
        tortureType: {},
        aggressors: {},
        ages: {
            "<18": 0,
            "18-24": 0,
            "25-30": 0,
            "31-35": 0,
            "36-49": 0,
            "50-60": 0,
            ">61": 0
        },
        genders: {},
        tortureOrMaltreatment: {},
        maltreatmentType: {}
    };

    getVisualization() {
        request({
            method: 'get',
            url: `/reports/visualizations_public`
        })
        .then(res => {
            this.setState({
                total: res.total,
                tortureType: res.tortureType,
                aggressors: res.aggressors,
                ages: res.ages,
                genders: res.genders,
                tortureOrMaltreatment: res.tortureOrMaltreatment,
                maltreatmentType: res.maltreatmentType,
            });
        }).catch(error => console.log("getVisualization ERROR:" + error));
    }

    render() {
        let tortureOrMaltreatment = this.state.tortureOrMaltreatment;
        const dataTortureOrMaltreatment = {
            labels: Object.keys(tortureOrMaltreatment),
            datasets: [
                {
                    label: "Por tipo de hecho",
                    backgroundColor: ["#E6333A", "#3A396E"],
                    data: Object.values(tortureOrMaltreatment)
                }
            ]
        };
        
        let maltreatmentType = this.state.maltreatmentType;
        const dataMaltreatmentType = {
            labels: Object.keys(maltreatmentType),
            datasets: [
                {
                    backgroundColor: [
                        "#E6333A",
                        "#3A396E",
                        "#EF7A7D",
                        "#112945",
                        "#fecb53",
                        "#4c2b36"
                    ],
                    data: Object.values(maltreatmentType)
                }
            ]
        };

        let genders = this.state.genders;
        const dataGender = {
            labels: Object.keys(genders),
            datasets: [
                {
                    label: "Por Sexo",
                    backgroundColor: ["#3A396E", "#E6333A"],
                    data: Object.values(genders)
                }
            ]
        };

        let aggressors = this.state.aggressors;
        const dataAggressors = {
            labels: Object.keys(aggressors),
            datasets: [
                {
                    backgroundColor: [
                        "#E6333A",
                        "#3A396E",
                        "#EF7A7D",
                        "#112945",
                        "#fecb53",
                        "#4c2b36",
                        "#856a5d",
                        "#d58936",
                        "#177e89",
                        "#0b0033"
                    ],
                    data: Object.values(aggressors)
                }
            ]
        };

        let tortureType = this.state.tortureType;
        const dataTortureTypes = {
            labels: Object.keys(tortureType),
            datasets: [
                {
                    backgroundColor: [
                        "#E6333A",
                        "#3A396E",
                        "#EF7A7D",
                        "#112945",
                        "#fecb53",
                        "#4c2b36"
                    ],
                    data: Object.values(tortureType)
                }
            ]
        };

        let ages = this.state.ages;
        const dataAges = {
            labels: Object.keys(ages),
            datasets: [
                {
                    backgroundColor: [
                        "#E6333A",
                        "#3A396E",
                        "#EF7A7D",
                        "#112945",
                        "#fecb53",
                        "#4c2b36"
                    ],
                    data: Object.values(ages)
                }
            ]
        };

        const styles = {
            card: {
                width: "75%"
            },
            tileCard: {
                padding: 20,
                textAlign: "center",
                fontSize: "18px",
                color: "#5e6ab1"
            }
        };

        return (
            <Grid
                container
                spacing={0}
                direction="column"
                alignItems="center"
                justify="center"
            >
                <Card style={styles.card}>
                    <Typography style={styles.tileCard}>
                        Total de reportes: {this.state.total}
                    </Typography>
                </Card>
                <hr />
                <Card style={styles.card}>
                    <div className="myChart">
                        <div className="titleChart">Tipo de hecho</div>
                        <Pie
                            data={dataTortureOrMaltreatment}
                            options={{
                                legend: {
                                    position: "bottom",
                                    labels: {
                                        fontColor: "#233771",
                                        fontFamily: "Roboto"
                                    }
                                },
                                tooltips: {
                                    callbacks: {
                                        label: function(tooltipItem, data) {
                                            var dataset =
                                                data.datasets[
                                                    tooltipItem.datasetIndex
                                                ];
                                            var meta =
                                                dataset._meta[
                                                    Object.keys(
                                                        dataset._meta
                                                    )[0]
                                                ];
                                            var total = meta.total;
                                            var currentValue =
                                                dataset.data[tooltipItem.index];
                                            var percentage = parseFloat(
                                                (
                                                    (currentValue / total) *
                                                    100
                                                ).toFixed(1)
                                            );
                                            return (
                                                currentValue +
                                                " (" +
                                                percentage +
                                                "%)"
                                            );
                                        },
                                        title: function(tooltipItem, data) {
                                            return data.labels[
                                                tooltipItem[0].index
                                            ];
                                        }
                                    }
                                }
                            }}
                        />
                    </div>
                </Card>
                <hr />
                <hr />
                <Card style={styles.card}>
                    <div
                        className="myChart"
                        style={{ height: 300, padding: 20 }}
                    >
                        <div className="titleChart">Por tipo de tortura</div>
                        <Bar
                            data={dataTortureTypes}
                            width={300}
                            height={500}
                            options={{
                                maintainAspectRatio: false,
                                scales: {
                                    xAxes: [
                                        {
                                            ticks: {
                                                fontColor: "#233771",
                                                fontFamily: "Roboto"
                                            }
                                        }
                                    ],
                                    yAxes: [
                                        {
                                            ticks: {
                                                min: 0,
                                                beginAtZero: true,
                                                precision: 0,
                                                fontColor: "#233771",
                                                fontFamily: "Roboto"
                                            }
                                        }
                                    ]
                                },
                                legend: {
                                    display: false,
                                    labels: {
                                        fontColor: "#233771",
                                        fontFamily: "Roboto"
                                    }
                                }
                            }}
                        />
                    </div>
                </Card>
                <Card style={styles.card}>
                    <div
                        className="myChart"
                        style={{ height: 300, padding: 20 }}
                    >
                        <div className="titleChart">Por tipo de maltrato</div>
                        <Bar
                            data={dataMaltreatmentType}
                            width={300}
                            height={500}
                            options={{
                                maintainAspectRatio: false,
                                scales: {
                                    xAxes: [
                                        {
                                            ticks: {
                                                fontColor: "#233771",
                                                fontFamily: "Roboto"
                                            }
                                        }
                                    ],
                                    yAxes: [
                                        {
                                            ticks: {
                                                min: 0,
                                                beginAtZero: true,
                                                precision: 0,
                                                fontColor: "#233771",
                                                fontFamily: "Roboto"
                                            }
                                        }
                                    ]
                                },
                                legend: {
                                    display: false,
                                    labels: {
                                        fontColor: "#233771",
                                        fontFamily: "Roboto"
                                    }
                                }
                            }}
                        />
                    </div>
                </Card>
                <hr />
                <Card style={styles.card}>
                    <div
                        className="myChart"
                        style={{ height: 300, padding: 20 }}
                    >
                        <div className="titleChart">Por agresores</div>
                        <Bar
                            data={dataAggressors}
                            width={300}
                            height={500}
                            options={{
                                maintainAspectRatio: false,
                                scales: {
                                    xAxes: [
                                        {
                                            ticks: {
                                                fontColor: "#233771",
                                                fontFamily: "Roboto"
                                            }
                                        }
                                    ],
                                    yAxes: [
                                        {
                                            ticks: {
                                                min: 0,
                                                beginAtZero: true,
                                                precision: 0,
                                                fontColor: "#233771",
                                                fontFamily: "Roboto"
                                            }
                                        }
                                    ]
                                },
                                legend: {
                                    display: false,
                                    labels: {
                                        fontColor: "#233771",
                                        fontFamily: "Roboto"
                                    }
                                }
                            }}
                        />
                    </div>
                </Card>
                <hr />
                <Card style={styles.card}>
                    <div
                        className="myChart"
                        style={{ height: 300, padding: 20 }}
                    >
                        <div className="titleChart">Por edades</div>
                        <Bar
                            data={dataAges}
                            width={300}
                            height={500}
                            options={{
                                maintainAspectRatio: false,
                                scales: {
                                    xAxes: [
                                        {
                                            ticks: {
                                                fontColor: "#233771",
                                                fontFamily: "Roboto"
                                            }
                                        }
                                    ],
                                    yAxes: [
                                        {
                                            ticks: {
                                                min: 0,
                                                beginAtZero: true,
                                                precision: 0,
                                                fontColor: "#233771",
                                                fontFamily: "Roboto"
                                            }
                                        }
                                    ]
                                },
                                legend: {
                                    display: false,
                                    labels: {
                                        fontColor: "#233771",
                                        fontFamily: "Roboto"
                                    }
                                }
                            }}
                        />
                    </div>
                </Card>
                <hr />
                <Card style={styles.card}>
                    <div className="myChart">
                        <div className="titleChart">Por género</div>
                        <Pie
                            data={dataGender}
                            options={{
                                legend: {
                                    position: "bottom",
                                    labels: {
                                        fontColor: "#233771",
                                        fontFamily: "Roboto"
                                    }
                                },
                                tooltips: {
                                    callbacks: {
                                        label: function(tooltipItem, data) {
                                            var dataset =
                                                data.datasets[
                                                    tooltipItem.datasetIndex
                                                ];
                                            var meta =
                                                dataset._meta[
                                                    Object.keys(
                                                        dataset._meta
                                                    )[0]
                                                ];
                                            var total = meta.total;
                                            var currentValue =
                                                dataset.data[tooltipItem.index];
                                            var percentage = parseFloat(
                                                (
                                                    (currentValue / total) *
                                                    100
                                                ).toFixed(1)
                                            );
                                            return (
                                                currentValue +
                                                " (" +
                                                percentage +
                                                "%)"
                                            );
                                        },
                                        title: function(tooltipItem, data) {
                                            return data.labels[
                                                tooltipItem[0].index
                                            ];
                                        }
                                    }
                                }
                            }}
                        />
                    </div>
                </Card>
            </Grid>
        );
    }
}

export default Visualizations;