import React, { useState, useEffect } from "react";
import { translate } from "react-admin";
import { Button } from '@material-ui/core';
import { withStyles } from '@material-ui/core';
import CircularProgress from "@material-ui/core/CircularProgress";
import req from "../utils/request";


const OtpDisableBtn = ({ record, translate, classes, ...props }) => {
  let [ isLoading, setIsLoading ] = useState(false);
  let [ userData, setUserData ] = useState(record);

  const otpDisable = () => {
    setIsLoading(true);
    req({
      method: "get",
      url: `/users/otpDisable/${userData.id}`
    })
        .then(res => {
          setUserData({ ...userData, otpEnabled: false });
          setIsLoading(false);
        })
        .catch(error => {
          console.error(error);
          setIsLoading(false);
          alert('El usuario debe ser SuperAdmin');
        });
  }

  return <div className="">
    {userData ? (<>
      {userData.otpEnabled ? (<>
        Funcionalidad configurada
        <Button variant="outlined" color="secondary" onClick={otpDisable}>
          {isLoading && (
            <CircularProgress size={25} thickness={2} />
          )}
          Desactivar
        </Button>
      </>) : (<>
        <Button variant="outlined" color="secondary" disabled={ true }>
          Desactivado
        </Button>
      </>)}
    </>) : ''}
  </div>
};

export default translate(OtpDisableBtn);
