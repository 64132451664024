import React from "react";
import Coordinator from "./roles/Coordinator";
import Defender from "./roles/Defender";
import Observer from "./roles/Observer";
import Public from "./roles/Public";
import { role } from "./utils/storage";
import request from "./utils/request";

class App extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: ''
        };
    }

    componentDidMount() {
        // // Only when role exist.
        if(role){
            request({
                method: "get",
                url: `/settings/all`
            }).then(res => {
                console.log(res);
                this.setState({ lang: res['languages'].split(',') });
            })
        }
    }

    render() {
        const token = this.state.data;
        token && console.log("## Token from remote call:", token);
        switch (role) {
            case "coordinator":
                return <Coordinator lang = {this.state && this.state.lang} />;
            case "defender":
                return <Defender lang = {this.state && this.state.lang} />;
            case "observer":
                return <Observer lang = {this.state && this.state.lang} />;
            default:
                return <Public />;
        }
    }
}

export default App;