import _ from 'lodash';
import React from 'react';
import { SelectInput } from 'react-admin';

const SelectInputDepartament = props => (
    <SelectInput 
        {...props} 
        resettable
        source={props.source} 
        choices={_.sortBy([
            { id: 0, name: 'Alto Paraguay' },
            { id: 1, name: 'Alto Paraná' },
            { id: 2, name: 'Amambay' },
            { id: 3, name: 'Boquerón' },
            { id: 4, name: 'Caaguazú' },
            { id: 5, name: 'Caazapá' },
            { id: 6, name: 'Canindeyú' },
            { id: 7, name: 'Central' },
            { id: 8, name: 'Concepción' },
            { id: 9, name: 'Cordillera' },
            { id: 10, name: 'Guairá' },
            { id: 11, name: 'Itapúa' },
            { id: 12, name: 'Misiones' },
            { id: 13, name: 'Ñeembucú' },
            { id: 14, name: 'Paraguarí' },
            { id: 15, name: 'Presidente' },
            { id: 16, name: 'San Pedro' },
            { id: 17, name: 'Capital' },
        ], ['name'])}
        options={{ labelposition: "left" }}
    />
);

export default SelectInputDepartament;