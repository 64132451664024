import React from 'react';
import { ReferenceInput, TextInput, SelectInput, SimpleForm, FunctionField, Edit, Toolbar, SaveButton, required, maxLength, minValue, BooleanInput } from 'react-admin';
import TextInputEmail from './fields/TextInputEmail';
import { withStyles } from '@material-ui/core';
import { userId } from '../utils/storage';
import OtpDisableBtn from './OtpDisableBtn';

const toolbarStyles = {
    toolbar: {
        display: 'flex',
        justifyContent: 'space-between',
    },
};

const CustomToolbar = withStyles(toolbarStyles)(props => (
    <Toolbar {...props}>
        <SaveButton />
    </Toolbar>
));

const AccountEdit = props => {
    return (
        <Edit {...props}>
            <SimpleForm toolbar={<CustomToolbar />}>
                <TextInput source="firstname" validate={required()} autoFocus />
                <TextInput source="lastname" validate={required()} />

                {/* Institution */}
                <ReferenceInput
                    source="institutionId"
                    reference="institutions"
                    validate={required()}
                    sort={{ field: "name", order: "ASC" }}
                    perPage={350}
                >
                    <SelectInput optionText="name" />
                </ReferenceInput>

                <TextInput source="position" validate={maxLength(50)} />
                <TextInput source="employer" validate={maxLength(50)} />
                <TextInput source="number" validate={[required()]} />
                <SelectInput
                    source="role"
                    choices={[
                        { id: "defender", name: "Defensor" },
                        { id: "coordinator", name: "Coordinador" },
                        { id: "observer", name: "Veedor" }
                    ]}
                    validate={required()}
                />
                <TextInputEmail source="email" />
                {(
                    !(
                        (userId == props.id) // not auto disable
                        || (props.id == 1) // not disable superadmin
                    )
                ) ? (
                    <BooleanInput source="isActive" />
                ) : ''}
                {userId == 1 ? (
                    <FunctionField
                    source="otp_enabled"
                    render={record => {
                        return <OtpDisableBtn record={record} />
                    }}
                    />
                ) : null}

            </SimpleForm>
        </Edit>
    );
};

export default AccountEdit;