import React, { Component } from 'react';
import {
	Row,
	Col,
	Container
} from 'reactstrap';
import logo_MDP from '../../../assets/logos/MDP.png';
import logo_MNP from '../../../assets/logos/MNP_transp_blanco.png';
import logo_MJ from '../../../assets/logos/Logo_MJ.png';
import logo_Tedic from '../../../assets/logos/Tedic.png';
import logo_Gitlab from '../../../assets/logos/Gitlab.png';
import './css/Footer.css';

export default class Footer extends Component {
	render() {
		const styles = {
			body: {
				backgroundColor: '#000',
    			color: '#FFF',
				paddingLeft: '10%',
				paddingBottom: '20px'
			},
			column_logos: {
				padding: '20px 0px 0px 0px'
			},
			column_links: {
				textAlign: 'right',
				fontFamily: 'Roboto',
				padding: '20px 0px 0px 0px'
			},
			links: {
				color: '#FFF',
    			textDecoration: 'none'
			},
			column_git: {
				padding: '15px 0px 0px 0px'
			}
		};

		return (
			<div style={styles.body}>
				<React.Fragment>
					<Container >
						<Row>
							<Col style={styles.column_logos}  md="auto">
								<a href="http://www.mdp.gov.py/" target="_blank" rel="noopener noreferrer">
									<img className="img" src={logo_MDP} height="65px" alt="Logo MDP" />
								</a>
								<a href="https://www.ministeriodejusticia.gov.py/" target="_blank" rel="noopener noreferrer">
									<img className="img" src={logo_MJ} height="65px" alt="Logo MJ" />
								</a>
								<a href="http://www.mnp.gov.py/" target="_blank" rel="noopener noreferrer">
									<img className="img" src={logo_MNP} height="65px" alt="Logo MNP" />
								</a>
								<a href="https://www.tedic.org/" target="_blank" rel="noopener noreferrer">
									<img className="img" src={logo_Tedic} height="100px" alt="Logo TEDIC" />
								</a>
							</Col>
							<Col style={styles.column_links} xs lg="4">
								<Col sm>
									<a href="/pdf/API_publica.pdf" style={styles.links} target="_blank" rel="noopener noreferrer">
										API
									</a>
								</Col>
								<Col sm>
									<a href="https://gitlab.com/tedicpy/defensores" style={styles.links} target="_blank" rel="noopener noreferrer">
										C&oacute;digo fuente
									</a>
								</Col>
								<Col sm>
									<a href="/pdf/Term_cond_Defensores_vssetTEDIC19.pdf" style={styles.links} target="_blank" rel="noopener noreferrer">
										T&eacute;rminos y condiciones
									</a>
								</Col>
							</Col>
							<Col style={styles.column_git} sm>
								<a href="https://gitlab.com/tedicpy/defensores" target="_blank" rel="noopener noreferrer">
									<img className="img" src={logo_Gitlab} height="60px" alt="Logo GitLab" />
								</a>
							</Col>
						</Row>
					</Container>
				</React.Fragment>
			</div>
		)
	}
}
