import React from "react";
import {
    List,
    Datagrid,
    Pagination,
    TextField,
    NumberInput,
    ShowButton,
    EditButton,
    DeleteButton,
    Filter,
    ReferenceInput,
    ReferenceField,
    FunctionField,
    AutocompleteInput
} from "react-admin";
import { vCoordinator } from "../utils/auth";
import { userId } from '../utils/storage';


const PlaceFilter = props => (
    <Filter {...props}>
        <NumberInput label="ID" source="id" reference="places" />

        <ReferenceInput
            label="Lugar"
            source="id"
            reference="places"
            allowEmpty
            alwaysOn
            sort={{ field: "name", order: "ASC" }}
            perPage={50}
            filterToQuery={searchText => ({
                or: [
                    { name: { like: "%" + searchText + "%" } },
                    { departament: { like: "%" + searchText + "%" } },
                    { district: { like: "%" + searchText + "%" } }
                ]
            })}
        >
            <AutocompleteInput
                optionText={place => `${place.name || ''} ${place.departament || ''} ${place.district || ''}`}
                suggestionComponent={({ suggestion, query, props }) => (
                    <div>
                        <div style={{}} {...props}>
                                {(suggestion.name || '')
                                    .toLowerCase()
                                    .includes(query.toLowerCase()) ||
                                (suggestion.departament || '')
                                    .toLowerCase()
                                    .includes(query.toLowerCase()) |
                                    (suggestion.district || '')
                                        .toLowerCase()
                                        .includes(query.toLowerCase())
                                    ? `\u00A0${suggestion.name || ''} ${suggestion.departament || ''} ${suggestion.district || ''}\u00A0`
                                    : ""}
                            </div>
                    </div>
                )}
            />
        </ReferenceInput>

        {vCoordinator() && (
            <ReferenceInput
                source="placeGroupId"
                reference="placeGroups"
                allowEmpty
                sort={{ field: "name", order: "ASC" }}
                perPage={50}
                filterToQuery={searchText => ({
                    name: { like: "%" + searchText + "%" }
                })}
            >
                <AutocompleteInput
                    optionText={place => place.name}
                    suggestionComponent={({ suggestion, query, props }) => (
                        <div>
                            <div style={{}} {...props}>
                                {suggestion.name
                                    .toLowerCase()}
                            </div>
                        </div>
                    )}
                />
            </ReferenceInput>
        )}
    </Filter>
);

const PlaceList = props => (
    <List {...props} bulkActionButtons={false} filters={<PlaceFilter/>} pagination={<Pagination />} sort={{ field: 'id', order: 'ASC' }} exporter={false}>
        <Datagrid>
            <TextField source="id" />
            <ReferenceField source="placeGroupId" reference="placeGroups" linkType="show">
                <TextField source="name" />
            </ReferenceField>
            <TextField source="name" />
            <FunctionField
                source="departament"
                render={record => [
                    'Alto Paraguay',
                    'Alto Paraná',
                    'Amambay',
                    'Boquerón',
                    'Caaguazú',
                    'Caazapá',
                    'Canindeyú',
                    'Central',
                    'Concepción',
                    'Cordillera',
                    'Guairá',
                    'Itapúa',
                    'Misiones',
                    'Ñeembucú',
                    'Paraguarí',
                    'Presidente',
                    'San Pedro'
                ][record.departament_number]}
            />
            <TextField source="district" />
            <ShowButton />
            <EditButton />
            {userId == 1 ? (
                <DeleteButton />
            ) : null}
        </Datagrid>
    </List>
);

export default PlaceList