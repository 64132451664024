import request from "./request";

const logout = () => {
    request({
        method: 'POST',
        url: '/users/logout'
    })
        .then(res => {
            localStorage.clear();
            window.location.replace("/");
        })
        .catch(error => {
            localStorage.clear();
            window.location.replace("/");
        });
};

export default logout;
