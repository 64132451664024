import React, { Component } from 'react';
import Maps from './components/Maps';
import './css/Map.css';

export default class Map extends Component {
	render() {
		return (
			<div className="content-map">
				<div className="title-map">
					Mapa de la violencia
                </div>
                <div>
					<Maps />
				</div>
			</div>
		)
	}
}