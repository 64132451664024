import React, { Component } from "react";
import logo from "../../../assets/logo-color.svg";
import "./css/Project.css";

export default class Project extends Component {
    constructor () {
        super()
        this.state = {
          isHidden: true
        }
    }
      
    toggleHidden () {
        this.setState({
            isHidden: !this.state.isHidden
        })
    }

    render() {
        const Child = () => (
            <div>
                <div className="large-title-project">
					Descripci&oacute;n general del proyecto
				</div>
                <div>
                    <p>
                        Contar con una plataforma web y aplicaci&oacute;n m&oacute;vil que permita un sostenido registro de casos de tortura dentro 
                        de las comisar&iacute;as y peninteciar&iacute;as de Paraguay. Espec&iacute;ficamente, facilitar&aacute; el monitoreo efectivo y 
                        permanente de este tipo de cr&iacute;menes por parte de defensores p&uacute;blicos y generar&aacute; informaci&oacute;n calificada 
                        para distinas instituciones como el Mecanismo Nacional de Prevenci&oacute;n de la Tortura (MNP), medios de comunicaci&oacute;n, 
                        periodistas, organizaciones de la sociedad civil y la ciudadan&iacute;a en general.
                    </p>
                    <p>
                        Un registro de alegaciones de torturas y malos tratos disponible al p&uacute;blico puede sensibilizar a las autoridades y 
                        ciudadan&iacute;a sobre la grave situaci&oacute;n penintenciaria del pa&iacute;s y promover acciones para su mejora. Adem&aacute;s, 
                        dejar&aacute; un antecedente replicable de uso de tecnolog&iacute;a para la garant&iacute;a de protecci&oacute;n de derechos humanos.
                    </p>
                    <p>
                        El registro puede desincentivar hechos de tortura y malos tratos pues se gerenar&aacute; una base de datos de reportes que 
                        permitir&aacute; identificar a los victimarios y todos aquellos que no cumplan con las normas y leyes, y as&iacute; facilitar&aacute; el 
                        trabajo de los defensores de acudir a los juzgados. Se espera que el acceso de los defensores p&uacute;blicos a un canal confidencial
                        e inmediato de reporte facilitar&aacute; la presentaci&oacute;n de casos ante el Ministerio P&uacute;blico y en el largo plazo, as&iacute; 
                        como contribuir&aacute; a una pol&iacute;tica eficaz de sanci&oacute;n de la tortuta. Se espera que tanto la plataforma web como la 
                        aplicaci&oacute;n m&oacute;vil sean replicadas por mecanismos de prevenci&oacute;n de la tortura (MNPs) y defensor&iacute;as p&uacute;blicas
                        de otros pa&iacute;ses, en vista de que el proyecto se desarrollar&aacute; con software libre y as&iacute; facilitar&aacute; su adaptabilidad 
                        y mejoramiento.
                    </p>
                </div>
            </div>
        );

        return (
            <div className="content-project">
                <div className="logo-project">
                    <img
                        src={logo}
                        width="100"
                        height="100"
                        alt="Imagen Defensores"
                    />
                </div>
                <div className="title-project">
                    Registro Unificado de <br />
                    Tortura y Malos Tratos
                </div>
                <div className="description-project">
                    Herramienta que permitir&aacute; que el Estado paraguayo y
                    la cuidadan&iacute;a visualicen los casos de tortura y
                    abusos contra las personas privadas de libertad
                    <br />
                    <button className="button-project" onClick={this.toggleHidden.bind(this)} >
                        PROYECTO
                    </button>
                </div>
                <div className="large-description-project">
                    {!this.state.isHidden && <Child />}
                </div>
            </div>
        );
    }
}
