import React from 'react';
import {
    List,
    Datagrid,
    TextField,
    NumberField,
    ReferenceField,
    FunctionField,
    BooleanField,
    EmailField,
    SelectField,
    ShowButton,
    EditButton,
    Filter,
    NumberInput,
    ReferenceInput,
    AutocompleteInput
} from 'react-admin';
import { vCoordinator } from "../utils/auth";


const optionRendererAccounts = account =>
    `${account.firstname} ${account.lastname}`;

const AccountFilter = props => (
    <Filter {...props}>
        <NumberInput label="ID" source="id" reference="users" />

        <ReferenceInput
            label="Usuario"
            source="id"
            reference="users"
            allowEmpty
            sort={{ field: "firstname", order: "ASC" }}
            perPage={50}
            filterToQuery={searchText => ({
                    or: [
                        { firstname: { like: "%" + searchText + "%" } },
                        { lastname: { like: "%" + searchText + "%" } }
                    ]
                })}
        >
            <AutocompleteInput
                optionText={optionRendererAccounts}
                suggestionComponent={({
                    suggestion,
                    query,
                    isHighlighted,
                    props
                }) => (
                    <div>
                        <div style={{}} {...props}>
                            {(suggestion.firstname || '')
                                .toLowerCase()
                                .includes(query.toLowerCase()) ||
                            (suggestion.lastname || '')
                                .toLowerCase()
                                .includes(query.toLowerCase())
                                ? `\u00A0${suggestion.firstname} ${suggestion.lastname}\u00A0`
                                : ""}
                        </div>
                    </div>
                )}
            />
        </ReferenceInput>

        {vCoordinator() && (
            <ReferenceInput
                source="institutionId"
                reference="institutions"
                allowEmpty
                sort={{ field: "name", order: "ASC" }}
                perPage={50}
                filterToQuery={searchText => ({
                    name: { like: "%" + searchText + "%" }
                })}
            >
                <AutocompleteInput
                    optionText={place => place.name}
                    suggestionComponent={({ suggestion, query, props }) => (
                        <div>
                            <div style={{}} {...props}>
                                {suggestion.name
                                    .toLowerCase()}
                            </div>
                        </div>
                    )}
                />
            </ReferenceInput>
        )}
    </Filter>
);

const AccountList = props => (
    <List {...props} bulkActionButtons={false} filters={<AccountFilter />} sort={{ field: 'id', order: 'ASC' }} exporter={false}>
        <Datagrid>
            <TextField source="userId" />
            <TextField source="firstname" />
            <TextField source="lastname" />
            <EmailField source="email" />
            <BooleanField source="isActive" />
            <SelectField
                source="role"
                choices={[
                    { id: "defender", name: "Defensor" },
                    { id: "coordinator", name: "Coordinador" },
                    { id: "observer", name: "Veedor" }
                ]}
            />
            <NumberField source="number" />
            <ReferenceField source="institutionId" reference="institutions" linkType="show">
                <TextField source="name" />
            </ReferenceField>
            <ShowButton />
            <EditButton />
        </Datagrid>
    </List>
);

export default AccountList;
