import React from 'react';
import { ReferenceInput, TextInput, SelectInput, SimpleForm, Create, required, maxLength, minValue, BooleanInput } from 'react-admin';
import TextInputEmail from './fields/TextInputEmail';


const AccountCreate = props => (
    <Create {...props}>
        <SimpleForm redirect="list">
            <TextInput source="firstname" validate={required()} autoFocus />
            <TextInput source="lastname" validate={required()} />

            {/* Institution */}
            <ReferenceInput
                source="institutionId"
                reference="institutions"
                validate={required()}
                sort={{ field: "name", order: "ASC" }}
                perPage={350}
            >
                <SelectInput optionText="name" />
            </ReferenceInput>

            <TextInput source="position" validate={maxLength(50)} />
            <TextInput source="employer" validate={maxLength(50)} />
            <TextInput source="number" validate={[required()]} />
            <SelectInput
                source="role"
                choices={[
                    { id: "defender", name: "Defensor" },
                    { id: "coordinator", name: "Coordinador" },
                    { id: "observer", name: "Veedor" }
                ]}
                validate={required()}
            />
            <TextInputEmail source="email" />
            <p className="">Se enviará al correo especificado un enlace y los pasos para establecer su contraseña.</p>

            <BooleanInput source="isActive" />
        </SimpleForm>
    </Create>
);

export default AccountCreate;