import React from "react";

import {
    List,
    Datagrid,
    TextField,
    ShowButton,
    EditButton,
    DeleteButton,
    Filter,
    NumberInput,
    ReferenceInput,
    AutocompleteInput
} from "react-admin";

import { userId } from '../utils/storage';


const PlacegroupFilter = props => (
    <Filter {...props}>
        <ReferenceInput
            label="Nombre"
            source="id"
            reference="placeGroups"
            alwaysOn
            allowEmpty
            sort={{ field: "name", order: "ASC" }}
            perPage={100}
            filterToQuery={searchText => ( 
                { 
                    name: {like:'%' + searchText + '%'}
                } 
            )}
        >
            <AutocompleteInput
                optionText={(option => option.name)}
                suggestionComponent={({
                    suggestion,
                    query,
                    isHighlighted,
                    props
                }) => (
                    <div>
                        <div style={{}} {...props}>
                        {suggestion.name
                                .toLowerCase()
                                .includes(query.toLowerCase())
                                ? `\u00A0${suggestion.name}\u00A0`
                                : ""}
                        </div>
                    </div>
                )}
            />
        </ReferenceInput>

        <NumberInput label="ID" source="id" reference="placeGroups" />
    </Filter>
);

const PlacegroupList = props => (
    <List {...props} bulkActionButtons={false} 
    filters={<PlacegroupFilter />}
    sort={{ field: 'id', order: 'ASC' }} exporter={false}>
        <Datagrid>
            <TextField source="id" />
            <TextField source="name" />
            <ShowButton />
            <EditButton />
            {userId == 1 ? (
                <DeleteButton />
            ) : null}
        </Datagrid>
    </List>
);

export default PlacegroupList;