import React from "react";
import { Button } from "@material-ui/core";
import { Edit } from "@material-ui/icons";

const onClick = reportId => {
    //console.log("reportId", reportId);
};

const EditButton = ({ record, resource }) => {
    const reportId = record.id;
    const url = `/#/${resource}/${reportId}`;
    
    return (
        <a href={url} style={{textDecoration: "none"}}>
            <Button
                size="small"
                color="primary"
                onClick={() => onClick(reportId)}
            >
                <Edit size="small" />
                &nbsp;Editar
            </Button>
        </a>
    );
};

export default EditButton;
