import React, { Component } from "react";
import { URL } from "../constants/env";
import {
    Button,
    TextField,
    Grid,
    Card,
    CardContent,
    Typography
} from "@material-ui/core";
import { translate } from "react-admin";
import "../css/ResetForm.css";
import req from "../utils/request";


class ResetForm extends Component {
    state = {
        email: "",
        emailSended: false,
        isLoading: false
    };

    validate = email => {
        if(email.trim() === ""){
            return false;
        }
        return true;
    }
    
    sendEmail = email => {
        this.setState({ isLoading: true });
        req({
            method: "post",
            url: `/users/reset-password/init`,
            data: { 
                email
             },
            config: { headers: { "Content-Type": "multipart/form-data" } }
        })
            .then(res => {
                console.log("OK: EMAIL SENT", res);
                this.setState({ emailSended: true, isLoading: true });
            })
            .catch(err => {
                this.setState({ isLoading: false });
                alert('Email no encontrado');
            });
    };
    
    handleChange =(event) => {
        this.setState({ email: event.target.value });
    }
    
    goToIndex =(event) => {
        document.location.replace("/");
        // this.setState({ email: "", emailSended: false });
    }

    handleSubmit = event => {
        if(this.validate(this.state.email)){
            this.sendEmail(this.state.email);
            event.preventDefault();
        }else{
            alert("Mail vacío.");
        }
    };

    componentDidMount() {
        //console.log('ResetForm', ResetForm)
    }

    render() {
        const { translate } = this.props;
        const styles = {
            card: {
                width: "30%",
                marginTop: "120px",
                paddingBottom: "10px"
            },
            tileCard: {
                paddingTop: 20,
                fontSize: "22px",
                fontFamily: "Roboto Slab",
                color: "#5e6ab1"
            },
            cardContent: {
                display: "grid",
                justifyContent: "center",
                alignItems: "center"
            }
        };

        return (
            <Grid
                container
                spacing={0}
                direction="column"
                alignItems="center"
                justify="center"
            >
                <Card style={styles.card}>
                    <CardContent style={styles.cardContent}>
                        {!this.state.emailSended ? (
                            <div className="formResetForm">
                                <Typography style={styles.tileCard}>
                                    {translate("recover_password_title")}
                                </Typography>
                                <div className="email_recover_password_desc">{translate("email_recover_password_desc")}</div>
                                <TextField
                                    label={translate("email_recover_password")}
                                    type="email"
                                    // autoComplete="current-password"
                                    onChange={this.handleChange}
                                    margin="normal"
                                    value={this.state.email}
                                    // InputProps={{
                                    //     startAdornment: (
                                    //         <InputAdornment position="start">
                                    //             <Email />
                                    //         </InputAdornment>
                                    //     )
                                    // }}
                                />
                                <Button
                                    variant="raised"
                                    color="primary"
                                    disabled={this.state.isLoading}
                                    onClick={this.handleSubmit}>
                                    {translate("send_recover_password")}
                                </Button>
                            </div>
                        ) : (
                            <div className="formResetForm">
                                <Typography style={styles.tileCard}>
                                    {translate("email_recover_sended_1")}
                                </Typography>
                                <div>{translate("email_recover_sended_2")}</div>
                                <br />
                                <Button
                                    variant="raised"
                                    color="primary"
                                    onClick={this.goToIndex}>
                                    {translate("email_recover_end")}
                                </Button>
                            </div>
                        )}
                    </CardContent>
                </Card>
            </Grid>
        );
    }
}

export default translate(ResetForm);