import React from "react";
import { TextInput, SimpleForm, Edit, Toolbar, SaveButton, number, minValue, maxValue, translate } from "react-admin";
import { withStyles } from '@material-ui/core';


const toolbarStyles = {
    toolbar: {
        display: 'flex',
        justifyContent: 'space-between',
    },
};

const validateChangeStatusHours = [number(), minValue(1), maxValue(400)];

const CustomToolbar = withStyles(toolbarStyles)(props => (
    <Toolbar {...props}>
        <SaveButton />
    </Toolbar>
));
const Settings = ({ staticContext, translate, ...props }) => {
    return (
        <Edit
            redirect={false}
            {...props}
            id="all"
            resource="settings"
            basePath="/settings"
            title="settings"
        >
            <SimpleForm toolbar={<CustomToolbar />}>
                <TextInput label={translate('resources.settings.fields.change_status_hours')} source="change_status_hours" validate={validateChangeStatusHours} autoFocus />
                <TextInput label={translate('resources.settings.fields.languages')} source="languages" />
                <TextInput label={translate('resources.settings.fields.daysOfInactivity')} source="daysOfInactivity" />
            </SimpleForm>
        </Edit>
    );
 };

 export default translate(Settings);