import loopbackProvider from "ra-data-lb4";
import { fetchUtils } from 'react-admin';
import _ from 'lodash';
import { URL } from "../constants/env";
import { token, userId } from '../utils/storage'
import request from '../utils/request';

const httpClient = (url, options = {}) => {
    if (!options.headers) {
        options.headers = new Headers({ Accept: 'application/json' });
    }
    options.headers.set('Authorization', 'Bearer ' + token);
    return fetchUtils.fetchJson(url, options);
}

const dataProvider = loopbackProvider(URL, undefined, httpClient);
// console.log(dataProvider);
const typeMap = {
    CREATE: 'create',
    DELETE: 'delete',
    DELETE_MANY: 'deleteMany',
    GET_LIST: 'getList',
    GET_MANY: 'getMany',
    GET_MANY_REFERENCE: 'getManyReference',
    GET_ONE: 'getOne',
    UPDATE: 'update',
    UPDATE_MANY: 'updateMany'
}

export default (type, resource, params) => {
    return new Promise((resolve, reject) => {
        // console.log(type, resource, params);
        setTimeout(() => {
            // Report create
            if (type === 'CREATE' && resource === 'reports') {
                let newData = _.cloneDeep(
                    _.omit(params.data, 'userNumber')
                );
                newData.userId = userId;
                stringifyReport(newData);
                delete newData.accountName;
                request({
                    method: 'post',
                    url: `${resource}/one`,
                    data: newData
                })
                .then((res) => {
                    resolve({ data: res });
                })
                .catch((err) => {
                    console.log('err CREATE report', err.message);
                    reject(err);
                    // resolve(err);
                });
            }
            // Report update
            else if (type === 'UPDATE' && resource === 'reports') {
                let newData = _.clone(params.data)
                stringifyReport(newData);
                request({
                    method: 'put',
                    url: `${resource}/${newData.id}`,
                    data: newData
                })
                .then((res) => {
                    resolve({ data: newData });
                })
                .catch((err) => {
                    console.log('err UPDATE report',  err.message);
                    reject(err);
                    // resolve(err);
                });
            }
            // default
            else {
                dataProvider[typeMap[type]](resource, params)
                .then((res) => {
                    let total = 0;
                    if ((type === 'GET_MANY' || type === 'GET_LIST') && res.data && res.data.data) {
                        total = res.data.total;
                        res.data = res.data.data;
                    }
                    if (type === 'GET_ONE' && resource === 'reports') {
                        parseReport(res.data);
                    }
                    if ((type === 'GET_MANY' || type === 'GET_LIST') && res.data && resource === 'reports') {
                        if (_.isArray(res.data)) {
                            res.data.forEach(parseReport);
                        }
                    }
                    if ((type === 'GET_MANY' || type === 'GET_LIST') && res.data && !_.isArray(res.data)) {
                        res.data = [ res.data ];
                    }
                    if ((type === 'GET_MANY' || type === 'GET_LIST') && resource === 'users') {
                        _.forEach(res.data, item => {
                            item.id = item.userId;
                        })
                    }
                    if (type === 'GET_ONE' && resource === 'users') {
                        res.data.id = res.data.userId;
                    }
                    if (res && _.isArray(res.data)) {
                        total = total || res.data.length;
                    }
                    resolve({ data: res.data, total });
                })
                .catch((err) => {
                    console.log('err', err.message);
                    reject(err);
                    // resolve(err);
                });
            }

        }, 200);
    });
};

function parseReport(newData) {
    newData.locationId = 0;
        // newData.tortureOrMaltreatment = newData.tortureOrMaltreatment ? newData.tortureOrMaltreatment.split('####') : [];
    newData.maltreatmentType = newData.maltreatmentType ? newData.maltreatmentType.split('####') : [];
    newData.tortureType = newData.tortureType ? newData.tortureType.split('####') : [];
    newData.tortureTypePhy = newData.tortureTypePhy ? newData.tortureTypePhy.split('####') : [];
    newData.tortureDamage = newData.tortureDamage ? newData.tortureDamage.split('####') : [];
    newData.tortureTypePsy = newData.tortureTypePsy ? newData.tortureTypePsy.split('####') : [];
    newData.tortureTypeSex = newData.tortureTypeSex ? newData.tortureTypeSex.split('####') : [];
    newData.tortureTime = newData.tortureTime ? newData.tortureTime.split('####') : [];
    newData.tortureCustody = newData.tortureCustody ? newData.tortureCustody.split('####') : [];
    newData.urgentRequestType = newData.urgentRequestType ? newData.urgentRequestType.split('####') : [];
}

function stringifyReport(newData) {
    newData.locationId = 0;
    Reflect.deleteProperty(newData, 'userNumber');
        // if (newData.tortureOrMaltreatment) { newData.tortureOrMaltreatment = newData.tortureOrMaltreatment.join('####'); } 
    if (newData.maltreatmentType) { newData.maltreatmentType = newData.maltreatmentType.join('####'); } 
    if (newData.tortureType) { newData.tortureType = newData.tortureType.join('####'); } 
    if (newData.tortureTypePhy) { newData.tortureTypePhy = newData.tortureTypePhy.join('####'); } 
    if (newData.tortureDamage) { newData.tortureDamage = newData.tortureDamage.join('####'); } 
    if (newData.tortureTypePsy) { newData.tortureTypePsy = newData.tortureTypePsy.join('####'); } 
    if (newData.tortureTypeSex) { newData.tortureTypeSex = newData.tortureTypeSex.join('####'); } 
    if (newData.tortureTime) { newData.tortureTime = newData.tortureTime.join('####'); } 
    if (newData.tortureCustody) { newData.tortureCustody = newData.tortureCustody.join('####'); } 
    if (newData.urgentRequestType) { newData.urgentRequestType = newData.urgentRequestType.join('####'); } 
    for (const key in newData) {
        if (Object.hasOwnProperty.call(newData, key)) {
            newData[key] = newData[key] === null ? '' : newData[key];
        }
    }
}