import React from 'react';
import { TextInput, required, number } from 'react-admin';

const validateTrim = (value) => {
    if (value && value.trim() === "") {
        return 'No puede contener solamente espacio como carácter.';
    }
    if (value && parseFloat(value) != value) {
        return 'Debe contener sólo números enteros y decimales, positivos o negativos.';
    }
    return; 
};

const validateGeometry = [required(), validateTrim];

const TextInputGeometry = props => (
    <TextInput source={props.source} validate={validateGeometry} {...props} />
);

export default TextInputGeometry;