import React from "react";
import { Button } from "@material-ui/core";
import { NoteAdd } from "@material-ui/icons";

const onClick = reportId => {
    //console.log("reportId", reportId);
};

const DocsButton = ({ record }) => {
    const reportId = record.id;
    const url = `/#/docs/${reportId}`;
    
    return (
        <a href={url} style={{textDecoration: "none"}}>
            <Button
                size="small"
                color="primary"
                onClick={() => onClick(reportId)}
            >
                <NoteAdd size="small" />
                &nbsp;Documentos
            </Button>
        </a>
    );
};

export default DocsButton;
