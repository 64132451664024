import { role } from './storage'
const vCoordinator = () => {
    if (role === "coordinator") {
        return true;
    } else return false;
};

const vDefender = () => {
    if (role === "defender") {
        return true;
    }
    return false;
};

const vObserver = () => {
    if (role === "observer") {
        return true;
    }
    return false;
};

const vPublic = () => {
    if (!role || role === "") {
        return true;
    }
    return false;
};

export { vCoordinator, vDefender, vObserver, vPublic };
