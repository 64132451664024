import React from "react";
import {
    DateInput,
    DateTimeInput,
    TextInput,
    Create,
    Toolbar,
    SaveButton,
    SelectInput,
    BooleanInput,
    CheckboxGroupInput,
    RadioButtonGroupInput,
    AutocompleteInput,
    ReferenceInput,
    DisabledInput,
    FormDataConsumer,
    TabbedForm,
    FormTab,
    CardActions,
    ListButton,
    required,
    number,
    maxLength,
    minValue,
    maxValue,
    translate
} from "react-admin";
import { withStyles } from "@material-ui/core";
import moment from 'moment';
import _ from 'lodash';
import { userId, name } from "../utils/storage";
import * as OTHERCONSTANTS from "../constants/otherConstants";
import "../css/Form.css";
import { GetDoc } from './GetDoc';

const toolbarStyles = {
    toolbar: {
        display: "flex",
        justifyContent: "space-between"
    }
};

const CustomToolbar = withStyles(toolbarStyles)(props => (
    <Toolbar {...props}>
        <SaveButton  />
    </Toolbar>
));

const ReportCreateActions = ({ basePath, data, resource }) => (
    <CardActions>
        <ListButton basePath={basePath} />
    </CardActions>
);

const alphaNumeric = (value, allValues) => {
    var alphaNumeric = value;
    var reg = new RegExp("^[0-9a-zA-Z]*$");
    var test = reg.test(alphaNumeric);

    if (!test) {
        return "Debe ser solo números y letras.";
    }
    return;
};

const validDate = (value) => {
    if ( (new Date(value)) == 'Invalid Date' ) {
        return 'Fecha inválida';
    }
    return;
}

const maxDateNow = (value) => {
    if ( moment(value).isAfter(new Date()) ) {
        return 'La fecha debe ser menor a la fecha actual';
    }
    return;
}

const minLengthList = (value) => {
    if ( !_.size(value) ) {
        return `Debe seleccionar al menos 1 opción`;
    }
    return;
}

const validateVictimCin = [maxLength(15), alphaNumeric];
const validateVictimAge = [number(), minValue(1), maxValue(110), required()];
const validateCaseNumber = [number()];

const defaultValue = () => ({ datetime: new Date() });

const optionRendererPlaces = place =>
    `${place.name} ${place.departament || ''} ${place.district || ''}`;

const PlacesSelector = ({...props}) => {
    let choicesFilter = _.filter(props.choices, place => place.lat && place.lng);
    return <AutocompleteInput
        choices={choicesFilter}
        optionText={optionRendererPlaces}
        suggestionComponent={({
            suggestion,
            query,
            isHighlighted,
            props
        }) => (
            <div>
                <div style={{}} {...props}>
                    {optionRendererPlaces(suggestion)}
                </div>
            </div>
        )}
        {...props}
    />
}

const ReportCreate = ({ translate, ...props }) => (
    <Create actions={<ReportCreateActions />} {...props}>
        <TabbedForm toolbar={<CustomToolbar />} redirect="list" defaultValues={defaultValue}>

            {/* 1 form - General */}
            <FormTab label={translate("report_tab_defender")}>

                <DisabledInput source="userId" defaultValue={userId} style={{display: 'none'}} />

                <DisabledInput source="accountName" defaultValue={name} />
                <GetDoc id={userId} reference="users">{(user) => (
                    <DisabledInput source="userNumber" label={translate("resources.reports.fields.userNumber")} defaultValue={user.number} />
                )}</GetDoc>


                <DateTimeInput source="datetime" validate={[required(), validDate]} label={translate("datetime")} disabled defaultValue={new Date()} />

                {/* <ReferenceInput
                    source="placeId"
                    reference="places"
                    validate={required()}
                    sort={{ field: "name", order: "ASC" }}
                    perPage={2000}
                    filterToQuery={searchText => ({
                        or: [
                            { name: { like: "%" + searchText + "%" } },
                            { departament: { like: "%" + searchText + "%" } },
                            { district: { like: "%" + searchText + "%" } }
                        ]
                    })}

                >
                    <PlacesSelector />
                </ReferenceInput>
                <FormDataConsumer>
                    {({ formData, ...rest }) =>
                        formData.placeId &&
                        formData.placeId === OTHERCONSTANTS.PLACES_OTHER && (
                            <TextInput source="placeIdOther" {...rest} />
                        )
                    }
                </FormDataConsumer> */}

                <TextInput source="place" validate={required()} />

                <SelectInput resettable
                    source="source"
                    choices={[
                        { id: "Es la propia víctima", name: "Es la propia víctima" },
                        { id: "Familiar", name: "Familiar" },
                        { id: "Otro", name: "Otro" },
                        { id: "Anónimo", name: "Anónimo" }
                    ]}
                    validate={required()}
                />

                <FormDataConsumer>
                    {({ formData, ...rest }) =>
                        formData.source &&
                        !(formData.source.includes("Es la propia víctima") || formData.source.includes("Anónimo")) && (<>
                            <TextInput source="sourceName" {...rest} />
                        </>)
                    }
                </FormDataConsumer>

                <FormDataConsumer>
                    {({ formData, ...rest }) =>
                        formData.source &&
                        !(formData.source.includes("Es la propia víctima") || formData.source.includes("Anónimo")) && (<>
                            <TextInput source="sourceContact" {...rest} />
                        </>)
                    }
                </FormDataConsumer>

            </FormTab>


            {/* 2 form - Torture */}
            <FormTab label={translate("report_tab_torture")}>

                <DateTimeInput source="captureDatetime" validate={[required(), validDate, maxDateNow]} label={translate("resources.reports.fields.captureDatetime")} />

                <RadioButtonGroupInput
                    source="tortureOrMaltreatment"
                    validate={[required()]}
                    choices={[
                        { id: "Tortura", name: "Tortura" },
                        { id: "Maltratos", name: "Maltratos" }
                    ]}
                    options={{ labelposition: "left" }}
                    //onChange={handleChange}
                    //onChange={e => handleChange(e)}
                />

                <FormDataConsumer> 
                    {({ formData, ...rest }) =>
                        formData.tortureOrMaltreatment &&
                        formData.tortureOrMaltreatment.includes("Tortura") && (<>
                            <CheckboxGroupInput
                                source="tortureType"
                                choices={[
                                    { id: "Física", name: "Física" },
                                    { id: "Psicológica", name: "Psicológica" },
                                    { id: "Sexual", name: "Sexual" },
                                    { id: "tortureTypeOther", name: "Otro" }
                                ]}
                                options={{ labelposition: "left" }}
                                validate={[required(), minLengthList]}
                                //onChange={handleChange}
                                //onChange={e => handleChange(e)}
                                {...rest}
                            />
                        </>)
                    }
                </FormDataConsumer>

                <FormDataConsumer>
                    {({ formData, ...rest }) =>
                        formData.tortureOrMaltreatment && formData.tortureOrMaltreatment.includes("Tortura")
                        && formData.tortureType && formData.tortureType.includes("tortureTypeOther") && (
                            <TextInput source="tortureTypeOther" {...rest} />
                        )
                    }
                </FormDataConsumer>

                <FormDataConsumer> 
                    {({ formData, ...rest }) =>
                        formData.tortureOrMaltreatment && formData.tortureOrMaltreatment.includes("Tortura")
                        && formData.tortureType && formData.tortureType.includes("Física")
                        && (<>
                            <CheckboxGroupInput
                                source="tortureTypePhy"
                                choices={[
                                    { id: "Estirones", name: "Estirones" },
                                    { id: "Arrojaron objetos", name: "Arrojaron objetos" },
                                    { id: "Bolsa en la cabeza", name: "Bolsa en la cabeza" },
                                    { id: "Empujones", name: "Empujones" },
                                    { id: "Golpe con objeto", name: "Golpe con objeto" },
                                    { id: "Puños, golpes con la mano", name: "Puños, golpes con la mano" },
                                    { id: "Patada", name: "Patada" },
                                    { id: "Po pyte", name: "Po pyte", },
                                    { id: "Py Pyte", name: "Py Pyte", },
                                    { id: "Apysarapo", name: "Apysarapo", },
                                    { id: "Posiciones incómodas prolongadas", name: "Posiciones incómodas prolongadas", },
                                    { id: "Exposición al clima", name: "Exposición al clima", },
                                    { id: "Hambre", name: "Hambre", },
                                    { id: "Sed", name: "Sed", },
                                    { id: "Aislamiento", name: "Aislamiento", },
                                    { id: "tortureTypePhyOther", name: "Otro" }
                                ]}
                                options={{ labelposition: "left" }}
                                {...rest}
                            />
                        </>)
                    }
                </FormDataConsumer>

                <FormDataConsumer>
                    {({ formData, ...rest }) =>
                        formData.tortureOrMaltreatment && formData.tortureOrMaltreatment.includes("Tortura")
                        && formData.tortureType && formData.tortureType.includes("Física")
                        && formData.tortureTypePhy && formData.tortureTypePhy.includes("tortureTypePhyOther") && (
                            <TextInput source="tortureTypePhyOther" {...rest} />
                        )
                    }
                </FormDataConsumer>

                <FormDataConsumer>
                    {({ formData, ...rest }) =>
                        formData.tortureOrMaltreatment && formData.tortureOrMaltreatment.includes("Tortura")
                        && formData.tortureType && formData.tortureType.includes("Física") && (
                            <CheckboxGroupInput
                                source="tortureDamage"
                                choices={[
                                    { id: "Cabeza", name: "Cabeza" },
                                    { id: "Rostro", name: "Rostro" },
                                    { id: "Abdomen", name: "Abdomen" },
                                    { id: "Extremidades", name: "Extremidades" },
                                    { id: "Genitales", name: "Genitales" },
                                    { id: "tortureDamageOther", name: "Otro" }
                                ]}
                                options={{ labelposition: "left" }}
                                {...rest}
                            />
                        )
                    }
                </FormDataConsumer>

                <FormDataConsumer>
                    {({ formData, ...rest }) =>
                        formData.tortureOrMaltreatment && formData.tortureOrMaltreatment.includes("Tortura")
                        && formData.tortureType && formData.tortureType.includes("Física")
                        && formData.tortureDamage && formData.tortureDamage.includes("tortureDamageOther") && (
                            <TextInput source="tortureDamageOther" {...rest} />
                        )
                    }
                </FormDataConsumer>

                <FormDataConsumer>
                    {({ formData, ...rest }) =>
                        formData.tortureOrMaltreatment && formData.tortureOrMaltreatment.includes("Tortura")
                        && formData.tortureType && formData.tortureType.includes("Sexual") 
                        && (
                            <CheckboxGroupInput
                                source="tortureTypeSex"
                                choices={[
                                    { id: "Violación", name: "Violación" },
                                    { id: "Amenaza de violación", name: "Amenaza de violación" },
                                    { id: "Manoseos", name: "Manoseos" },
                                    { id: "Revisión de cavidades íntimas", name: "Revisión de cavidades íntimas" },
                                    { id: "Desnudos", name: "Desnudos" },
                                    { id: "tortureTypeSexOther", name: "Otro" }
                                ]}
                                options={{ labelposition: "left" }}
                                {...rest}
                            />
                        )
                    }
                </FormDataConsumer>

                <FormDataConsumer>
                    {({ formData, ...rest }) => {
                        return (
                            formData.tortureOrMaltreatment && formData.tortureOrMaltreatment.includes("Tortura")
                            && formData.tortureType && formData.tortureType.includes("Sexual") 
                            && formData.tortureTypeSex && formData.tortureTypeSex.includes("tortureTypeSexOther") 
                            && (
                                <TextInput
                                    source="tortureTypeSexOther"
                                    {...rest}
                                />
                            )
                        );
                    }}
                </FormDataConsumer>

                <FormDataConsumer>
                    {({ formData, ...rest }) =>
                        formData.tortureOrMaltreatment && formData.tortureOrMaltreatment.includes("Tortura")
                        && formData.tortureType && formData.tortureType.includes("Psicológica") 
                        && (
                            <CheckboxGroupInput
                                source="tortureTypePsy"
                                choices={[
                                    { id: "Amenazas", name: "Amenazas" },
                                    { id: "Agresión verbal", name: "Agresión verbal" },
                                    { id: "tortureTypePsyOther", name: "Otro" }
                                ]}
                                options={{ labelposition: "left" }}
                                {...rest}
                            />
                        )
                    }
                </FormDataConsumer>

                <FormDataConsumer>
                    {({ formData, ...rest }) =>
                        formData.tortureOrMaltreatment && formData.tortureOrMaltreatment.includes("Tortura")
                        && formData.tortureType && formData.tortureType.includes("Psicológica") 
                        && formData.tortureTypePsy && formData.tortureTypePsy.includes("tortureTypePsyOther") 
                        && (
                            <TextInput
                                lenght="35"
                                source="tortureTypePsyOther"
                                {...rest}
                            />
                        )
                    }
                </FormDataConsumer>

                <FormDataConsumer> 
                    {({ formData, ...rest }) =>
                        formData.tortureOrMaltreatment &&
                        formData.tortureOrMaltreatment.includes("Maltratos") && (<>
                            <CheckboxGroupInput
                                source="maltreatmentType"
                                choices={[
                                    { id: "Acceso a la justicia", name: "Acceso a la justicia" },
                                    { id: "Acceso a la salud", name: "Acceso a la salud" },
                                    { id: "Vínculo familiar", name: "Vínculo familiar" },
                                    { id: "maltreatmentTypeOther", name: "Otro" }
                                ]}
                                options={{ labelposition: "left" }}
                                validate={[required(), minLengthList]}
                                //onChange={handleChange}
                                //onChange={e => handleChange(e)}
                                {...rest}
                            />
                        </>)
                    }
                </FormDataConsumer>

                <FormDataConsumer>
                    {({ formData, ...rest }) =>
                        formData.tortureOrMaltreatment && formData.tortureOrMaltreatment.includes("Maltratos")
                        && formData.maltreatmentType && formData.maltreatmentType.includes("maltreatmentTypeOther") && (
                            <TextInput source="maltreatmentTypeOther" {...rest} />
                        )
                    }
                </FormDataConsumer>

                <TextInput source="factDescription" multiline />

                <CheckboxGroupInput
                    source="tortureTime"
                    choices={[
                        { id: "Al momento de la aprehensión", name: "Al momento de la aprehensión" },
                        { id: "Luego de la aprehensión", name: "Luego de la aprehensión" },
                        { id: "Durante la privación de libertad", name: "Durante la privación de libertad" },
                        { id: "tortureTimeOther", name: "Otro" }
                    ]}
                    options={{ labelposition: "left" }}
                />

                <FormDataConsumer>
                    {({ formData, ...rest }) =>
                        formData.tortureTime && formData.tortureTime.includes("tortureTimeOther") && (
                            <TextInput source="tortureTimeOther" {...rest} />
                        )
                    }
                </FormDataConsumer>

                <CheckboxGroupInput
                    source="tortureCustody"
                    choices={[
                        { id: "Policía", name: "Policía" },
                        { id: "Penitenciaría", name: "Penitenciaría" },
                        { id: "tortureCustodyOther", name: "Otro" }
                    ]}
                    options={{ labelposition: "left" }}
                />
                <FormDataConsumer>
                    {({ formData, ...rest }) =>
                        formData.tortureCustody && formData.tortureCustody.includes("tortureCustodyOther") && (
                            <TextInput source="tortureCustodyOther" {...rest} />
                        )
                    }
                </FormDataConsumer>

                <ReferenceInput
                    source="tortureAt"
                    validate={required()}
                    reference="placeGroups"
                    sort={{ field: "name", order: "ASC" }}
                    perPage={500}
                    filterToQuery={searchText => ({
                        name: { like: "%" + searchText + "%" }
                    })}
                >
                    <AutocompleteInput
                        optionText={optionRendererPlaces}
                        suggestionComponent={({ suggestion, query, isHighlighted, props }) => (
                            <div {...props}>
                                { suggestion.name }
                            </div>
                        )}
                    />
                </ReferenceInput>
                <FormDataConsumer>
                    {({ formData, ...rest }) =>
                        formData.tortureAt === "tortureAtOther" ? (
                            <TextInput source="tortureAtOther" {...rest} />
                        ) : ''
                    }
                </FormDataConsumer>

                <FormDataConsumer>
                    {({ formData, ...rest }) =>
                        ( formData.tortureAt !== "tortureAtOther" && formData.tortureAt > -1 ) ? (
                            <ReferenceInput
                                source="tortureAtPlaceId"
                                validate={required()}
                                reference="places"
                                sort={{ field: "name", order: "ASC" }}
                                perPage={500}
                                filterToQuery={searchText => ({
                                    name: { like: "%" + searchText + "%" }
                                })}
                                filter={{ placeGroupId: formData.tortureAt }}
                                {...rest}
                            >
                                <AutocompleteInput
                                    optionText={optionRendererPlaces}
                                    suggestionComponent={({ suggestion, query, isHighlighted, props }) => (
                                        <div {...props}>
                                            { suggestion.placeGroupId === formData.tortureAt 
                                                ? `\u00A0${suggestion.name || ''} ${suggestion.departament || ''} ${suggestion.district || ''}\u00A0`
                                                : '' }
                                        </div>
                                    )}
                                />
                            </ReferenceInput>
                        ) : ''
                    }
                </FormDataConsumer>

                <FormDataConsumer>
                    {({ formData, ...rest }) =>
                        formData.tortureAtPlaceId && formData.tortureAtPlaceOther && (
                            <TextInput source="tortureAtPlaceOther" {...rest} />
                        )
                    }
                </FormDataConsumer>

                <ReferenceInput
                    source="aggressorId"
                    reference="aggressors"
                    sort={{ field: "id", order: "ASC" }}
                    validate={required()}
                >
                    <SelectInput resettable optionText="name" />
                </ReferenceInput>
                <FormDataConsumer>
                    {({ formData, ...rest }) =>
                        formData.aggressorId &&
                        formData.aggressorId ===
                            OTHERCONSTANTS.TORTURE_AGGRESSORS_OTHER && (
                            <TextInput source="aggressorOther" {...rest} />
                        )
                    }
                </FormDataConsumer>

                <TextInput source="aggressorInstitution" />

                <TextInput source="aggressorName" />

                <TextInput source="medicName" />

                <BooleanInput source="isPhoto" />

            </FormTab>


            {/* 3 form - Measures */}
            <FormTab label={translate("report_tab_measures")}>

                <CheckboxGroupInput
                    source="urgentRequestType"
                    choices={[
                        { id: "Denuncia a agente fiscal", name: "Denuncia a agente fiscal" },
                        { id: "Denuncia ante jugzado o tribunal", name: "Denuncia ante jugzado o tribunal" },
                        { id: "Solicitud de traslado", name: "Solicitud de traslado" },
                        { id: "Denuncia ante Dir. Gral. de DDH de MJ", name: "Denuncia ante Dir. Gral. de DDH de MJ" },
                        { id: "Denuncia ante Dir. Gral. de DDH de MI", name: "Denuncia ante Dir. Gral. de DDH de MI" },
                        { id: "urgentRequestTypeOther", name: "Otro" }
                    ]}
                    options={{ labelposition: "left" }}
                />
                <FormDataConsumer>
                    {({ formData, ...rest }) =>
                        formData.urgentRequestType &&
                        formData.urgentRequestType.includes("urgentRequestTypeOther") && (
                            <TextInput
                            source="urgentRequestTypeOther"
                            {...rest}
                            />
                            )
                        }
                </FormDataConsumer>

                <FormDataConsumer>
                    {({ formData, ...rest }) =>
                        formData.urgentRequestType && formData.urgentRequestType.includes("Denuncia a agente fiscal") && (<>
                            <TextInput source="caseNumber" validate={validateCaseNumber} {...rest} />
                        </>)
                    }
                </FormDataConsumer>
                <FormDataConsumer>
                    {({ formData, ...rest }) =>
                        formData.urgentRequestType && formData.urgentRequestType.includes("Denuncia a agente fiscal") && (<>
                            <TextInput source="caseTitle" {...rest} />
                        </>)
                    }
                </FormDataConsumer>
                <FormDataConsumer>
                    {({ formData, ...rest }) =>
                        formData.urgentRequestType && formData.urgentRequestType.includes("Denuncia a agente fiscal") && (<>
                            <TextInput source="caseFiscalAgent" {...rest} />
                        </>)
                    }
                </FormDataConsumer>

            </FormTab>

            
            {/* 4 form - Victim */}
            <FormTab label={translate("report_tab_victim")}>
                
                <TextInput source="victimName" validate={required()} />

                <TextInput source="victimCin" validate={validateVictimCin} />

                <TextInput source="victimNationality" />

                <DateInput source="victimBirthdate" validate={[required(), validDate, maxDateNow]} label={translate("resources.reports.fields.victimBirthdate")} />                

                <TextInput source="victimAge" validate={validateVictimAge} />

                <SelectInput resettable
                    source="victimGender"
                    choices={[
                        { id: "Masculino", name: "Masculino" },
                        { id: "Femenino", name: "Femenino" }
                    ]}
                    options={{ labelposition: "left" }}
                    validate={required()}
                />

                <FormDataConsumer>
                    {({ formData, ...rest }) =>
                        formData.victimGender &&
                        formData.victimGender.includes("Femenino") && (
                            <BooleanInput source="isVictimPregnant" /*validate={required()}*/ {...rest} />
                        )
                    }
                </FormDataConsumer>

                <BooleanInput source="isVictimLgbti" /*validate={required()}*/ />

                <BooleanInput source="isVictimNative" />
                <FormDataConsumer>
                    {({ formData, ...rest }) =>
                        formData.isVictimNative && (<div className="mb-20">
                            <SelectInput resettable
                                source="victimTribe"
                                choices={[
                                    { id: "Aché", name: "Aché" },
                                    { id: "Avá Guaraní", name: "Avá Guaraní" },
                                    { id: "Mbyá", name: "Mbyá" },
                                    { id: "Páî Tavyterâ", name: "Páî Tavyterâ" },
                                    { id: "Guaraní Ñandeva", name: "Guaraní Ñandeva" },
                                    { id: "Guaraní Occidental", name: "Guaraní Occidental" },
                                    { id: "Toba Maskoy", name: "Toba Maskoy" },
                                    { id: "Enlhet Norte", name: "Enlhet Norte" },
                                    { id: "Enxet Sur", name: "Enxet Sur" },
                                    { id: "Sanapaná", name: "Sanapaná" },
                                    { id: "Toba", name: "Toba" },
                                    { id: "Angaité", name: "Angaité" },
                                    { id: "Guaná", name: "Guaná" },
                                    { id: "Nivaclé", name: "Nivaclé" },
                                    { id: "Maká", name: "Maká" },
                                    { id: "Manjui", name: "Manjui" },
                                    { id: "Ayoreo", name: "Ayoreo" },
                                    { id: "Yvytoso", name: "Yvytoso" },
                                    { id: "Tomárâho", name: "Tomárâho" },
                                    { id: "Toba-Qom", name: "Toba-Qom" },
                                    { id: "victimTribeOther", name: "Otro" }
                                ]}
                                options={{ labelposition: "left" }}
                                {...rest}
                            />
                        </div>)
                    }
                </FormDataConsumer>
                <FormDataConsumer>
                    {({ formData, ...rest }) =>
                        formData.isVictimNative
                        && formData.victimTribe && formData.victimTribe.includes("victimTribeOther") && (
                            <TextInput source="victimTribeOther" {...rest} />
                        )
                    }
                </FormDataConsumer>

                <BooleanInput source="isVictimDisabled" />
                <FormDataConsumer>
                    {({ formData, ...rest }) =>
                        formData.isVictimDisabled && (<div className="mb-20">
                            <SelectInput resettable
                                source="victimDisabilityType"
                                validate={required()}
                                choices={[
                                    { id: "Física", name: "Física" },
                                    { id: "Auditiva", name: "Auditiva" },
                                    { id: "Visual", name: "Visual" },
                                    { id: "Intelectual", name: "Intelectual" },
                                    { id: "Psicosocial", name: "Psicosocial" },
                                    { id: "Multidiscapacidad", name: "Multidiscapacidad" },
                                    { id: "victimDisabilityTypeOther", name: "Otro"}
                                ]}
                                options={{ labelposition: "left" }}
                                {...rest}
                            />
                        </div>)
                    }
                </FormDataConsumer>
                <FormDataConsumer>
                    {({ formData, ...rest }) =>
                        formData.isVictimDisabled &&
                        formData.victimDisabilityType && formData.victimDisabilityType.includes("victimDisabilityTypeOther") && (
                            <TextInput source="victimDisabilityTypeOther" {...rest} />
                        )
                    }
                </FormDataConsumer>

                <BooleanInput source="isVictimAfro" /*validate={required()}*/ />

                <SelectInput resettable
                    source="isWillingToFileReport"
                    validate={required()}
                    choices={[
                        { id: "Si", name: "Si" },
                        { id: "No", name: "No" },
                        { id: "isWillingToFileReportOther", name: "Otro" }
                    ]}
                    options={{ labelposition: "left" }}
                />
                <FormDataConsumer>
                    {({ formData, ...rest }) =>
                        formData.isWillingToFileReport && formData.isWillingToFileReport.includes("isWillingToFileReportOther") && (
                            <TextInput source="isWillingToFileReportOther" {...rest} />
                        )
                    }
                </FormDataConsumer>

            </FormTab>

        </TabbedForm>
    </Create>
);

export default translate(ReportCreate);
