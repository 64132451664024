import {
    AUTH_LOGIN,
    AUTH_LOGOUT,
    AUTH_ERROR,
    AUTH_GET_PERMISSIONS,
    AUTH_CHECK
} from "react-admin";

import { token } from "../utils/storage";
import { URL } from "../constants/env";
import logout from "../utils/session";



export function customLogin ({ username, password, otpToken }) {
    const request = new Request(
        `${URL}/users/login`,
        {
            method: "POST",
            body: JSON.stringify({ 
                email: username, 
                password: password,
                otpToken: otpToken
            }),
            headers: new Headers({ "Content-Type": "application/json" })
        }
    );
    return fetch(request)
        .then(response => {
            if (response.status < 200 || response.status >= 300) {
                throw new Error(response.statusText);
            }
            return response.json();
        })
        .then((data) => {
            // console.log(data);
            let { token, userProfile, loginError, otpData } = data;
            
            if (loginError && loginError.code === 'NOT_ACTIVE_USER') {
                localStorage.setItem("otpTokenMissing", 'false');
                localStorage.setItem("otpEnabledMissing", 'false');
                return Promise.reject("NOT_ACTIVE_USER");
            } else if (loginError && loginError.code === 'INVALID_OTP_TOKEN') {
                localStorage.setItem("otpTokenInvalidError", "otp.validate.validateFailed");
                return Promise.reject("otp.validate.validateFailed");
            } else if (loginError && loginError.code === 'OTP_TOKEN_MISSING') {
                localStorage.setItem("otpTokenInvalidError", "");
                localStorage.setItem("otpTokenMissing", 'true');
                localStorage.setItem("otpEnabledMissing", 'false');
                localStorage.setItem("username", username);
                localStorage.setItem("password", password);
                return Promise.reject("otp.validate.otpTokenMissing");
            } else if (loginError && loginError.code === 'OTP_ENABLED_MISSING') {
                localStorage.setItem("otpTokenInvalidError", "");
                localStorage.setItem("otpTokenMissing", 'false');
                localStorage.setItem("otpEnabledMissing", 'true');
                localStorage.setItem("otpData", JSON.stringify(otpData));
                localStorage.setItem("username", username);
                localStorage.setItem("password", password);
                return Promise.reject("otp.validate.otpEnabledMissing");
            }
            else {
                let name = userProfile.firstname + " " + userProfile.lastname;
                localStorage.setItem("token", token);
                localStorage.setItem("userId", userProfile.userId);
                localStorage.setItem("institutionId", userProfile.institutionId);
                localStorage.setItem("name", name);
                localStorage.setItem("role", userProfile.role); 
                localStorage.setItem("email", userProfile.email);
                localStorage.setItem("lang", 'spanish');
    
                localStorage.setItem("otpTokenInvalidError", "");
                localStorage.setItem("otpTokenMissing", "");
                localStorage.setItem("otpEnabledMissing", "");
                localStorage.setItem("otpData", "");
                localStorage.setItem("username", "");
                localStorage.setItem("password", "");
                window.location.replace("/");
            }
        });
};

export default async (type, params) => {
    if (type === AUTH_LOGIN) { }

    if (type === AUTH_LOGOUT) {
        logout(token);
        //@todo remove localStorage
        return Promise.resolve();
    }

    if (type === AUTH_ERROR) {
        const status = params.status;
        if (status === 401 || status === 403) {
            localStorage.removeItem("token");
            return Promise.reject();
        }
        return Promise.resolve();
    }

    if (type === AUTH_CHECK) {
        Promise.resolve();
    }

    if (type === AUTH_GET_PERMISSIONS) {
        const role = localStorage.getItem("role");
        return role ? Promise.resolve(role) : Promise.reject();
    }

    // return Promise.reject("Unknown method");
    Promise.resolve();
};