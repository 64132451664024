import React, { Component } from "react";
import Header from "./Header";
import Content from "./Content";
import Footer from "./Footer";
import "./css/Main.css";

export default class Main extends Component {
    render() {
        return (
            <React.Fragment>
                <div className="wrap">
                    <Header />
                    <Content />
                    <Footer />
                </div>
            </React.Fragment>
        );
    }
}
