import React from "react";

import { FunctionField, TextInput, SimpleForm, Edit, Toolbar, TextField, SaveButton, required, maxLength, minValue } from "react-admin";
import { withStyles } from '@material-ui/core';
import { userId } from "../../utils/storage";
import OtpVerifySecretModal from "../OtpVerifySecretModal";

const toolbarStyles = {
    toolbar: {
        display: 'flex',
        justifyContent: 'space-between',
    },
};

const CustomToolbar = withStyles(toolbarStyles)(props => (
    <Toolbar {...props}>
        <SaveButton />
    </Toolbar>
));

const ProfileEdit = ({ staticContext, ...props }) => {
    return (
        <Edit
            redirect={false} // I don't need any redirection here, there's no list page
            {...props}
            id={userId}
            resource="users"
            basePath="/myProfile"
            title="My profile"
        >
            <SimpleForm toolbar={<CustomToolbar />}>
                <TextInput source="firstname" validate={required()} />
                <TextInput source="lastname" validate={required()} />
                <TextInput source="position" validate={maxLength(50)} />
                <TextInput source="employer" validate={maxLength(50)} />
                <TextInput source="number" validate={[required()]} />
                <TextField source="email" />
                <FunctionField source="otp_enabled" render={record => {
                    return <OtpVerifySecretModal userId={record.id} />
                }} />
            </SimpleForm>
        </Edit>
    );
};

export default ProfileEdit;