import React from "react";
import {
    Grid,
    Card,
    CardActions,
    CardContent,
    Button,
    Typography
} from "@material-ui/core";
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { Title } from "react-admin";
import { Map, TileLayer, Marker, Popup } from "react-leaflet";
import MarkerClusterGroup from "react-leaflet-markercluster";
import "leaflet/dist/leaflet.css";
import "react-leaflet-markercluster/dist/styles.min.css";
import moment from 'moment'
import L from "leaflet";
import request from '../../../../utils/request';
import _ from 'lodash';

delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
    iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
    iconUrl: require("leaflet/dist/images/marker-icon.png"),
    shadowUrl: require("leaflet/dist/images/marker-shadow.png")
});

var d1 = new Date(new Date().getTime() - (new Date().getTimezoneOffset() * 60000));
d1.setDate(d1.getDate() - 365);
var d2 = new Date(new Date().getTime() - (new Date().getTimezoneOffset() * 60000) + 24*60*60*1000);
export default class Maps extends React.Component {
    state = {
        lat: -24.10655,
        lng: -58.30051,
        zoom: 6,
        markers: [],
        filtered: null,
        startDate: d1,
        endDate: d2,
        filterStatus: 'all'
    };

    componentDidMount() {
        this.all();
    }

    filterByStatus = status => {
        this.setState({
            filtered: this.state.markers.filter(
                marker => marker.status === status
            ),
            filterStatus: status
        });
    };


    setStartDate = value => {
        this.setState({
            startDate: value
        },function(){
            this.all();
        });
    }

    setEndDate = value => {
        this.setState({
            endDate: value
        },function(){
            this.all();
        });
    }

    all() {
        request({
            method: 'post',
            url: `/reports/geojson_public`,
            data: {
                'startDate': this.state.startDate, 
                'endDate': this.state.endDate
            }
        }).then(res => {
            this.setState({ markers: res || [] });
            this.setState({ filtered: null });
        });
    }

    reset() {
        this.setState({ filtered: null, filterStatus: 'all' });
    }

    render() {
        const markers = this.state.filtered || this.state.markers || [];
        const center = [this.state.lat, this.state.lng];
        const acceptedMarker = L.icon({
            iconUrl: require('../../../../assets/Markers/accepted.svg'),
            iconRetinaUrl: require('../../../../assets/Markers/accepted.svg'),
            iconSize: [32,32]
        });
        const resolvedMarker = L.icon({
            iconUrl: require('../../../../assets/Markers/resolved.svg'),
            iconRetinaUrl: require('../../../../assets/Markers/resolved.svg'),
            iconSize: [32,32]
        });
        const defaultMarker = L.icon({
            iconUrl: require('../../../../assets/Markers/default.svg'),
            iconRetinaUrl: require('../../../../assets/Markers/default.svg'),
            iconSize: [32,32]
        });
        const MarkersUnfiltered = _.map(markers, (marker, idx) => {
            let status = '';
            let markerIcon = '';

            switch(marker.status) {
                case 'accepted':
                    status = 'Aceptado';
                    markerIcon = acceptedMarker;
                    break;
                case 'resolved':
                    status = 'Resuelta';
                    markerIcon = resolvedMarker;
                    break;
                default:
                    status = 'Estado no válido';
                    markerIcon = defaultMarker;
            }
            return (
                <Marker position={[marker.lat, marker.lng]} key={`marker_${idx}`} icon={markerIcon}>
                    <Popup>
                        Lugar: {marker.name} <br />
                        Fecha captura: {moment(marker.captureDatetime).format('DD/MM/yyyy HH:mm')} <br />
                        Sexo: {marker.gender}<br />
                        Estado: {status}<br />
                    </Popup>
                </Marker>
            )
        });
        const styles = {
            card: {
                width: "75%"
            }
        };

        const button = {
            primary: {
                background: "#303f9f"
            },
            accepted: {
                background: "#1E90FF"
            },
            resolved: {
                background: "#008000"
            },
            unselect: {
                background: "#8D8D8D"
            },
        };

        return (
            <React.Fragment>
                <Title title="Mapas" />
                <Grid
                    container
                    spacing={0}
                    direction="column"
                    alignItems="center"
                    justify="center"
                >
                    <Card style={styles.card}>
                        <CardActions>
                            <Typography type="title" gutterBottom>
                                Filtrar por Estado
                            </Typography>
                            <Button
                                size="small"
                                variant="contained"
                                style={this.state.filterStatus === 'all' ? button.primary : button.unselect}
                                color="primary"
                                onClick={() => this.reset()}
                            >
                                Todos
                            </Button>

                            <Button
                                size="small"
                                variant="contained"
                                style={this.state.filterStatus === 'accepted' ? button.accepted : button.unselect}
                                color="primary"
                                onClick={() => this.filterByStatus("accepted")}
                            >
                                Aceptada
                            </Button>

                            <Button
                                size="small"
                                variant="contained"
                                color="primary"
                                style={this.state.filterStatus === 'resolved' ? button.resolved : button.unselect}
                                onClick={() => this.filterByStatus("resolved")}
                            >
                                Resuelta
                            </Button>
                            <Typography type="title" gutterBottom>
                                Filtrar por fecha

                            </Typography>
                            <div style={{zIndex: '997'}}>
                                <DatePicker
                                    dateFormat="dd/MM/yyyy"
                                    selected={this.state.startDate}
                                    onChange={this.setStartDate}
                                    selectsStart
                                    startDate={this.state.startDate}
                                    endDate={this.state.endDate}
                                />
                                <DatePicker
                                    dateFormat="dd/MM/yyyy"
                                    selected={this.state.endDate}
                                    onChange={this.setEndDate}
                                    selectsEnd
                                    endDate={this.state.endDate}
                                    minDate={this.state.startDate}
                                />
                            </div>
                        </CardActions>
                        <CardContent>
                            <div>
                                <Map
                                    center={center}
                                    zoom={this.state.zoom}
                                    maxZoom={18}
                                    style={{ height: "400px" }}
                                    scrollWheelZoom={false}
                                >
                                    <TileLayer
                                        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                    />
                                    {MarkersUnfiltered && MarkersUnfiltered.length ? (
                                        <MarkerClusterGroup>
                                            {MarkersUnfiltered}
                                        </MarkerClusterGroup>
                                    ) : ''}
                                </Map>
                            </div>
                        </CardContent>
                    </Card>
                </Grid>
            </React.Fragment>
        );
    }
}