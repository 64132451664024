import React from "react";

import { Layout } from "react-admin";
import MyMenu from "./MyMenu";
import {returnMyAppBar,passVar} from "./MyAppBar";
import * as COLORS from "../constants/colors";

const styles = {
    MyLayout: {
        backgroundColor: COLORS.BG_CREAM
    }
};

const MyLayout = lang => props => {
    passVar(lang);
    return (        
        <Layout {...props} menu={MyMenu} appBar={returnMyAppBar} style={styles.MyLayout}/>
    )
}

export default MyLayout;
