import React, { Component, Fragment, useState } from "react";
// import {Progress} from 'reactstrap';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { Grid, Card, CardHeader, CardContent } from "@material-ui/core";
// import { makeStyles } from "@material-ui/core/styles";
import GridList from "@material-ui/core/GridList";
import GridListTile from "@material-ui/core/GridListTile";
import Lightbox from 'react-image-lightbox'
import 'react-image-lightbox/style.css'
import slugify from "slugify";

import GridListTileBar from "@material-ui/core/GridListTileBar";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";

import Compressor from 'compressorjs';

import request from "../utils/request";
import { URL } from "../constants/env";
import { 
    MAX_DOC_FILE_SIZE,
    MAX_IMAGE_DIMENSION,
    MAX_IMAGE_QUALITY
} from '../constants/sizeMax';
import { token } from "../utils/storage";


const ImageGridPrint = ({ tileData }) => {
    const classes = {
        root: {},
        row: {
          maxWidth: '950px',
        },
        image: {
          objectFit: 'contain',
          maxHeight: '400px',
          maxWidth: '320px',
          width: 'auto',
          margin: '10px 10px'
        },
    };
    
    // Convert tileData into a nested array
    const gridData = [];
    if (tileData) {
      for (let i = 0; i < tileData.length; i += 2) {
        const row = [tileData[i], tileData[i + 1]];
        gridData.push(row);
      }
    }

    const getTilePath = (tile) => {
        let path = '';
        if (tile) {
            path = `${URL}/files/${tile}?access_token=${token}`;
        }
        return path;
    }
  
    return (
      <div className={`image-list ${classes.root}`}>
        <span style={{ marginLeft: '20px' }}>Imágenes</span>
        {gridData && gridData.length ? (
          <div style={classes.root}>

            {gridData.map((row, rowIndex) => (
              <div key={rowIndex} style={classes.row} className="break-before no-break">
                {row.map((tile, tileIndex) => (
                  tile ? (<img
                    style={classes.image}
                    className="no-break"
                    src={getTilePath(tile)}
                    alt={`Image ${tileIndex}`}
                    key={tileIndex} 
                  />) : ''
                ))}
              </div>
            ))}

          </div>
        ) : ('')}
      </div>
    );
};


export const ImageGridList = props => {
    const tileData = props.images;
    const onDelete = props.onDelete;
    const [isOpen, setIsOpen] = useState(false);
    const [lightboxSlideIndex, setLightboxSlideIndex] = useState(0);

    const getTilePath = (tile) => {
      let path = '';
      if (tile) {
          path = `${URL}/files/${tile}?access_token=${token}`;
      }
      return path;
    }

    const getTilePathByIndex = (slideIndex) => {
      let path = '';
      if (slideIndex < tileData.length) {
          path = tileData && tileData[slideIndex] ? `${URL}/files/${tileData[slideIndex]}?access_token=${token}` : '';
      }
      return path;
    }

    const classes = {
        root: {
          display: 'grid',
          gridTemplateColumns: 'repeat(3, 1fr)',
          gap: '20px',
          width: "100%",
            // backgroundColor: theme.palette.background.paper
        },
        gridList: {
            width: "100%",
            height: "100%",
            // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
            transform: "translateZ(0)"
        },
        button: {
          width: "300px",
          border: "none",
          backgroundColor: "transparent",
          padding: "none"
        },
        img: {
          objectFit: 'contain',
          maxHeight: '300px',
          maxWidth: '300px',
          height: '300px',
          width: 'auto',
          boxShadow: "#727272 0px 3px 12px 0px"
        },
        icon: {
            color: "white"
        }
    };

    // Convert tileData into a nested array
    const gridData = [];
    if (tileData) {
      for (let i = 0; i < tileData.length; i += 2) {
        const row = [tileData[i], tileData[i + 1]];
        gridData.push(row);
      }
    }

    const getTileIndexByName = (tile) => {
      for (let i = 0; i < tileData.length; i++) {
        if (tileData[i] === tile) {
          return i;
        }
      } 
    }
    
    return (<>
        {props.isPrinting ? (
          tileData && (<>
            <ImageGridPrint tileData={tileData} />
          </>)
        ) : (
          <Card>
              <CardHeader title={`Imágenes`} />
              <CardContent>
                <div style={classes.root}>
                  {gridData.map((row, rowIndex) => (
                    <Fragment key={rowIndex}>
                      {row.map((tile, tileIndex) => (
                        tile ? (
                          <button type="button" key={`${rowIndex}-${tileIndex}`}
                            onClick={() => { setIsOpen(true); setLightboxSlideIndex( getTileIndexByName( tile ) );}}
                            style={classes.button} className="no-break"
                          >
                            <img
                              style={classes.img}
                              src={getTilePath(tile)}
                              alt={`Image ${tile.index}`}
                            />
                          </button>
                        ) : ''
                      ))}
                      </Fragment>
                    ))}
                  </div>
              </CardContent>
              {isOpen && (
                  <Lightbox
                      mainSrc={getTilePathByIndex(lightboxSlideIndex)}
                      nextSrc={getTilePathByIndex((lightboxSlideIndex + 1) % tileData.length)}
                      prevSrc={getTilePathByIndex((lightboxSlideIndex + tileData.length - 1) % tileData.length)}
                      onCloseRequest={() => setIsOpen(false)}
                      onMovePrevRequest={() => {
                          let prevIndex = (lightboxSlideIndex + tileData.length - 1) % tileData.length
                          setLightboxSlideIndex(prevIndex)
                      }}
                      onMoveNextRequest={() => {
                          let nextIndex = (lightboxSlideIndex + 1) % tileData.length
                          setLightboxSlideIndex(nextIndex)
                      }}
                  />
              )}
          </Card>
        )}
    </>);
};

const image2report = async (name, reportId) => {
    return await request({
        method: "put",
        url: `/reports/${reportId}/files/pics`,
        data: {
            name
        }
    })
        .then(res => {
            // console.log("SAVED IMAGE TO REPORT ID:", reportId);
        })
        .catch(error => {
            console.log("ERROR SAVING IMAGE TO REPORT ID:", reportId);
        });
};

class Images extends Component {
  state = {
    reportId: null,
    images: []
  };

  loadImages = () => {
    const { reportId } = this.state;
    return request({
      method: 'get',
      url: `/reports/${reportId}`
    })
      .then((res) => {
        if (res && res.files) {
          let filesTree = JSON.parse(res.files);
          this.setState({ images: filesTree.pics });
        }
      })
      .catch((error) => {
        console.log('ERROR GETTING IMAGES', error);
      });
  };

  deleteFromDisk = (name) => {
    request({
      method: 'delete',
      url: `/files/${name}`
    })
      .then((res) => {
        // console.log('deleted from disk')
      })
      .catch((error) => {
        console.log('error deleting from disk', error);
      });
  };

  onDelete = (name) => {
    request({
      method: 'delete',
      url: `/reports/${this.state.reportId}/files/pics/${name}`
    })
      .then((res) => {
        // console.log("deleted from report", id);
      })
      .then((res) => {
        this.deleteFromDisk(name);
        this.loadImages();
      })
      .catch((error) => {
        console.log('error deleting from report', error);
      });
  };

  

  onFileLoad = (reportId) => (e) => {
    const image = e.target.files[0];
    const data = new FormData();
    let name = image.name.replace(/\.[^/.]+$/, "");
    const rand = Date.now();
    const ext = image.name.split(".").pop();

    // Check file type and size before resizing
    if (this.checkMimeType(image)) {
      new Compressor(image, {
        quality: MAX_IMAGE_QUALITY, // Adjust the compression quality as needed
        maxWidth: MAX_IMAGE_DIMENSION, // Adjust the maximum width as needed
        maxHeight: MAX_IMAGE_DIMENSION, // Adjust the maximum height as needed
        success: (compressedImage) => {
          // Create a new File with the compressed image
          const resizedFile = new File([compressedImage], `${name}-${rand}.${ext}`, { type: image.type });

          name = slugify(`${name}-${rand}.${ext}`);
          data.append("file", resizedFile, name);
          toast.info("Procesando imagen subida");
          request({
            method: "post",
            url: "/files",
            data: data,
          })
            .then((res) => {
              return image2report(name, reportId);
            })
            .then((res) => {
              setTimeout(() => {
                this.loadImages().then(() => {
                  toast.success("¡La imagen se subió con éxito!");
                  this.fileInput.value = "";
                });
              }, 1500);
            })
            .catch((error) => {
              toast.error("¡La subida de la imagen ha fallado!");
              this.fileInput.value = "";
            });
        },
        error: (error) => {
          console.log("Compression error:", error);
          toast.error("Error al comprimir la imagen");
        },
      });
    }
  };

  checkMimeType = (image) => {
    let file = image;
    let error;
    const types = ['image/png', 'image/jpeg', 'image/webp'];

    // Compare file type and find doesn't match
    if (types.every((type) => file.type !== type)) {
      error = 'No se puede subir un formato ' + file.type + ' para imágenes.\n';
      toast.error(error);
      this.fileInput.value = '';
      return false;
    }
    return true;
  };

  checkFileSize = (image) => {
    let file = image;
    let size = MAX_DOC_FILE_SIZE;
    let error;

    if (file.size > size) {
      let pic_max_size = Math.round(size / 1024 / 1024);
      error = 'La imagen que quieres subir excede los ' + pic_max_size + ' MB permitidos.\n';
      toast.error(error);
      this.fileInput.value = '';
      return false;
    }
    return true;
  };

  componentDidMount() {
    this.setState(
      {
        reportId: this.props.match.params.id
      },
      () => this.loadImages()
    );
  }

  render() {
    const { reportId, images } = this.state;
    const onFileLoad = this.onFileLoad;
    const onDelete = this.onDelete;

    return (
      <Grid>
        <Card>
          <CardHeader title={`Subir imágenes para #DEF${reportId}`} />
          <CardContent>
            <div>
              <input
                id="file_input_file"
                type="file"
                name="image"
                onChange={onFileLoad(reportId)}
                ref={(ref) => (this.fileInput = ref)}
              />
            </div>
            <div>
              <ToastContainer />
            </div>
          </CardContent>
        </Card>
        <br />
        <ImageGridList images={images} onDelete={onDelete} isPrinting={this.props.isPrinting} />
      </Grid>
    );
  }
}

export default Images;