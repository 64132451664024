import React from "react";
import { translate } from "react-admin";
import { Card, CardContent, Button } from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import DeleteIcon from "@material-ui/icons/Delete";
import { token } from "../utils/storage";
import request from "../utils/request";
import moment from "moment";
import logout from "../utils/session";


const dateFieldRender = record => {
    const utcDateString = moment.utc(record.datetime).format();
    const localDate = moment( utcDateString );
    return localDate.format('DD/MM/yyyy HH:mm');
}

class SessionList extends React.Component {
    state = {
        sessions: [],
        loading: false
    };

    getSessions = () => {
        request({
            method: "get",
            url: `/users/sessions`
        }).then(result => {
            if (result && result.length > 0) {
                const sessions = result;
                this.setState({ sessions, loading: false });
            } else {
                this.setState({ sessions: [], loading: false });
            }
        });
    };

    reload = () => {
        this.setState({ loading: true });
        this.getSessions();
    };

    closeSession = (sessionIndex) => {
        let session = this.state.sessions[sessionIndex];
        if (session.token === token) {
            return logout();
        }
        request({
            method: "delete",
            url: `/users/sessions/${session.id}`
        })
            .then(res => {
                window.location.reload();
                this.reload();
                console.log("SESSION CLOSED", session.id);
            })
            .catch(error => {
                console.log("ERROR: SESSION NOT CLOSED");
            });
    };

    componentDidMount = async () => {
        this.getSessions();
    };

    render() {
        const { sessions, loading } = this.state;
        const { translate } = this.props;

        if (loading) return <h1>Cargando</h1>;

        return (
            <Card>
                <CardContent>
                    <h1>{translate("mySessions")}</h1>
                </CardContent>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell align="right">
                                {translate("resources.sessions.fields.client")}
                            </TableCell>
                            <TableCell align="right">
                                {translate("resources.sessions.fields.ip")}
                            </TableCell>
                            <TableCell align="right">
                                {translate(
                                    "resources.sessions.fields.datetime"
                                )}
                            </TableCell>
                            <TableCell align="right">
                                {translate("action")}
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {sessions.map((session, sessionIndex) => (
                            <TableRow key={session.id}>
                                <TableCell align="right">
                                    {session.token === token
                                        ? "ACTUAL"
                                        : session.client}
                                </TableCell>
                                <TableCell align="right">
                                    {session.ip}
                                </TableCell>

                                <TableCell align="right">
                                    {dateFieldRender(session)}
                                </TableCell>

                                <TableCell>
                                    <Button
                                        style={{
                                            backgroundColor: "#ea6847",
                                            color: "white"
                                        }}
                                        variant="outlined"
                                        color="default"
                                        onClick={() =>
                                            this.closeSession(sessionIndex)
                                        }
                                    >
                                        {translate("closeSessions")}
                                        <DeleteIcon />
                                    </Button>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </Card>
        );
    }
    // {
    //     (!sessions || sessions.length <= 0) && (
    //         <Card>
    //             <CardContent>
    //                 <h1 style={{ color: "#ea6847" }}>
    //                     {translate("noOpenSessions")}
    //                 </h1>
    //             </CardContent>
    //         </Card>
    //     );
    // }
}

export default translate(SessionList);
