import React, { Component } from "react";
import { connect } from "react-redux";
import { crudGetOne, UserMenu, MenuItemLink, translate } from "react-admin";
import SettingsIcon from "@material-ui/icons/Settings";
import LockOpenIcon from "@material-ui/icons/LockOpen";
import { userId, name } from '../utils/storage';
import { vCoordinator } from "../utils/auth";

class MyUserMenuView extends Component {
    render() {
        const { crudGetOne, profile, translate, ...props } = this.props;

        return (
            <UserMenu label={name} {...props}>
                <MenuItemLink
                    to="/myProfile"
                    primaryText={translate("profile")}
                    leftIcon={<SettingsIcon />}
                />
                {vCoordinator() && (
                    <MenuItemLink
                        to="/settings"
                        primaryText={translate("settings")}
                        leftIcon={<SettingsIcon />}
                    />
                )}
                <MenuItemLink
                    to="/changePassword"
                    primaryText={translate("reset")}
                    leftIcon={<LockOpenIcon />}
                />
            </UserMenu>
        );
    }
}

const mapStateToProps = state => {
    const resource = "Accounts";
    const id = {userId};
    const profileState = state.admin.resources[resource];

    return {
        profile: profileState ? profileState.data[id] : null
    };
};

const MyUserMenu = connect(
    mapStateToProps,
    { crudGetOne }
)(translate(MyUserMenuView));
export default MyUserMenu;
