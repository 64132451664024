import React, { useState } from 'react';
import {
    Collapse,
    Navbar,
    NavbarToggler,
    NavbarBrand,
    Nav,
    NavItem,
    NavLink
} from 'reactstrap';
import logo from "../../../../assets/logo-blanco.svg";
import "../css/Header.css";

const NavBar = (props) => {
    const [isOpen, setIsOpen] = useState(false);
    const toggle = () => setIsOpen(!isOpen);

    return (
        <Navbar style={{paddingLeft: "10%", paddingRight: "10%"}} className="navbar-nav" expand="md">
            <NavbarBrand href="/">
                <img
                    src={logo}
                    height="30"
                    className="logo"
                    alt="Imagen Defensores"
                />

            </NavbarBrand>
            <NavbarToggler onClick={toggle} className="navbar-toggler" />
            <Collapse isOpen={isOpen} navbar>
                <Nav className="ml-auto" navbar>
                    <NavItem>
                        <NavLink className="navbar-links" href="#resources_public">Recursos</NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink className="navbar-links" href="#maps_public">Mapa</NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink className="navbar-links" href="#visualizations_public">Visualizaciones</NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink className="navbar-links" href="/#/login">Ingresar</NavLink>
                    </NavItem>
                </Nav>
            </Collapse>
        </Navbar>
  );
}

export default NavBar;
