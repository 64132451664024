import axios from "axios";
import { URL } from '../constants/env';
import { token } from './storage'

const request = async options => {
    const onSuccess = function(response) {
        return response.data;
    };

    const onError = function(error) {
        const sup = JSON.stringify(error);
        const sap = JSON.parse(sup);
        let myError = error;
        if (error && error.response && sap && sap.response && sap.response.data) {
            if (
                sap.response.data.error.message ===
                "accessToken is required to logout"
            ) {
                myError = "error.logout";
            } else if (sap.response.data.error.message === "login failed") {
                myError = "error.login";
            } else {
                // myError = sap.response.data.error.message
            }
        } else {
            if (sap && sap.request && sap.request.response.includes("Failed to connect")) {
                myError = "error.offline";
            }
        }

        return Promise.reject(myError);
    };

    const client = axios.create({
        baseURL: URL,
        headers: { 
            Authorization: 'Bearer ' + token,
            Accept: 'application/json'
        }
    });

    return client(options)
        .then(onSuccess)
        .catch(onError);
};

export default request;
