import React from "react";
import { SimpleForm, Edit, Toolbar, NumberInput, maxLength, SaveButton, required } from "react-admin";
import TextInputName from './fields/TextInputName';
import { withStyles } from '@material-ui/core';

const toolbarStyles = {
    toolbar: {
        display: 'flex',
        justifyContent: 'space-between',
    },
};
const CustomToolbar = withStyles(toolbarStyles)(props => (
    <Toolbar {...props}>
        <SaveButton />
    </Toolbar>
));

const validateName = (value) => {
    var name = value;  
    if (name && name.trim() === "") {
        return 'No puede contener solamente espacio como carácter.';
    }
    if (name && parseInt(name) == name) {
        return 'No puede contener solamente números.';
    }
    return; 
};

const InstitutionEdit = props => (
    <Edit {...props}>
        <SimpleForm toolbar={<CustomToolbar />} redirect="list">
            {/* <NumberInput source="id"/> */}
            <TextInputName validate={[required(), validateName, maxLength(50)]} source="name"/>
        </SimpleForm>
    </Edit>
);

export default InstitutionEdit;
