import React, { Component } from "react";
import PropTypes from "prop-types";
import {
    Notification,
    translate,
    userLogin,
    showNotification
} from "react-admin";
import { propTypes, reduxForm, Field } from "redux-form";
import { push } from "react-router-redux";
import { connect } from "react-redux";
import compose from "recompose/compose";
import { Avatar, Button, Card, CardActions, CircularProgress, TextField, FormHelperText } from '@material-ui/core';
import LockIcon from "@material-ui/icons/Lock";
import {
    MuiThemeProvider,
    createMuiTheme,
    withStyles
} from "@material-ui/core/styles";
import { lightTheme } from "./themes";
import { Link } from "react-router-dom";
import OtpVerifySecretOnFirstLoginModal from "./OtpVerifySecretOnFirstLoginModal";
import { customLogin } from '../providers/authProvider';


const styles = theme => ({
    main: {
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
        alignItems: "center",
        justifyContent: "flex-start",
        backgroundRepeat: "repeat"
    },
    card: {
        minWidth: 300,
        marginTop: "6em"
    },
    avatar: {
        margin: "1em",
        display: "flex",
        justifyContent: "center"
    },
    icon: {
        backgroundColor: theme.palette.secondary.main
    },
    hint: {
        marginTop: "1em",
        display: "flex",
        justifyContent: "center",
        color: theme.palette.grey[500]
    },
    form: {
        padding: "0 1em 1em 1em"
    },
    input: {
        marginTop: "1em"
    },
    actions: {
        padding: "0 1em 1em 1em"
    },
    forgot_password: {
        padding: "0 0 0 4em"
    },
    h2: {
        fontSize: '25px',
        margin: '10px 0 15px 0',
    },
    text: {
        maxWidth: "350px",
        fontSize: '13px'
    },
});

const renderInput = ({
    meta: { touched, error } = {},
    input: { ...inputProps },
    ...props
}) => (
    <TextField
        autoComplete="off"
        error={!!(touched && error)}
        helperText={touched && error}
        {...inputProps}
        {...props}
        fullWidth
    />
);

class Login extends Component {
    state = {
        otpTokenMissing: false,
        username: "",
        password: ""
    };

    componentDidMount() {}

    login = (e) => {
        return customLogin(e)
            .catch((error) => {
                if (error.message === 'Unauthorized') {
                    this.props.showNotification('bad_credentials', 'warning');
                } 
                else if (error === 'NOT_ACTIVE_USER') {
                    this.props.showNotification('not_active_user', 'warning');
                }
                else {
                    this.setState({
                        otpTokenMissing: localStorage.getItem("otpTokenMissing") === "true",
                        otpEnabledMissing: localStorage.getItem("otpEnabledMissing") === "true",
                        otpData: localStorage.getItem("otpData") ? JSON.parse(localStorage.getItem("otpData")) : null,
                        username: localStorage.getItem("username") || "",
                        password: localStorage.getItem("password") || "",
                    });
                }
            });
    }

    render() {
        const { classes, handleSubmit, isLoading, translate } = this.props;
        const { otpTokenMissing, otpEnabledMissing, otpData } = this.state;

        const onOtpVerify = (next) => {
            this.login(this.state).then(next);
        }

        const getValidateError = () => {
            return (this.state.otpToken && localStorage.getItem("otpTokenInvalidError")) ? localStorage.getItem("otpTokenInvalidError") : null;
        }

        return (
            <div
                className={classes.main}
                style={{ background: '#E3E4E3' }}
            >
                <Card className={classes.card}>
                    <div className={classes.avatar}>
                        <Avatar className={classes.icon}>
                            <LockIcon />
                        </Avatar>
                    </div>
                    <form onSubmit={handleSubmit(this.login)}>
                        {/* Acount */}
                        <div className={classes.form} style={{display: ((otpTokenMissing || otpEnabledMissing) ? 'none' : '')}}>
                            <div className={classes.input}>
                                <Field
                                    autoFocus
                                    name="username"
                                    component={renderInput}
                                    label={translate("ra.auth.username")}
                                    disabled={isLoading}
                                    value={this.state.username}
                                    onChange={(e) => this.setState({ username: e.target.value, ...this.state })}
                                />
                            </div>
                            <div className={classes.input}>
                                <Field
                                    name="password"
                                    component={renderInput}
                                    label={translate("ra.auth.password")}
                                    type="password"
                                    disabled={isLoading}
                                    value={this.state.password}
                                    onChange={(e) => this.setState({ password: e.target.value, ...this.state })}
                                />
                            </div>
                        </div>
                        <Link
                            className={classes.forgot_password}
                            style={{display: ((otpTokenMissing || otpEnabledMissing) ? 'none' : '')}}
                            to="/resetForm"
                        >
                            {translate("forgot_password")}
                        </Link>
                        {/* OTP token input */}
                        {otpTokenMissing ? (
                            <div className={classes.form}>
                                <h2 className={classes.h2}>
                                    {translate('otp.title')}
                                </h2>
                                <div className={classes.text}>
                                    {translate('otp.validate.tokenInputDesc')}
                                </div>
                                <div className={classes.input}>
                                    <Field
                                        autoFocus
                                        name="otpToken"
                                        component={renderInput}
                                        label={translate("otp.validate.tokenInput")}
                                        disabled={isLoading}
                                        value={this.state.otpToken}
                                        onChange={(e) => { this.setState({ ...this.state, otpToken: e.target.value }) }}
                                        error={!!getValidateError()}
                                    />
                                    {getValidateError() ? (
                                        <FormHelperText error>{translate(getValidateError())}</FormHelperText>
                                    ) : ''}
                                </div>
                            </div>
                        ) : ''}
                        {/* OTP config modal */}
                        {otpEnabledMissing ? (
                            <div className={classes.form}>
                                <h2 className={classes.h2}>
                                    {translate('otp.title')}
                                </h2>
                                <div className={classes.text}>
                                    {translate('otp.generate.subtitle')}
                                </div>
                                {}
                                <OtpVerifySecretOnFirstLoginModal 
                                    otpData={otpData}
                                    otpToken={this.state.otpToken}
                                    setOtpToken={(otpToken) => { this.setState({ ...this.state, otpToken }) }}
                                    onOtpVerify={onOtpVerify} 
                                />
                                <Field
                                    name="otpToken"
                                    component={renderInput}
                                    value={this.state.otpToken}
                                    style={{ display: 'none' }}
                                />
                            </div>
                        ) : ''}
                        {/* Actions */}
                        <CardActions className={classes.actions}>
                            <Button
                                variant="raised"
                                type="submit"
                                color="primary"
                                disabled={
                                    isLoading || (otpTokenMissing && !this.state.otpToken)
                                }
                                className={classes.button}
                                fullWidth
                            >
                                {isLoading && (
                                    <CircularProgress size={25} thickness={2} />
                                )}
                                {translate("ra.auth.sign_in")}
                            </Button>
                        </CardActions>
                    </form>
                </Card>
                <Notification />
            </div>
        );
    }
}

Login.propTypes = {
    ...propTypes,
    authProvider: PropTypes.func,
    classes: PropTypes.object,
    previousRoute: PropTypes.string,
    translate: PropTypes.func.isRequired,
    userLogin: PropTypes.func.isRequired
};

const mapStateToProps = state => ({ isLoading: state.admin.loading > 0 });

const enhance = compose(
    translate,
    reduxForm({
        form: "signIn",
        validate: (values, props) => {
            const errors = {};
            const { translate } = props;
            if (!values.username) {
                errors.username = translate("ra.validation.required");
            }
            if (!values.password) {
                errors.password = translate("ra.validation.required");
            }
            if (values.password && values.password.length < 8) {
                errors.password = translate("pwd.min");
            }
            return errors;
        }
    }),
    connect(
        mapStateToProps,
        { userLogin }
    ),
    withStyles(styles)
);

const EnhancedLogin = enhance(Login);

// We need to put the MuiThemeProvider decoration in another component
// Because otherwise the withStyles() HOC used in EnhancedLogin won't get
// the right theme
const MyLoginPage = props => (
    <MuiThemeProvider theme={createMuiTheme(lightTheme)}>
        <EnhancedLogin
            {...props}
        />
    </MuiThemeProvider>
);

export default connect(
    null,
    {
        showNotification,
        push
    }
)(MyLoginPage);

// export default MyLoginPage;
