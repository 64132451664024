import React from "react";

import {
    List,
    Datagrid,
    TextField,
    ShowButton,
    EditButton,
    DeleteButton,
    Filter,
    NumberInput,
    ReferenceInput,
    AutocompleteInput
} from "react-admin";
import * as LIMITS from "../constants/limits";
import { userId } from '../utils/storage';


const optionRendererInstitution = institution =>
    `${institution.name}`;

const InstitutionFilter = props => (
    <Filter {...props}>
        <NumberInput label="ID" source="id" reference="institutions" />

        <ReferenceInput
            label="Nombre"
            source="id"
            reference="institutions"
            alwaysOn
            allowEmpty
            sort={{ field: "name", order: "ASC" }}
            perPage={LIMITS.LOCATIONS}
            filterToQuery={searchText => ( 
                { 
                    name: {like:'%' + searchText + '%'}
                } 
            )}
        >
            <AutocompleteInput
                optionText={optionRendererInstitution}
                suggestionComponent={({
                    suggestion,
                    query,
                    isHighlighted,
                    props
                }) => (
                    <div>
                        <div style={{}} {...props}>
                        {suggestion.name
                                .toLowerCase()
                                .includes(query.toLowerCase())
                                ? `\u00A0${suggestion.name}\u00A0`
                                : ""}
                        </div>
                    </div>
                )}
            />
        </ReferenceInput>
    </Filter>
);

const InstitutionList = props => (
    <List {...props} bulkActionButtons={false} 
    filters={<InstitutionFilter />}
    sort={{ field: 'id', order: 'ASC' }} exporter={false}>
        <Datagrid>
            <TextField source="id" />
            <TextField source="name" />
            <ShowButton />
            <EditButton />
            {userId == 1 ? (
                <DeleteButton />
            ) : null}
        </Datagrid>
    </List>
);

export default InstitutionList;