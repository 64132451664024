import React from "react";
import { SimpleForm, Edit, Toolbar, SaveButton } from "react-admin";
import TextInputName from './fields/TextInputName';
import { withStyles } from '@material-ui/core';

const toolbarStyles = {
    toolbar: {
        display: 'flex',
        justifyContent: 'space-between',
    },
};
const CustomToolbar = withStyles(toolbarStyles)(props => (
    <Toolbar {...props}>
        <SaveButton />
    </Toolbar>
));

const LocationEdit = props => (
    <Edit {...props}>
        <SimpleForm toolbar={<CustomToolbar />} redirect="list">
            <TextInputName source="name"/>
        </SimpleForm>
    </Edit>
);

export default LocationEdit;
