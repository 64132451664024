import React, { Component } from "react";
import {
    Grid,
    Card,
    Typography,
} from "@material-ui/core";
import { translate } from "react-admin";
import { Bar, Pie } from "react-chartjs-2";
import "../css/Visualizations.css";
import request from '../utils/request';

class Visualizations extends Component {
    componentDidMount() {
        this.getVisualization();
    }

    state = {
        total: 0,
        states: {},
        aggressors: {},
        genders: {},
        places: {}
    };

    getVisualization() {
        request({
            method: 'get',
            url: `/reports/visualizations_admin`
        })
        .then(res => {
            this.setState({
                total: res.total,
                states: res.states,
                aggressors: res.aggressors,
                genders: res.genders,
                places: res.places,
            });
        }).catch(error => console.log("getVisualization ERROR:" + error));
    }

    sortable_object = (object) => {
        let newObject = {};
        Object.keys(object)
          .sort(function(a,b){
              return object[b]-object[a]
          })
          .map(key => newObject[key] = object[key]);
        return newObject;
    }

    max_object = (object) => {
        let array = [];
        const MAX_OBJECT = 10;
        for (let value in object) {
            array.push([value, object[value]]);
        }

        // Only MAX_OBJECT elements.
        for (let i = array.length; i > MAX_OBJECT; i--){
            array.pop();
        }

        // Array to object again.
        let newObject = {};
        for(let index in array){
            newObject[array[index][0]] = array[index][1];
        }
        return newObject;
    }

    render() {
        const { translate } = this.props;
        const dataStates = {
            labels: Object.keys(this.state.states),
            datasets: [
                {
                    backgroundColor: [
                        "#828282",
                        "#ffca28",
                        "#1E90FF",
                        "#a05000",
                        "#FF2301",
                        "#008000"
                    ],
                    data: Object.values(this.state.states)
                }
            ]
        };

        const dataGender = {
            labels: Object.keys(this.state.genders),
            datasets: [
                {
                    label: "Por Sexo",
                    backgroundColor: ["#4c2b36", "#d58936"],
                    data: Object.values(this.state.genders)
                }
            ]
        };


        let places = this.state.places;
        let sortable_places = this.sortable_object(places);
        sortable_places = this.max_object(sortable_places);
        const dataPlaces = {
            labels: Object.keys(sortable_places),
            datasets: [
                {
                    backgroundColor: [
                        "#ea6847",
                        "#5f6bb3",
                        "#fef9e4",
                        "#112945",
                        "#fecb53",
                        "#4c2b36",
                        "#856a5d",
                        "#d58936",
                        "#177e89",
                        "#0b0033"
                    ],
                    data: Object.values(sortable_places)
                }
            ]
        };

        let aggressors = this.state.aggressors;
        let sortable_aggressors = this.sortable_object(aggressors);
        sortable_aggressors = this.max_object(sortable_aggressors);
        const dataAggressors = {
            labels: Object.keys(sortable_aggressors),
            datasets: [
                {
                    backgroundColor: [
                        "#ea6847",
                        "#5f6bb3",
                        "#fef9e4",
                        "#112945",
                        "#fecb53",
                        "#4c2b36",
                        "#856a5d",
                        "#d58936",
                        "#177e89",
                        "#0b0033"
                    ],
                    data: Object.values(sortable_aggressors)
                }
            ]
        };

        const styles = {
            card: {
                width: "100%"
            },
            tileCard: {
                paddingTop: 20,
                paddingBottom: 20,
                textAlign: 'center',
                fontSize: '22px',
                color: '#5e6ab1'
            },
            subTileCard: {
                padding: 20,
                textAlign: 'center',
                fontSize: '18px',
                color: '#5e6ab1'
            }
        };

        return (
            <Grid
                container
                spacing={0}
                direction="column"
                alignItems="center"
                justify="center"
                style={{ minHeight: '100vh' }}
            >
                <Card style={styles.card}>
                    <Typography
                        style={styles.tileCard}
                    >
                        {translate("visualizations")}
                    </Typography>
                </Card>
                <hr />
                <Card style={styles.card}>
                    <Typography
                        style={styles.subTileCard}
                    >
                        {translate("total_report")}: {this.state.total}
                    </Typography>
                </Card>
                <hr />
                <Card style={styles.card}>
                    <div className="myChart" style={{ height: 300, padding: 20 }}>
                        <div className="titleChart">{translate("by_states_reports")}</div>
                        <Bar
                            data={dataStates}
                            width={300}
                            height={500}
                            options={{
                                maintainAspectRatio: false,
                                scales: {
                                    xAxes: [{
                                        ticks: {
                                            fontColor: '#233771',
                                            fontFamily: 'Roboto'
                                        }
                                    }],
                                    yAxes: [
                                        {
                                            ticks: {
                                                min: 0,
                                                beginAtZero: true,
                                                precision: 0,
                                                fontColor: '#233771',
                                                fontFamily: 'Roboto'
                                            }
                                        }
                                    ]
                                },
                                legend: {
    	                            display: false,
                                    labels: {
                                        fontColor: '#233771',
                                        fontFamily: 'Roboto'
                                    }
                                }
                            }}
                        />
                    </div>
                </Card>
                <hr />
                <Card style={styles.card}>
                    <div className="myChart">
                        <div className="titleChart">{translate("by_sex_reports")}</div>
                        <Pie
                            data={dataGender}
                            options={{
                                legend: {
                                    position: "bottom",
                                    labels: {
                                        fontColor: '#233771',
                                        fontFamily: 'Roboto'
                                    }
                                },
                                tooltips: {
                                    callbacks: {
                                        label: function(tooltipItem, data) {
                                            var dataset = data.datasets[tooltipItem.datasetIndex];
                                            var meta = dataset._meta[Object.keys(dataset._meta)[0]];
                                            var total = meta.total;
                                            var currentValue = dataset.data[tooltipItem.index];
                                            var percentage = parseFloat((currentValue/total*100).toFixed(1));
                                            return currentValue + ' (' + percentage + '%)';
                                        },
                                        title: function(tooltipItem, data) {
                                            return data.labels[tooltipItem[0].index];
                                        }
                                    }
                                }
                            }}
                        />
                    </div>
                </Card>
                <hr />
                <Card style={styles.card}>
                    <div className="myChart" style={{ height: 300, padding: 20 }}>
                        <div className="titleChart">{translate("by_places_reports")}</div>
                        <Bar
                            data={dataPlaces}
                            width={300}
                            height={500}
                            options={{
                                maintainAspectRatio: false,
                                scales: {
                                    xAxes: [{
                                        ticks: {
                                            fontColor: '#233771',
                                            fontFamily: 'Roboto'
                                        }
                                    }],
                                    yAxes: [
                                        {
                                            ticks: {
                                                min: 0,
                                                beginAtZero: true,
                                                precision: 0,
                                                fontColor: '#233771',
                                                fontFamily: 'Roboto'
                                            }
                                        }
                                    ]
                                },
                                legend: {
    	                            display: false,
                                    labels: {
                                        fontColor: '#233771',
                                        fontFamily: 'Roboto'
                                    }
                                }
                            }}
                        />
                    </div>
                </Card>
                <hr />
                <Card style={styles.card}>
                    <div className="myChart" style={{ height: 300, padding: 20 }}>
                        <div className="titleChart">{translate("by_aggressors_reports")}</div>
                        <Bar
                            data={dataAggressors}
                            width={300}
                            height={500}
                            options={{
                                maintainAspectRatio: false,
                                scales: {
                                    xAxes: [{
                                        ticks: {
                                            fontColor: '#233771',
                                            fontFamily: 'Roboto'
                                        }
                                    }],
                                    yAxes: [
                                        {
                                            ticks: {
                                                min: 0,
                                                beginAtZero: true,
                                                precision: 0,
                                                fontColor: '#233771',
                                                fontFamily: 'Roboto'
                                            }
                                        }
                                    ]
                                },
                                legend: {
    	                            display: false,
                                    labels: {
                                        fontColor: '#233771',
                                        fontFamily: 'Roboto'
                                    }
                                }
                            }}
                        />
                    </div>
                </Card>
            </Grid>
        );
    }
}

export default translate(Visualizations);