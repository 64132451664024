import React from "react";
import {
    List,
    Datagrid,
    DateField,
    TextField,
    ReferenceField,
    FunctionField,
    Filter,
    ReferenceInput,
    AutocompleteInput,
    translate
} from "react-admin";
import * as LIMITS from "../constants/limits";

const optionRenderer = account => `${account.firstname} ${account.lastname}`;

const LogFilter = ({translate, ...props}) => (
    <Filter {...props}>
        {/* <TextInput label="Buscar reporte" source="model" alwaysOn /> */}
        <ReferenceInput
            source="userId"
            reference="users"
            alwaysOn
            sort={{field: 'firstname', order: 'ASC'}}
            perPage={LIMITS.ACCOUNTS}
        >
            <AutocompleteInput
                optionText={optionRenderer}
                suggestionComponent={({
                    suggestion,
                    query,
                    isHighlighted,
                    props
                }) => (
                    <div>
                        <div style={{}} {...props}>
                            {(suggestion.firstname || '')
                                .toLowerCase()
                                .includes(query.toLowerCase()) ||
                            (suggestion.lastname || '')
                                .toLowerCase()
                                .includes(query.toLowerCase())
                                    ? `\u00A0${suggestion.firstname || ''} ${ suggestion.lastname || '' }\u00A0`
                                    : ''}
                        </div>
                    </div>
                )}
            />
        </ReferenceInput>
    </Filter>
);

const LogList = props => (
    <List 
        {...props}
        bulkActionButtons={false}
        filters={<LogFilter />}
        exporter={false}
    >
        <Datagrid>
            <TextField source="id" />
            <DateField source="datetime" showTime={true} />
            <ReferenceField source="userId" reference="users">
                <FunctionField
                    render={record => `${record.firstname || ''} ${record.lastname || ''}`}
                />
            </ReferenceField>
            <TextField source="type" />
            <TextField source="model" sortable={false} />
            <TextField source="modelId" sortable={false} />
            <TextField source="changes" />
        </Datagrid>
    </List>
);

export default translate(LogList);
