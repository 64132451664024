import React from "react";
import { SimpleForm, Create } from "react-admin";
import TextInputName from './fields/TextInputName';

const LocationCreate = props => (
    <Create {...props}>
        <SimpleForm redirect="list">
            <TextInputName source="name"/>
        </SimpleForm>
    </Create>
);

export default LocationCreate;
