import React from 'react';
import IMG_TESTIMONIO_PLAY from './../../../../assets/image/testimonio_play.png';
import IMG_TESTIMONIO_PAUSE from './../../../../assets/image/testimonio_pause.png';
import { 
	CardImg
} from 'reactstrap';

export default class Player extends React.Component {
    constructor(props) {
        super(props);
        this.state = { play: false };
    };

    componentWillReceiveProps() {
        this.setState({ play: true });
    };

    play = () => {
        if (this.state.play) {
            this.setState({ play: false });
            this.audio.pause();
        } else {
             this.setState({ play: true });
             this.audio.play();
        }
    };

    render() {
        const styles = {
            button: {
                padding: 0,
                border: 'none',
                background: 'none'
            }
        };

        return <div>
                   <audio src={this.props.audio}
                        ref={(audio) => { this.audio = audio }}
                    />
                    <button style={styles.button} onClick={this.play}>
                        {this.state.play ? 
                          <CardImg top height="80%" src={IMG_TESTIMONIO_PAUSE} title="Testimonio" alt="Testimonio" /> :
                          <CardImg top height="80%" src={IMG_TESTIMONIO_PLAY} title="Testimonio" alt="Testimonio" />}
                    </button>
               </div>
    }
}