const token = localStorage.getItem("token");
const role = localStorage.getItem("role");
const userId = parseInt( localStorage.getItem("userId") );
const institutionId = parseInt( localStorage.getItem("institutionId") );
const name = localStorage.getItem("name");
const email = localStorage.getItem("email");

export {
    token,
    role,
    userId,
    institutionId,
    name,
    email
}
