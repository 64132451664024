import React from "react";
import {
    List,
    Datagrid,
    TextField,
    DateField,
    ReferenceField,
    FunctionField,
    ReferenceInput,
    Filter,
    NumberInput,
    SelectInput,
    ShowButton,
    AutocompleteInput,
    translate
} from "react-admin";
import {
    ApproveButton,
    ImagesButton,
    DocsButton,
    RejectButton,
    CommentButton
} from "../common";
import { vCoordinator, vDefender } from "../utils/auth";
import { userId, institutionId } from "../utils/storage";
import * as LIMITS from "../constants/limits";
import EditButton from './EditButton';

const optionRendererAccounts = account =>
    `${account.firstname || ''} ${account.lastname || ''}`;
const optionRendererPlaces = place =>
    `${place.name || ''} ${place.departament || ''} ${place.district || ''}`;

const estados = [
    { name: "Borrador", id: "draft" },
    { name: "Comentarios", id: "comments" },
    { name: "Evaluación", id: "evaluation" },
    { name: "Rechazada", id: "rejected" },
    { name: "Aceptada", id: "accepted" },
    { name: "Resuelta", id: "resolved" }
];

const button = {
    draft: {
        background: "#828282",
        padding: "2px 10px",
        marginRight: "5px"
    },
    evaluation: {
        background: "#fb5a31",
        padding: "2px 10px",
        marginRight: "5px"
    },
    accepted: {
        background: "#1E90FF",
        padding: "2px 10px",
        marginRight: "5px"
    },
    resolved: {
        background: "#008000",
        padding: "2px 10px",
        marginRight: "5px"
    },
    comments: {
        background: "#828282",
        padding: "2px 10px",
        marginRight: "5px"
    },
    rejected: {
        background: "#FF2301",
        padding: "2px 10px",
        marginRight: "5px"
    }
};

var d1 = new Date(
    new Date().getTime() - new Date().getTimezoneOffset() * 60000
);
d1.setDate(d1.getDate() - 1);
var d2 = new Date(
    new Date().getTime() - new Date().getTimezoneOffset() * 60000
);
d2.setDate(d2.getDate() - 2);
const choices = [{ name: "24 hs", _id: "24" }, { name: "48 hs", _id: "48" }];

const ReportFilter = props => (
    <Filter {...props}>
        <NumberInput label="ID" source="id" reference="reports" />

        {vCoordinator() && (
            <SelectInput
                label="Fecha"
                source="lastReport"
                optionText="name"
                optionValue="_id"
                choices={choices}
            />
        )}

        <SelectInput label="Estado" source="status" choices={estados} />

        {vCoordinator() && (
            <ReferenceInput
                label="Usuario"
                source="userId"
                reference="users"
                allowEmpty
                sort={{ field: "firstname", order: "ASC" }}
                perPage={LIMITS.ACCOUNTS}
            >
                <AutocompleteInput
                    optionText={optionRendererAccounts}
                    suggestionComponent={({
                        suggestion,
                        query,
                        isHighlighted,
                        props
                    }) => (
                        <div>
                            <div style={{}} {...props}>
                            {(suggestion.firstname || '')
                                    .toLowerCase()
                                    .includes(query.toLowerCase()) ||
                            (suggestion.lastname || '')
                                    .toLowerCase()
                                    .includes(query.toLowerCase())
                                    ? `\u00A0${suggestion.firstname || ''} ${ suggestion.lastname || '' }\u00A0`
                                    : ''}
                            </div>
                        </div>
                    )}
                />
            </ReferenceInput>
        )}

        {vCoordinator() && (
            <ReferenceInput
                label="Lugar"
                source="tortureAtPlaceId"
                reference="places"
                allowEmpty
                sort={{ field: "name", order: "ASC" }}
                perPage={50}
                filterToQuery={searchText => ({
                    or: [
                        { name: { like: "%" + searchText + "%" } },
                        { departament: { like: "%" + searchText + "%" } },
                        { district: { like: "%" + searchText + "%" } }
                    ]
                })}
            >
                <AutocompleteInput
                    optionText={optionRendererPlaces}
                    suggestionComponent={({ suggestion, query, props }) => (
                        <div>
                            <div style={{}} {...props}>
                                {(suggestion.name || '')
                                    .toLowerCase()
                                    .includes(query.toLowerCase()) ||
                                (suggestion.departament || '')
                                    .toLowerCase()
                                    .includes(query.toLowerCase()) |
                                    (suggestion.district || '')
                                        .toLowerCase()
                                        .includes(query.toLowerCase())
                                    ? `\u00A0${suggestion.name || ''} ${suggestion.departament || ''} ${suggestion.district || ''}\u00A0`
                                    : ""}
                            </div>
                        </div>
                    )}
                />
            </ReferenceInput>
        )}

        {vCoordinator() && (    
            <ReferenceInput
                source="institutionId"
                reference="institutions"
                allowEmpty
                sort={{ field: "name", order: "ASC" }}
                perPage={50}
                filterToQuery={searchText => ({
                    name: { like: "%" + searchText + "%" }
                })}
            >
                <AutocompleteInput
                    optionText={place => place.name}
                    suggestionComponent={({ suggestion, query, props }) => (
                        <div>
                            <div style={{}} {...props}>
                                {suggestion.name
                                    .toLowerCase()}
                            </div>
                        </div>
                    )}
                />
            </ReferenceInput>
        )}
    </Filter>
);

const CStatus = ({ record = {} }) => {
    const classes = {
        urgent: {
            display: "flex",
            flexWrap: "wrap",
            backgroundColor: "#E6333A",
            padding: "5px",
            color: "white",
            borderRadius: "3px",
            fontSize: "14px",
            margin: "3px 0px"
        },
        alertCoordinator: {
            color: '#ffdc75',
            fontWeight: 'bold'
        },
        alertDefender: {
            color: '#c99600',
            fontWeight: 'bold'
        }
    };
    let mode = "";
    let state = estados.find(estado => {
        return estado.id === record.status;
    });
    if (state && state.name) {
        mode = state.name;
    } else {
        mode = "";
    }
    if (vCoordinator() && record.status === "evaluation") {
        return <span style={classes.urgent}>
            Necesita evaluación
            {record.cambioAutomatico ? (
                <span style={classes.alertCoordinator}> Cambio automático</span>
            ) : ''}
        </span>;
    }
    return <span style={{ color: button[state.id].background }}>
        {mode}
        {record.cambioAutomatico ? (
            <span style={classes.alertDefender}> Cambio automático</span>
        ) : ''}
    </span>;
};
CStatus.defaultProps = { label: "Estado" };

const ReportList = props => {
    const CustomEdit = props => {
        const record = props.record;
        const status = record.status || '';
        return (
            ["comments", "draft"].includes(status) && <EditButton {...props} />
        );
    };

    const CustomEditImage = props => {
        const record = props.record;
        const status = record.status || '';
        return (
            ["comments", "draft"].includes(status) && (
                <ImagesButton {...props} />
            )
        );
    };

    const CustomEditDoc = props => {
        const record = props.record;
        const status = record.status || '';
        return (
            ["comments", "draft"].includes(status) && <DocsButton {...props} />
        );
    };

    const EditControls = props => {
        const record = props.record;
        if (!record) { return ''; }
        return <>
            {(vDefender() && (record.status === 'draft' || record.status === 'comments')) ? (<>
                <div style={{display: 'flex'}}>
                    <CustomEdit {...props} />
                    <CustomEditImage {...props} />
                    <CustomEditDoc {...props} />
                </div>
            </>) : ''}
            {/* user is report owner */}
            {vCoordinator() && (record.userId === userId && (record.status === 'draft' || record.status === 'comments')) ? (
                <div style={{display: 'flex'}}>
                    <EditButton {...props} />
                    <ImagesButton {...props} />
                    <DocsButton {...props} />
                </div>
            ) : ''}
        </>;
    }

    return (
        <List
            {...props}
            bulkActionButtons={false}
            filters={vCoordinator() || vDefender() ? <ReportFilter /> : null}
            filter={vDefender() ? { userId: userId } : {}}
            filterDefaultValues={vCoordinator() ? { status: "evaluation" } : {}}
            sort={{ field: "datetimeModified", order: "DESC" }}
            exporter={false}
        >
            <Datagrid>
                <FunctionField
                    source="id"
                    render={record => `DEF${record.id}`}
                />

                <ReferenceField source="institutionId" reference="institutions" linkType={(vCoordinator() || vDefender()) ? 'show' : false}>
                    <TextField source="name" />
                </ReferenceField>

                {vCoordinator() && (
                    <ReferenceField source="userId" reference="users" linkType="show">
                        <FunctionField render={record => 
                                `${record.firstname || ''} ${record.lastname || ''}`
                            }
                        />
                    </ReferenceField>
                )}

                <DateField source="standardDatetime" showTime={true} />
                
                <DateField source="datetimeModified" showTime={true} label={props.translate("datetimeModifiedGrid")} />

                <CStatus sortBy="status" />

                <TextField source="comment" />

                {vCoordinator() && (
                    <FunctionField render={report => (<>
                        {report.status !== 'draft' && report.status !== 'comments' ? (
                            [report.institutionId == institutionId ? (<div key="state-1" style={{ marginBottom: '5px', display: 'flex' }}>
                                {report.status !== 'accepted' ? (<ApproveButton record={report} style={button.accepted} des="ACEPTAR" color="primary" />) : ''}
                                {report.status !== 'comments' ? (<CommentButton record={report} style={button.comments} des="COMENTAR" color="primary" />) : ''}
                                {report.status !== 'rejected' ? (<RejectButton record={report} style={button.rejected} des="RECHAZAR" color="primary" />) : ''}
                                {report.status !== 'resolved' ? (<ApproveButton record={report} style={button.resolved} des="RESOLVER" color="primary" />) : ''}
                            </div>) : '',
                            report.institutionId != institutionId ? (<div key="state-2" style={{ marginBottom: '5px' }}>
                                {report.status !== 'comments' ? (<CommentButton record={report} style={button.comments} des="COMENTAR" color="primary" />) : ''}
                            </div>) : ''
                        ]) : ''}
                    </>)} />
                )}

                <FunctionField
                    render={record => ( 
                        (record.status === "draft" || record.status === "comments") 
                        && ( 
                            vDefender() 
                            || (vCoordinator() && (record.userId === userId))
                        )
                        && (<ApproveButton
                            style={button.evaluation}
                            des="ENVIAR"
                            color="primary"
                            record={record}
                        />)
                    )}
                />

                {/* {vDefender() && (
                    <ApproveButton
                        style={button.evaluation}
                        des="ENVIAR"
                        color="primary"
                    />
                )} */}

                <ShowButton />
                <EditControls />
            </Datagrid>
        </List>
    );
};

export default translate(ReportList);
