import React from 'react';
import { TextInput, required } from 'react-admin';

const validateTrim = (value) => {
    var name = value;
    
    if (name && name.trim() === "") {
        return 'No puede contener solamente espacio como carácter.';
    }
    return; 
};

const validateName = [required(), validateTrim];

const TextInputName = props => (  
    <TextInput source={props.source} validate={validateName} autoFocus {...props} />
);

export default TextInputName;