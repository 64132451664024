import React from "react";
import {
    TextField,
    ShowController,
    SimpleShowLayout,
    ShowView
} from "react-admin";

export const PlacegroupShow = props => (
    <ShowController {...props}>
        {controllerProps =>
            <ShowView {...props} {...controllerProps}>
                <SimpleShowLayout>
                    <TextField source="name" />
                </SimpleShowLayout>
            </ShowView>
        }
    </ShowController>
);

export default PlacegroupShow;