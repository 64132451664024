import React, { useState, useEffect } from "react";
import { translate } from "react-admin";
import { Button, Modal, TextField, FormHelperText } from '@material-ui/core';
import { withStyles } from '@material-ui/core';
import CircularProgress from "@material-ui/core/CircularProgress";
import QRCode from "react-qr-code";


function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
    overflowY: 'scroll',
    maxHeight: '100vh'
  };
}

const styles = theme => ({
  paper: {
    position: 'absolute',
    width: theme.spacing.unit * 60,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 4,
    outline: 'none',
  },
  h2: {
    fontSize: '25px',
    margin: '10px 0 15px 0',
  },
  h3: {
    fontSize: '19px',
    margin: '25px 0 15px 0',
  },
  text: {
    fontSize: '13px'
  },
});

const OtpVerifySecretOnFirstLoginModal = ({ otpData, otpToken, setOtpToken, onOtpVerify, translate, classes, ...props }) => {
  let [ open, setOpen ] = useState(false);
  let [ isLoadingVerify, setIsLoadingVerify ] = useState(false);

  const getValidateError = () => {
    return (otpToken && localStorage.getItem("otpTokenInvalidError")) ? localStorage.getItem("otpTokenInvalidError") : null;
  }

  const openConfigModal = () => {
    setOpen(true);
  }

  const closeModal = () => {
    setOpen(false);
  }

  const verify  = () => {
    setIsLoadingVerify(true);
    onOtpVerify(() => {
      setIsLoadingVerify(false);
    });
  }
  

  return <div className="">
    <Button variant="outlined" color="secondary" onClick={openConfigModal} disabled={ isLoadingVerify }>
      Configurar
    </Button>
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div style={getModalStyle()} className={classes.paper}>
        <h2 className={classes.h2}>
          {translate('otp.title')}
        </h2>
        <h3 className={classes.h3}>
          {translate('otp.generate.subtitle')}
        </h3>
        <div className={classes.text}>
          {translate('otp.generate.step1')}<br />
          {translate('otp.generate.step2')}<br />
          {translate('otp.generate.step3')}<br />
        </div>
        <h3 className={classes.h3}>
          {translate('otp.generate.scanTitle')}
        </h3>
        {otpData && otpData.authUrl ? (<>
          <div style={{ height: "auto", margin: "0 auto", maxWidth: 180, width: "100%" }}>
            <QRCode
              size={256}
              style={{ height: "auto", maxWidth: "100%", width: "100%" }}
              value={otpData.authUrl}
              viewBox={`0 0 256 256`}
            />
          </div>
          <h3 className={classes.h3}>
            {translate('otp.generate.typeTitle')}
          </h3>
          <div className={classes.text}>
            {translate('otp.generate.secretKey')}: <b>{otpData.base32}</b>
          </div>
          <h3 className={classes.h3}>
            {translate('otp.generate.verifyCodeTitle')}
          </h3>
          <div className={classes.text} style={{ marginBottom: '10px' }}>
            {translate('otp.generate.verifyCodeDesc')}<br />
          </div>
          <TextField 
            autoFocus
            id="verifyCode"
            label={translate('otp.generate.otpToken')} 
            variant="outlined"
            onChange={(e) => {
              setOtpToken(e.target.value);
            }}
            error={!!getValidateError()}
          />
          {getValidateError() ? (
            <FormHelperText error>{translate(getValidateError())}</FormHelperText>
          ) : ''}
        </>) : ''}
        <div style={{ marginTop: '20px' }} >
          <Button variant="outlined" color="secondary" style={{ marginRight: '10px' }} onClick={closeModal}>
            Cerrar
          </Button>
          <Button variant="contained" color="secondary" onClick={verify} disabled={ isLoadingVerify || !otpToken }>
            {isLoadingVerify && (
              <CircularProgress size={25} thickness={2} />
            )}
            Verificar y Activar
          </Button>
        </div>
      </div>
    </Modal>
  </div>
};

// We need an intermediary variable for handling the recursive nesting.
const OtpVerifySecretOnFirstLoginModalWithStyles = withStyles(styles)(OtpVerifySecretOnFirstLoginModal);

export default translate(OtpVerifySecretOnFirstLoginModalWithStyles);
