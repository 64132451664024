import React, { useState, useEffect, useRef } from "react";
import html2pdf from "html2pdf.js";
import printStyles from '../css/Print.css';

export const PrintContent = ({ reportId, children }) => {
  const componentRef = useRef(null);
  const [isPrinting, setIsPrinting] = useState(false);

  const printPdf = async () => {
    if (isPrinting) {
      return; // If printing is already in progress, don't initiate another print
    }

    setIsPrinting(true); // Set the state to "printing"

    setTimeout(async () => {
      const content = componentRef.current;
      try {
        const filename = `Reporte_DEF${reportId}.pdf`;

        // Clone the content
        const clonedContent = content.cloneNode(true);

        // Remove elements with the class ".no-print"
        const noPrintElements = clonedContent.querySelectorAll('.no-print');
        noPrintElements.forEach(element => {
          element.remove();
        });

        // Convert images to base64 strings
        const imageElements = clonedContent.querySelectorAll('img');
        const imagePromises = Array.from(imageElements).map(async (img) => {
          const imageUrl = img.src;
          const base64Image = await getBase64Image(imageUrl);
          img.src = base64Image;
        });
        await Promise.all(imagePromises);

        const worker = html2pdf().set({
          margin:       0.3,
          filename:     filename,
          image:        { type: 'jpeg', quality: 0.8 },
          html2canvas:  { scale: 2 },
          jsPDF:        { unit: 'in', format: 'letter', orientation: 'portrait' },
          style:        printStyles,
          imageStyles:  { breakInside: 'avoid' }, // Set break-inside: avoid in the image styles
          pagebreak:    { mode: ['avoid-all', 'css'] }
        });

        const pdf = await worker.from(clonedContent, 'element').toPdf().output('blob');

        // Create the URL with the filename
        const url = URL.createObjectURL( new Blob([pdf], { type: 'application/pdf', name: filename }) );

        // Create a download link in the DOM
        const downloadLink = document.createElement('a');
        downloadLink.href = url;
        downloadLink.download = filename;
        downloadLink.click(); // Simulate a click on the download link to initiate the download

        const newWindow = window.open(url, '_blank');
        if (newWindow) {
          newWindow.addEventListener('load', () => {
            newWindow.document.title = filename; // Set window title to the filename
            newWindow.print();
            URL.revokeObjectURL(url);
            setIsPrinting(false); // Set the state to "not printing" once printing is finished
          });
        } else {
          throw new Error('Failed to open new window');
        }
      } catch (err) {
        console.log(err);
        setIsPrinting(false); // Set the state to "not printing" in case of an error
      }


    }, 100); // Delay of 100 milliseconds (adjust the value as needed)
  };

  const getBase64Image = (url) => {
    return new Promise((resolve, reject) => {
      const image = new Image();
      image.crossOrigin = "Anonymous";

      image.onload = () => {
        const canvas = document.createElement("canvas");
        canvas.width = image.width;
        canvas.height = image.height;

        const context = canvas.getContext("2d");
        context.drawImage(image, 0, 0);

        const base64String = canvas.toDataURL("image/jpeg");
        resolve(base64String);
      };

      image.onerror = () => {
        reject(new Error("Failed to convert image to base64"));
      };

      image.src = url;
    });
  };

  return (
    <div>
      {children && children({ componentRef, triggerPrint: printPdf, isPrinting })}
    </div>
  );
};
