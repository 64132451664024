// in src/MyAppBar.js
import React from "react";
import { AppBar } from "react-admin";
import { withStyles } from "@material-ui/core/styles";
import { changeLocale as changeLocaleAction, translate } from "react-admin";
import Button from "@material-ui/core/Button";
import { connect } from "react-redux";
import * as COLORS from "../constants/colors";

import MyUserMenu from './MyUserMenu';


const styles = {
    AppBar: {
        backgroundColor: COLORS.BG_DARK
    },
    title: {
        flex: 1,
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        overflow: "hidden"
    },
    spacer: {
        flex: 1
    },
    button: {
        marginRight: "5px"
    }
};


var lang = "";

const passVar = variable => {
    lang = variable;
}


const MyAppBar = withStyles(styles)(({ classes, translate, ...props  }) => {
    const role = localStorage.getItem("role");
    const switchToSpanish = () => props.changeLocale("es");
    const switchToEnglish = () => props.changeLocale("en");
    const switchToGuarani = () => props.changeLocale("gn");
    const switchToPortuguez = () => props.changeLocale("pt");

    const { changeLocale, ...p } = props;

    return (
        <AppBar {...p} style={styles.AppBar} userMenu={<MyUserMenu/>}>
            {role ? translate(role) : "Public"}
            <span className={classes.spacer} />
            {lang && lang.includes("english")   &&
            <Button variant="outlined" onClick={() => switchToEnglish()}>
                <span role="img" aria-label="United States">
                    &#x1F1FA;&#x1F1F8;
                </span>
            </Button>
            }
            {lang && lang.includes("spanish")   &&
            <Button variant="outlined" onClick={() => switchToSpanish()}>
                <span role="img" aria-label="Spain">
                    &#x1F1EA;&#x1F1F8;
                </span>
            </Button>
            }
            {lang && lang.includes("guarani")   &&
            <Button variant="outlined" onClick={() => switchToGuarani()}>
                <span role="img" aria-label="Paraguay">
                    &#x1F1F5;&#x1F1FE;
                </span>
            </Button>
            }
            {lang && lang.includes("portuguese")   &&
            <Button variant="outlined" onClick={() => switchToPortuguez()}>
                <span role="img" aria-label="Brazil">
                    &#x1F1E7;&#x1F1F7;
                </span>
            </Button>
            }
        </AppBar>
    );
});


const returnMyAppBar = connect(
    undefined,
    { changeLocale: changeLocaleAction }
)(translate(MyAppBar));

export {returnMyAppBar,passVar}; 

