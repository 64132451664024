import React from "react";
import { Route } from "react-router-dom";
import { Admin, Resource } from "react-admin";
import PostIcon from "@material-ui/icons/Book";
import PowerSettingsNew from "@material-ui/icons/PowerSettingsNew";
import IdleTimer from "react-idle-timer";
import spanishMessages from "ra-language-spanish";
import englishMessages from "ra-language-english";
import dataProvider from "../providers/dataProvider";
import authProvider from "../providers/authProvider";
import {
    ReportCreate,
    ReportEdit,
    ReportList,
    ReportShow,
    Stats,
    Visualizations,
    Maps,
    ResetEmail,
    ResetForm,
    MyLoginPage,
    profile,
    Images,
    Docs,
    SessionList,
    ChangePassword
} from "../common";
import MyLayout from "../layout/MyLayout";
import { myEs, myEn, myGn, myPt } from "../constants/Translations";
import { IDLE_TIME } from "../constants/env";
import checkToken from "../utils/checkToken";

import { userId, token } from "../utils/storage";
import logout from "../utils/session";

const messages = {
    es: { ...spanishMessages, ...myEs },
    en: { ...englishMessages, ...myEn },
    gn: { ...spanishMessages, ...myGn },
    pt: { ...spanishMessages, ...myPt }
};

const i18nProvider = locale => messages[locale];

class Defender extends React.Component {
    constructor(props) {
        super(props);
        this.idleTimer = null;
    }

    idleLogout = () => {
        logout(userId, token);
    };

    render() {
        checkToken();

        return (
            <div>
                <IdleTimer
                    ref={ref => {
                        this.idleTimer = ref;
                    }}
                    element={document}
                    onIdle={() => this.idleLogout()}
                    debounce={250}
                    timeout={IDLE_TIME}
                />

                <Admin
                    loginPage={MyLoginPage}
                    dataProvider={dataProvider}
                    authProvider={authProvider}
                    locale="es"
                    i18nProvider={i18nProvider}
                    customRoutes={[
                        <Route
                            path="/visualizations"
                            component={Visualizations}
                        />,
                        <Route path="/stats" component={Stats} />,
                        <Route path="/maps" component={Maps} />,
                        <Route path="/images/:id" component={Images} />,
                        <Route path="/docs/:id" component={Docs} />,
                        <Route
                            key={userId}
                            path="/myProfile"
                            component={profile.edit}
                        />,
                        <Route path="/resetForm" component={ResetForm} noLayout/>,
                        <Route path="/resetEmail/:resetPassKey" component={ResetEmail}  />,
                        <Route path="/changePassword" component={ChangePassword} />
                    ]}
                    appLayout={MyLayout(this.props.lang)}
                >
                    <Resource
                        name="reports"
                        list={ReportList}
                        show={ReportShow}
                        create={ReportCreate}
                        edit={ReportEdit}
                        icon={PostIcon}
                    />
                    <Resource name="users"/>
                    <Resource name="institutions" />
                    <Resource name="places" />
                    <Resource name="placeGroups" />
                    <Resource name="aggressors" />
                    {/* <Resource name="locations" /> */}
                    <Resource name="settings" />

                    <Resource
                        name="sessions"
                        list={SessionList}
                        icon={PowerSettingsNew}
                    />
                </Admin>
            </div>
        );
    }
}

export default Defender;
