import React, { Component } from "react";
import Project from "./Project";
import Resources from "./Resources";
import Visualizaciones from "./Visualization";
import Mapa from "./Map";

export default class Content extends Component {
    render() {
        const styles = {
            body: {
                backgroundColor: '#E3E4E3'
            },
            resources_public: {
                backgroundColor: '#E6333A',
                paddingBottom: '50px'
            }
        };

        return (
            <div style={styles.body}>
                <div>
                    <Project />
                </div>
                <div style={styles.resources_public} id="resources_public">
                    <Resources />
                </div>
				<div id="maps_public">
                    <Mapa />
                </div>
                <div id="visualizations_public">
                    <Visualizaciones />
                </div>
            </div>
        );
    }
}
