import React from "react";
import { SimpleForm, NumberInput, Create, required, maxLength } from "react-admin";
import TextInputName from './fields/TextInputName';

const validateName = (value) => {
    var name = value;  
    if (name && name.trim() === "") {
        return 'No puede contener solamente espacio como carácter.';
    }
    if (name && parseInt(name) == name) {
        return 'No puede contener solamente números.';
    }
    return; 
};

const PlacegroupCreate = props => (
    <Create {...props}>
        <SimpleForm redirect="list">
            {/* <NumberInput source="id"/> */}
            <TextInputName validate={[required(), validateName, maxLength(50)]} source="name"/>
        </SimpleForm>
    </Create>
);

export default PlacegroupCreate;
