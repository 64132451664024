import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Button from "@material-ui/core/Button";
import { showNotification } from "react-admin";
import { push } from "react-router-redux";
import { Check } from "@material-ui/icons";
import request from "../utils/request";


const validate = async (reportId, newState, push, showNotification, des) => {
    let notification =  "notification_report_accepted" 
    if (des === 'RESOLVER') {
        notification = "notification_report_resolved"
    } else if (des === 'ENVIAR') {
        notification = "notification_report_evaluation"
    }
    request({
        method: "put",
        url: `/reports/${reportId}/states`,
        data: newState
    })
        .then(response => {
            showNotification(notification);
            // to refresh
            push("/accounts");
            push("/reports");
        })
        .catch(e => {
            showNotification("ERROR VALIDACIÓN", "warning");
        });
};

class ApproveButton extends Component {
    handleClick = () => {
        const { push, record, showNotification, des } = this.props;
        const reportId = record.id;

        let status = "";

        switch (des) {
            case "ENVIAR":
                status = "evaluation";
                break;
            case "ACEPTAR":
                status = "accepted";
                break;
            case "COMENTAR":
                status = "comments";
                break;
            case "RECHAZAR":
                status = "rejected";
                break;
            case "RESOLVER":
                status = "resolved";
                break;
        }

        const newState = {
            reportId: reportId,
            type: status
        };

        validate(reportId, newState, push, showNotification, des);
    };

    render() {
        const { des } = this.props;
        return (
            <div style={{display: 'inline-block'}}>
                <Button
                    onClick={this.handleClick}
                    size="small"
                    style= {this.props.style}
                    variant="contained"
                    color={this.props.color}
                >
                    <Check size="small" />
                    {des}
                </Button>
            </div>
        );
    }
}

ApproveButton.propTypes = {
    push: PropTypes.func,
    record: PropTypes.object,
    showNotification: PropTypes.func
};

export default connect(
    null,
    {
        showNotification,
        push
    }
)(ApproveButton);
