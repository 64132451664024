import React from 'react';
import { ReferenceInput, SelectInput, TextInput, NumberInput, SimpleForm, Create, required, maxLength } from 'react-admin';
import TextInputName from './fields/TextInputName';
import SelectInputDepartament from './fields/SelectInputDepartament';
import TextInputGeometry from './fields/TextInputGeometry';

const validateName = (value) => {
    var name = value;  
    if (name && name.trim() === "") {
        return 'No puede contener solamente espacio como carácter.';
    }
    if (name && parseInt(name) == name) {
        return 'No puede contener solamente números.';
    }
    return; 
};

const PlaceCreate = props => (
    <Create {...props}>
        <SimpleForm redirect="list">
            {/* <NumberInput source="id"/> */}
            {/* Placegroup */}
            <ReferenceInput
                source="placeGroupId"
                reference="placeGroups"
                validate={required()}
                sort={{ field: "name", order: "ASC" }}
                perPage={350}
            >
                <SelectInput optionText="name" />
            </ReferenceInput>
            {/* <TextInput source="departament" /> */}
            <SelectInputDepartament source="departament_number" />
            <TextInput source="district" validate={maxLength(50)} />
            <TextInputName validate={[required(), validateName, maxLength(50)]} source="name"/>
            <TextInputGeometry source="lng" />
            <TextInputGeometry source="lat" />
        </SimpleForm>
    </Create>
);

export default PlaceCreate;
