import React from "react";
import {
    ReferenceField,
    TextField,
    BooleanField,
    ShowController,
    SimpleShowLayout,
    ShowView
} from "react-admin";
import { Chip, InputLabel } from '@material-ui/core';

export const AccountShow = props => (
    <ShowController {...props}>
        {controllerProps => 
            <ShowView {...props} {...controllerProps}>
                <SimpleShowLayout>
                    <ReferenceField source="institutionId" reference="institutions" linkType="show">
                        <TextField source="name" />
                    </ReferenceField>
                    {(props.id == 1) ? ( // is superadmin
                        <InputLabel size="small" style={{fontSize: '12px'}}>
                            Está activa <br />
                            <Chip label="usuario superadmin" style={{marginTop: '5px'}}></Chip>
                        </InputLabel >
                    ) : (
                        <BooleanField source="isActive" />
                    )}
                    <TextField source="firstname" />
                    <TextField source="lastname" />
                    <TextField source="position" />
                    <TextField source="employer" />
                    <TextField source="number" />
                    <TextField source="role" />
                    <TextField source="email" />
                </SimpleShowLayout>
            </ShowView>
        }
    </ShowController>
);

export default AccountShow;
