import React, { Component } from "react";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { Grid, Card, CardHeader, CardContent } from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
import slugify from "slugify";

import request from "../utils/request";
import { URL } from "../constants/env";
import { 
    MAX_DOC_FILE_SIZE
 } from '../constants/sizeMax';
import { token } from "../utils/storage";

export const DocGridList = props => {
    const tileData = props.docs;
    const onDelete = props.onDelete;
    const classes = {
        root: {
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "space-around",
            overflow: "hidden"
            // backgroundColor: theme.palette.background.paper
        },
        titleBar: {
            background:
                "linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, " +
                "rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)"
        },
        icon: {
            color: "red"
        }
    };

    return (
        <Card className="doc-list no-break">
            <CardHeader title={`Documentos`} />
            <CardContent>
                <div style={classes.root}>
                    <Table style={classes.table}>
                        <TableHead>
                            <TableRow>
                                <TableCell>Nombre</TableCell>
                                {onDelete ? (
                                    <TableCell align="right">Acción</TableCell>
                                ) : ''}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {tileData && tileData.length ? tileData.map(tile => (
                                <TableRow key={tile}>
                                    <TableCell component="th" scope="row">
                                        <li><a href={`${URL}/files/${tile}?access_token=${token}`} 
                                               rel="noopener noreferrer" target="_blank">{tile}</a></li>
                                    </TableCell>
                                    {onDelete ? (
                                        <TableCell align="right">
                                            <IconButton
                                                onClick={() =>
                                                    onDelete(tile)
                                                }
                                                aria-label={`star ${tile}`}
                                                style={classes.icon}
                                            >
                                                <DeleteIcon />
                                            </IconButton>
                                        </TableCell>
                                    ) : ''}
                                </TableRow>
                            )) : ''}
                        </TableBody>
                    </Table>
                </div>
            </CardContent>
        </Card>
    );
};

const doc2report = async (name, reportId) => {
    return await request({
        method: "put",
        url: `/reports/${reportId}/files/docs`,
        data: {
            name
        }
    })
        .then(res => {
            //console.log("SAVED DOC TO REPORT ID:", reportId);
        })
        .catch(error => {
            console.log("ERROR SAVING DOC TO REPORT ID:", reportId);
        });
};

class Docs extends Component {
    state = {
        reportId: null,
        docs: []
    };

    checkFileSize = (image) => {
        let file = image;
        let size = MAX_DOC_FILE_SIZE;
        let error;
    
        if (file.size > size) {
          let pic_max_size = Math.round(size / 1024 / 1024);
          error = 'La imagen que quieres subir excede los ' + pic_max_size + ' MB permitidos.\n';
          toast.error(error);
          this.fileInput.value = '';
          return false;
        }
        return true;
      };

    loadDocs = () => {
        const { reportId } = this.state;
        return request({
            method: "get",
            url: `/reports/${reportId}`
        })
            .then(res => {
                if (res && res.files) {
                    let filesTree = JSON.parse(res.files);
                    this.setState({ docs: filesTree.docs });
                }
            })
            .catch(error => {
                console.log("ERROR GETTING IMAGES", error);
            });
    };

    deleteFromDisk = (name) => {
        request({
            method: "delete",
            url: `/files/${name}`
        }).then(res => {
            // console.log('deleted from disk')
        }).catch(error => {
            console.log('error deleting from disk', error)
        })
    }

    onDelete = (name) => {
        request({
            method: "delete",
            url: `/reports/${this.state.reportId}/files/docs/${name}`
        })
            .then(res => {
                //console.log("deleted", id);
            })
            .then(res => {
                this.deleteFromDisk(name)
                this.loadDocs();
            })
            .catch(error => {
                console.log("error deleting from report", error);
            });
    };

    onFileLoad = reportId => e => {
        const doc = e.target.files[0];
        const data = new FormData();
        let name = doc.name.replace(/\.[^/.]+$/, "");
        const rand = Date.now();
        const ext = doc.name.split(".").pop();

        if(this.checkFileSize(doc)){ 
            name = slugify(`${name}-${rand}.${ext}`);
            data.append("file", doc, name);
            //console.log(data);
            toast.info('Procesando documento subido');
            request({
                method: "post",
                url: '/files',
                data: data
            })
                .then(res => {
                    return doc2report(name, reportId);
                })
                .then(res => {
                    setTimeout(() => {
                        this.loadDocs().then(() => {
                            toast.success('El archivo adjunto se subió con éxito!!!');
                            this.fileInput.value = "";
                        });
                    }, 1500);
                })
                .catch(error => {
                    toast.error('La subida del archivo adjunto ha fallado!!!');
                    //console.log("ARCHIVO ERROR", error);
                    this.fileInput.value = "";
                });
        }
    };

    componentDidMount() {
        this.setState({ 
            reportId: this.props.match.params.id 
        }, () =>
            this.loadDocs()
        );
    }

    render() {
        const { reportId, docs } = this.state;
        const onFileLoad = this.onFileLoad;
        const onDelete = this.onDelete;

        return (
            <Grid>
                <Card>
                    <CardHeader
                        title={`Subir documentos para #DEF${reportId}`}
                    />
                    <CardContent>
                        <div>
                            <input
                                id="file_input_file"
                                type="file"
                                name="doc"
                                onChange={onFileLoad(reportId)}
                                ref={ref=> this.fileInput = ref} 
                            />
                        </div>
                        <div>
                            <ToastContainer />
                        </div>
                    </CardContent>
                </Card>
                <br />
                <DocGridList docs={docs} onDelete={onDelete} />
            </Grid>
        );
    }
}

export default Docs;