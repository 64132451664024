import React from "react";
import {
    Card,
    CardActions,
    CardContent,
    Typography,
    Select,
    MenuItem,
    Input
} from "@material-ui/core";
import { translate } from "react-admin";
import { Map, TileLayer, Marker, Popup } from "react-leaflet";
import MarkerClusterGroup from "react-leaflet-markercluster";
import moment from 'moment';
import "leaflet/dist/leaflet.css";
import "react-leaflet-markercluster/dist/styles.min.css";
import L from "leaflet";
import { vCoordinator, vDefender, vObserver } from "../utils/auth";
import Autocomplete from "react-autocomplete";
import _ from 'lodash';
import * as LIMITS from "../constants/limits";
import request from '../utils/request';
import "../css/Maps.css";

delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
    iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
    iconUrl: require("leaflet/dist/images/marker-icon.png"),
    shadowUrl: require("leaflet/dist/images/marker-shadow.png")
});

let d1 = moment().subtract({ month: 1, days: 1 }).format('YYYY-MM-DD');
let d2 = moment().format('YYYY-MM-DD');

class Maps extends React.Component {
    constructor(props, context) {
        super(props, context);

        // Set initial State
        this.state = {
            lat: -24.10655,
            lng: -58.30051,
            zoom: 7,
            markers: [],
            filtered: null,
            accounts: [],
            places: [],
            valuePlace: "",
            placeId: null,
            userId: 0,
            status: [
                "draft",
                "evaluation",
                "accepted",
                "comments",
                "rejected",
                "resolved"
            ],
            value: "", // Current value of the select field
            startDate: d1,
            endDate: d2
        };
    }

    setStartDate = value => {
        this.setState(
            {
                startDate: value
            },
            function() {
                this.all();
            }
        );
    };

    setEndDate = value => {
        this.setState(
            {
                endDate: value
            },
            function() {
                this.all();
            }
        );
    };

    componentDidMount() {
        this.all();
        this.getAccounts();
        this.getPlace();
    }

    handleChange = event => {
        const userId = event.target.value;
        this.setState({ userId });
        if (userId > 0) {
            this.setState({
                filtered: this.state.markers.filter(
                    marker => marker.userId === userId
                )
            });
        } else {
            this.reset();
        }
    };

    handleChangeStatus = event => {
        let value = event.target.value;
        this.filterByStatus(value);
        this.setState({
            status: value
        });
    };

    handleChangeStatusBtn = status => {
        let selectedStatusList;
        // exclude
        if (this.state.status.includes(status)) {
            selectedStatusList = this.state.status.filter(item => item !== status); 
        } 
        // include
        else {
            selectedStatusList = [...this.state.status, status];
        }
        this.filterByStatus(selectedStatusList);
        this.setState({
            status: selectedStatusList
        });
    };

    getAccounts() {
        request({
            method: 'get',
            url: `/users?filter={"order":"firstname ASC","order":"lastname ASC","limit": ${LIMITS.ACCOUNTS}}`
        })
        .then(res => {
            this.setState({ accounts: res.data });
        });
    }

    listAccounts() {
        return this.state.accounts ? this.state.accounts.map((dt, i) => {
            return { id: dt.userId, label: `${dt.firstname} ${dt.lastname}` };
        }) : [];
    }

    getPlace() {
        request({
            method: 'get',
            url: `/places`
        })
            .then(res => {
                let places = _.filter(res.data, place => place.lat && place.lng);
                this.setState({ places });
            });
    }

    listPlace() {
        return this.state.places ? this.state.places.map((dt, i) => {
            return { id: dt.id, label: `${dt.name}` };
        }) : [];
    }

    filterByAccount = userId => {
        if (userId > 0) {
            this.setState({
                filtered: this.state.markers ? this.state.markers.filter(
                    marker => marker.userId === userId
                ) : []
            });
        } else {
            this.reset();
        }
    };

    filterByStatus = status => {
        this.setState({
            filtered: this.state.markers ? this.state.markers.filter(marker =>
                status.includes(marker.status)
            ) : []
        });
    };

    all() {
        let data = {
            startDate: moment(this.state.startDate).startOf('day').toDate(),
            endDate: moment(this.state.endDate).endOf('day').toDate(),
        };
        if (this.state.placeId) {
            data.placeId = this.state.placeId;
        }
        request({
            method: 'post',
            url: `/reports/geojson`,
            data
        })
        .then(res => {
            this.setState({
                markers: res || [],
                filtered: null
            });
        });
    }

    reset() {
        this.setState({ filtered: null });
    }

    render() {
        const { translate } = this.props;
        const ITEM_HEIGHT = 48;
        const ITEM_PADDING_TOP = 8;
        const MenuProps = {
            PaperProps: {
                style: {
                    maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                    width: 250
                }
            }
        };
        const markers = this.state.filtered || this.state.markers;
        const center = [this.state.lat, this.state.lng];
        const draftMarker = L.icon({
            iconUrl: require("../assets/Markers/draft.svg"),
            iconRetinaUrl: require("../assets/Markers/draft.svg"),
            iconSize: [32, 32]
        });
        const evaluationMarker = L.icon({
            iconUrl: require("../assets/Markers/evaluation.svg"),
            iconRetinaUrl: require("../assets/Markers/evaluation.svg"),
            iconSize: [32, 32]
        });
        const acceptedMarker = L.icon({
            iconUrl: require("../assets/Markers/accepted.svg"),
            iconRetinaUrl: require("../assets/Markers/accepted.svg"),
            iconSize: [32, 32]
        });
        const commentsMarker = L.icon({
            iconUrl: require("../assets/Markers/comments.svg"),
            iconRetinaUrl: require("../assets/Markers/comments.svg"),
            iconSize: [32, 32]
        });
        const rejectedMarker = L.icon({
            iconUrl: require("../assets/Markers/rejected.svg"),
            iconRetinaUrl: require("../assets/Markers/rejected.svg"),
            iconSize: [32, 32]
        });
        const resolvedMarker = L.icon({
            iconUrl: require("../assets/Markers/resolved.svg"),
            iconRetinaUrl: require("../assets/Markers/resolved.svg"),
            iconSize: [32, 32]
        });
        const defaultMarker = L.icon({
            iconUrl: require("../assets/Markers/default.svg"),
            iconRetinaUrl: require("../assets/Markers/default.svg"),
            iconSize: [32, 32]
        });

        const MarkersUnfiltered = markers.map((marker, idx) => {
            let status = "";
            let markerIcon = "";

            switch (marker.status) {
                case "draft":
                    status = "Borrador";
                    markerIcon = draftMarker;
                    break;
                case "evaluation":
                    status = "Evaluación";
                    markerIcon = evaluationMarker;
                    break;
                case "accepted":
                    status = "Aceptado";
                    markerIcon = acceptedMarker;
                    break;
                case "comments":
                    status = "Comentarios";
                    markerIcon = commentsMarker;
                    break;
                case "rejected":
                    status = "Rechazada";
                    markerIcon = rejectedMarker;
                    break;
                case "resolved":
                    status = "Resuelta";
                    markerIcon = resolvedMarker;
                    break;
                default:
                    status = "Estado no válido";
                    markerIcon = defaultMarker;
            }
            return (
                <Marker
                    position={[marker.lat, marker.lng]}
                    key={`marker_${idx}`}
                    icon={markerIcon}
                >
                    <Popup>
                        <b>DEF{marker.reportId}</b>
                        <br />
                        {translate("point_place")}: {marker.name} <br />
                        {translate("point_captureDatetime")}: {moment(marker.captureDatetime).format('DD/MM/yyyy HH:mm')} <br />
                        {translate("point_gender")}: {marker.gender}
                        <br />
                        {translate("point_status")}: {status}
                        <br />
                    </Popup>
                </Marker>
            );
        });
        const styles = {
            tileCard: {
                paddingTop: 20,
                textAlign: "center",
                fontSize: "22px",
                fontFamily: "Roboto Slab",
                color: "#5e6ab1"
            },
            cardActionsFilterStatus: {
                paddingTop: 5,
                paddingLeft: 20
            },
            cardActionsFilterUsers: {
                paddingTop: 10,
                paddingLeft: 20
            },
            cardActionsFilterDate: {
                paddingTop: 10,
                paddingLeft: 20
            },
            cardActionsFilterPlace: {
                paddingTop: 10,
                paddingLeft: 0
            },
            cardActionsAlign: {
                display: 'flex',
                justifyContent: 'spaceBetween'
            }
        };

        return (
            <React.Fragment>
                <Card>
                    <CardContent>
                        <Typography style={styles.tileCard}>
                            {translate("title_map")}
                        </Typography>
                    </CardContent>
                    <div style={styles.cardActionsAlign}>
                        <CardActions style={styles.cardActionsFilterStatus}>
                            <Typography type="title">
                                {translate("filter_status")}
                            </Typography>
                            <div style={{ paddingLeft: '20px' }}>
                                {vObserver() ? (<>
                                    <button onClick={() => this.handleChangeStatusBtn("accepted")} className={`input-btn-select ${this.state.status.includes('accepted') ? 'active' : ''}`}>
                                        {translate("accepted")}
                                    </button>
                                    <button onClick={() => this.handleChangeStatusBtn("resolved")} className={`input-btn-select ${this.state.status.includes('resolved') ? 'active' : ''}`}>
                                        {translate("resolved")}
                                    </button>
                                </>) : (<>
                                    <button onClick={() => this.handleChangeStatusBtn("draft")} className={`input-btn-select ${this.state.status.includes('draft') ? 'active' : ''}`}>
                                        {translate("draft")}
                                    </button>
                                    <button onClick={() => this.handleChangeStatusBtn("evaluation")} className={`input-btn-select ${this.state.status.includes('evaluation') ? 'active' : ''}`}>
                                        {translate("evaluation")}
                                    </button>
                                    <button onClick={() => this.handleChangeStatusBtn("accepted")} className={`input-btn-select ${this.state.status.includes('accepted') ? 'active' : ''}`}>
                                        {translate("accepted")}
                                    </button>
                                    <button onClick={() => this.handleChangeStatusBtn("resolved")} className={`input-btn-select ${this.state.status.includes('resolved') ? 'active' : ''}`}>
                                        {translate("resolved")}
                                    </button>
                                    <button onClick={() => this.handleChangeStatusBtn("comments")} className={`input-btn-select ${this.state.status.includes('comments') ? 'active' : ''}`}>
                                        {translate("comments")}
                                    </button>
                                    <button onClick={() => this.handleChangeStatusBtn("rejected")} className={`input-btn-select ${this.state.status.includes('rejected') ? 'active' : ''}`}>
                                        {translate("rejected")}
                                    </button>
                                </>)}
                            </div>
                        </CardActions>

                        <CardActions style={styles.cardActionsFilterDate}>
                            <Typography type="title" gutterBottom>
                                {translate("filter_date")}
                            </Typography>
                            <div className="customDatePickerWidth" style={{ zIndex: "997", paddingLeft: '40px' }}>
                                <label htmlFor="Desde" style={{marginRight: '5px'}}>
                                    <input type="date" id="start"
                                        value={this.state.startDate}
                                        max={this.state.endDate}
                                        onChange={(e) => this.setStartDate(e.target.value)}
                                    />
                                </label>
                                <label htmlFor="Hasta">
                                    <input type="date" id="end"
                                        value={this.state.endDate}
                                        min={this.state.startDate}
                                        onChange={(e) => this.setEndDate(e.target.value)}
                                    />
                                </label>
                            </div>
                        </CardActions>

                        <CardActions style={styles.cardActionsFilterPlace}>
                            <Typography type="title">
                                {translate("filter_places")}
                            </Typography>
                            <div style={{ paddingLeft: '0px' }}>
                                <Autocomplete
                                    menuStyle={{
                                        borderRadius: "3px",
                                        boxShadow: "1 2px 12px rgba(0, 0, 0, 0.1)",
                                        background: "#FEF9E4",
                                        padding: "5px 5px 5px 5px",
                                        fontSize: "15px",
                                        fontFamily: "Roboto",
                                        position: "fixed",
                                        overflow: "auto",
                                        maxHeight: "50%",
                                        zIndex: "998"
                                    }}
                                    items={this.listPlace()}
                                    shouldItemRender={(item, value) =>
                                        item.label
                                            .toLowerCase()
                                            .indexOf(value.toLowerCase()) > -1
                                    }
                                    getItemValue={item => item.label}
                                    renderItem={(item, highlighted) => (
                                        <div
                                            key={item.id}
                                            style={{
                                                backgroundColor: highlighted
                                                    ? "#eee"
                                                    : "transparent"
                                            }}
                                        >
                                            {item.label}
                                        </div>
                                    )}
                                    value={this.state.valuePlace}
                                    onChange={e =>
                                        this.setState(
                                            {
                                                valuePlace: e.target.value,
                                                placeId: e.target.id
                                            },
                                            function() {
                                                if (this.state.valuePlace === "")
                                                    this.all();
                                            }
                                        )
                                    }
                                    onSelect={(value, item) => {
                                        this.setState(
                                            {
                                                valuePlace: value,
                                                placeId: item.id
                                            },
                                            function() {
                                                this.all();
                                            }
                                        );
                                    }}
                                />
                            </div>
                        </CardActions>

                        
                    </div>
                    {(vCoordinator() || vObserver()) && (
                        <CardActions style={styles.cardActionsFilterUsers}>
                            <Typography type="title">
                                {translate("filter_defender")}
                            </Typography>
                            <div style={{ paddingLeft: '6px' }}>
                                <Autocomplete
                                    menuStyle={{
                                        borderRadius: "3px",
                                        boxShadow: "1 2px 12px rgba(0, 0, 0, 0.1)",
                                        background: "#FEF9E4",
                                        padding: "5px 5px 5px 5px",
                                        fontSize: "15px",
                                        fontFamily: "Roboto",
                                        position: "fixed",
                                        overflow: "auto",
                                        maxHeight: "50%",
                                        zIndex: "998"
                                    }}
                                    items={this.listAccounts()}
                                    shouldItemRender={(item, value) =>
                                        item.label
                                            .toLowerCase()
                                            .indexOf(value.toLowerCase()) > -1
                                    }
                                    getItemValue={item => item.label}
                                    renderItem={(item, highlighted) => (
                                        <div
                                            key={item.id}
                                            style={{
                                                backgroundColor: highlighted
                                                    ? "#eee"
                                                    : "transparent"
                                            }}
                                        >
                                            {item.label}
                                        </div>
                                    )}
                                    value={this.state.valueUser}
                                    onChange={e =>
                                        this.setState(
                                            {
                                                valueUser: e.target.value,
                                                userId: e.target.id
                                            },
                                            function() {
                                                if (this.state.valueUser === "") {
                                                    this.all();
                                                } else {
                                                    this.filterByAccount(e.target.id);
                                                }
                                            }
                                        )
                                    }
                                    onSelect={(value, item) => {
                                        this.setState(
                                            {
                                                valueUser: value,
                                                userId: item.id
                                            },
                                            function() {
                                                if (value === "") {
                                                    this.all();
                                                } else {
                                                    this.filterByAccount(item.id);
                                                }
                                            }
                                        );
                                    }}
                                />
                            </div>
                        </CardActions>
                    )}
                    <CardContent>
                        <div>
                            <Map
                                center={center}
                                zoom={this.state.zoom}
                                maxZoom={18}
                                style={{ height: "600px" }}
                                scrollWheelZoom={false}
                            >
                                <TileLayer
                                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                />
                                {MarkersUnfiltered && MarkersUnfiltered.length ? (
                                    <MarkerClusterGroup
                                        showCoverageOnHover={false}
                                        spiderLegPolylineOptions={{
                                            weight: 3,
                                            opacity: 2
                                        }}
                                    >
                                        {MarkersUnfiltered}
                                    </MarkerClusterGroup>
                                ) : ''}
                            </Map>
                        </div>
                    </CardContent>
                </Card>
            </React.Fragment>
        );
    }
}

export default translate(Maps);
