import React from "react";
import { Route } from "react-router-dom";
import { Admin, Resource } from "react-admin";
import spanishMessages from "ra-language-spanish";
import englishMessages from "ra-language-english";
import dataProvider from "../../providers/dataProvider";
import authProvider from "../../providers/authProvider";
import Visualizations from "./Dashboard/components/Visualizations";
import Maps from "./Dashboard/components/Maps";
import {ResetEmail, ResetForm, MyLoginPage} from "../../common";
import { myEs, myEn, myGn, myPt } from "../../constants/Translations";
import checkToken from "../../utils/checkToken";
import Dashboard from "./Dashboard";

const messages = {
    es: { ...spanishMessages, ...myEs },
    en: { ...englishMessages, ...myEn },
    gn: { ...spanishMessages, ...myGn },
    pt: { ...spanishMessages, ...myPt }
};

const i18nProvider = locale => messages[locale];

class Public extends React.Component {
    render() {
        const PublicRoutes = [
            'maps_public',
            'visualizations_public',
            'resources_public',
            'visualizations',
            'maps',
            'resetForm',
            'resetEmail',
        ];
        checkToken((error) => {
            const isPublicRoute = () => {
                return Object.values(PublicRoutes).some(route => window.location.href.includes(route));
            }
            if (window.location.hash === '#/') {
                return;
            }
            if (!isPublicRoute()) {
                // localStorage.clear();
                // window.location.replace('/')
            }
        });
        return (
            <Admin
                loginPage={MyLoginPage}
                dashboard={Dashboard}
                dataProvider={dataProvider}
                authProvider={authProvider}
                locale="es"
                i18nProvider={i18nProvider}
                customRoutes={[
                    <Route exact path="/" component={Dashboard} noLayout />,
                    <Route exact path="/maps_public" component={Dashboard} noLayout />,
                    <Route exact path="/visualizations_public" component={Dashboard} noLayout />,
                    <Route exact path="/resources_public" component={Dashboard} noLayout />,
                    <Route path="/visualizations" component={Visualizations} noLayout />,
                    <Route path="/maps" component={Maps} noLayout />,
                    <Route exact path="/resetForm" component={ResetForm}  noLayout/>,
                    <Route
                        path="/resetEmail/:resetPassKey"
                        component={ResetEmail}
                        noLayout
                    />

                ]}
            >
                <Resource name="Configs" />
                <Resource name="Reports" />
                <Resource name="Accounts" />
                <Resource name="Locations" />
                <Resource name="Places" />
                <Resource name="Authors" />
                <Resource name="Aggressors" />
                <Resource name="Pics" />
                <Resource name="Docs" />
                <Resource name="States" />
            </Admin>
        );
    }
}

export default Public;
