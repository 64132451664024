import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { translate } from "react-admin";

const CommentBox = props => {
    const { 
        reportId, type, handleSaveComment, 
        open, setOpen,
        translate,
        translationKeyDialogTitle,
        translationKeyDialogContentText,
        translationKeyTextField,
        translationKeyButtonSubmit,
        translationKeyButtonClose
    } = props;
    const [comment, setComment] = useState(null);

    const handleSave = () => {
        const newState = {
            reportId,
            type,
            comment
        };

        handleSaveComment(reportId, newState)
        setOpen(false);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleChange = e => {
        setComment(e.target.value);
    };
    

    return (
        <div>
            <Dialog
                open={open}
                onClose={() => ''}
                aria-labelledby="form-dialog-title"
            >
                <DialogTitle id="form-dialog-title">{translationKeyDialogTitle ? translate(translationKeyDialogTitle) : ''}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {translationKeyDialogContentText ? translate(translationKeyDialogContentText) : ''}
                    </DialogContentText>
                    <TextField
                        autoFocus
                        multiline={true}
                        margin="dense"
                        id="comment"
                        label={translationKeyTextField ? translate(translationKeyTextField) : ''}
                        onChange={handleChange}
                        fullWidth
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="gray">
                        {translationKeyButtonClose ? translate(translationKeyButtonClose) : ''}
                    </Button>
                    <Button onClick={handleSave} color="primary">
                        {translationKeyButtonSubmit ? translate(translationKeyButtonSubmit) : ''}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default translate(CommentBox);
