import React, { useState, useEffect } from "react";
import {
    TextField,
    DateField,
    ShowController,
    BooleanField,
    ReferenceField,
    FunctionField,
    SimpleShowLayout,
    ShowView,
    CardActions,
    ListButton,
    EditButton,
    translate
} from "react-admin";
import { Print } from "@material-ui/icons";
import { Button } from "@material-ui/core";
import {
    ApproveButton,
    ImagesButton,
    DocsButton,
    RejectButton,
    CommentButton
} from "../common";
import { vCoordinator, vDefender, vObserver } from "../utils/auth";
import { institutionId } from "../utils/storage";
import moment from 'moment'
import request from "../utils/request";
import { ImageGridList } from './Images';
import { DocGridList } from './Docs';
import { TextOnly } from "./TextOnly";
import { PrintContent } from "./PrintContent";
import "../css/Print.css";

const Imagen = ({ source, record = {} }) => (
    <img src={`${record[source]}`} alt="" />
);

Imagen.defaultProps = { addLabel: true };
const button = {
    draft: {
        background: "#828282",
        padding: "2px 10px",
        marginRight: "5px"
    },
    evaluation: {
        background: "#fb5a31",
        padding: "2px 10px",
        marginRight: "5px"
    },
    accepted: {
        background: "#1E90FF",
        padding: "2px 10px",
        marginRight: "5px"
    },
    resolved: {
        background: "#008000",
        padding: "2px 10px",
        marginRight: "5px"
    },
    comments: {
        background: "#828282",
        padding: "2px 10px",
        marginRight: "5px"
    },
    rejected: {
        background: "#FF2301",
        padding: "2px 10px",
        marginRight: "5px"
    }
};


const ReportShowActions = ({ basePath, triggerPrint, isPrinting, translate }) => {
    return (
        <div className="no-print">
            <CardActions>
                <Button
                    disabled={isPrinting}
                    onClick={() => triggerPrint()}
                    size="small"
                    color="primary"
                    style={{marginRight: '5px', opacity: isPrinting ? 0.7 : 1}}
                >
                    <Print size="small" style={{marginRight: '3px'}} />
                    {translate('print.btn')}
                </Button>
                <ListButton size="medium" basePath={basePath} />
            </CardActions>
        </div>
    );
}

const RenderField = ({ render, record }) => {
    return render(record);
}

const dateFieldRender = field => {
    // inicializar la fecha en UTC
    return moment(field).utc().format('DD/MM/yyyy')
}

const CustomEdit = props => {
    const record = props.record;
    const status = record.status;
    return (
        ["comments", "draft"].includes(status) && <EditButton {...props} />
    );
};
const CustomEditImage = props => {
    const record = props.record;
    const status = record.status;
    return (
        ["comments", "draft"].includes(status) && (
            <ImagesButton {...props} />
        )
    );
};
const CustomEditDoc = props => {
    const record = props.record;
    const status = record.status;
    return (
        ["comments", "draft"].includes(status) && <DocsButton {...props} />
    );
};

const Buttons = props => {
    const { record } = props;
    return (
        <div className="no-print">
            <div style={{marginBottom: 10}}>
                {(record.status === "draft" ||
                 record.status === "comments") &&
                 vDefender() && (
                    <ApproveButton
                        style={button.evaluation}
                        des="ENVIAR"
                        color="primary"
                        record={record}
                    />
                )}
                {vDefender() && <CustomEdit {...props} />}
                {vDefender() && <CustomEditImage {...props} />}
                {vDefender() && <CustomEditDoc {...props} />}
            </div>
            
            {vCoordinator() && (<>
                {record.institutionId === institutionId ? (<div style={{ marginBottom: '5px' }}>
                    <ApproveButton record={record} style={button.accepted} des="ACEPTAR" color="primary" />
                    <CommentButton record={record} style={button.comments} des="COMENTAR" color="primary" />
                    <RejectButton record={record} style={button.rejected} des="RECHAZAR" color="primary" />
                    <ApproveButton record={record} style={button.resolved} des="RESOLVER" color="primary" />
                </div>) : ''}
                {record.institutionId !== institutionId ? (<div style={{ marginBottom: '5px' }}>
                    <CommentButton record={record} style={button.comments} des="COMENTAR" color="primary" />
                </div>) : ''}
            </>)}
        </div>
    )
}


const TextListField = ({ source, label, ...rest }) => {
    return (
        <FunctionField
            source={source}
            render={record => {
                let items = record[source];
                return (items && items.length > 0 ? (
                    <div>
                        <label className="label">{label}</label>
                        <ul>
                            {items && items.map(item => (
                                <li key={item}>{ item.includes('Other') ? 'Otro' : item }</li>
                            ))}
                        </ul>
                    </div>
                ) : '');
            }}
            {...rest}
        />
    )
};

export const ReportShow = ({ translate, ...props }) => {
    const [attachedFiles, setAttachedFiles] = useState(null);
    const reportId = props.id;

    const loadImages = () => {
        request({
            method: "get",
            url: `/reports/${reportId}`
        })
        .then(res => {
            if (res && res.files) {
                setAttachedFiles( JSON.parse(res.files || '') );
            }
        })
        .catch(error => {
            console.log("ERROR GETTING IMAGES", error);
        });
    };  
            
    useEffect(() => {
        if (!attachedFiles) {
            loadImages();
        }    
    }, [attachedFiles]);


    return (
        <PrintContent reportId={reportId}>
            {({ componentRef, triggerPrint, isPrinting }) => (
                <div ref={componentRef}>
                    <ShowController { ...props}>
                        {ctlrProps =>
                            <ShowView actions={<ReportShowActions triggerPrint={triggerPrint} isPrinting={isPrinting} translate={translate} {...props} />} {...props} {...ctlrProps}>
                                <SimpleShowLayout>
                                    <span className="print-only print-date">Impresión: {moment().format('DD/MM/YYYY, HH:mm')}</span>

                                    <Buttons />


                                    {/* 1 form - Defender */}

                                    <FunctionField
                                        className="main-field"
                                        source="id"
                                        render={record => {
                                            return (<>
                                                <h1>#DEF{record.id}</h1>
                                            </>);
                                        }}
                                    /> {/* <TextField source="id" /> */}

                                    {ctlrProps.record && ctlrProps.record.institutionId &&
                                        (vObserver() ? (
                                            <FunctionField source="institutionId" reference="institutions" render={record => <TextOnly source="name" reference="institutions" id={record.institutionId} />} /> 
                                        ) : (
                                            <ReferenceField source="institutionId" reference="institutions" linkType={(vCoordinator() || vDefender()) ? 'show' : false}>
                                                <TextField source="name" />
                                            </ReferenceField>
                                        ))
                                    }

                                    {ctlrProps.record && ctlrProps.record.status &&
                                        <FunctionField
                                            source="status"
                                            style={{ color: button[ctlrProps.record.status].background }}
                                            render={record => {
                                                return {
                                                    "draft": "Borrador",
                                                    "evaluation": "Evaluación",
                                                    "accepted": "Aceptado",
                                                    "comments": "Comentarios",
                                                    "rejected": "Rechazado",
                                                    "resolved": "Resuelto"
                                                }[ record.status ];
                                            }}
                                        />
                                    }

                                    {ctlrProps.record && ctlrProps.record.comment &&
                                        <TextField source="comment" />
                                    }

                                    {ctlrProps.record && ctlrProps.record.datetimeModified &&
                                        <DateField source="datetimeModified" label={translate("datetimeModified")} showTime={true} />
                                    }

                                    {/* {ctlrProps.record && ctlrProps.record.placeId &&
                                        (vObserver() ? (
                                            <FunctionField source="placeId" reference="places" render={record => <TextOnly source="name" reference="places" id={record.placeId} />} /> 
                                        ) : (
                                            <ReferenceField source="placeId" reference="places" linkType={(vCoordinator() || vDefender()) ? 'show' : false}>
                                                <TextField source="name" />
                                            </ReferenceField>
                                        ))
                                    } */}

                                    {/* {ctlrProps.record && ctlrProps.record.placeOther &&
                                        <TextField source="placeOther" />
                                    } */}

                                    {ctlrProps.record && ctlrProps.record.place &&
                                        <TextField source="place" />
                                    }

                                    {ctlrProps.record && ctlrProps.record.captureDatetime &&
                                        <DateField source="captureDatetime" label={translate("resources.reports.fields.captureDatetime")} showTime={true} />
                                    }

                                    {ctlrProps.record && ctlrProps.record.source &&
                                        <TextField source="source" />
                                    }
                                    {ctlrProps.record && ctlrProps.record.sourceOther &&
                                        <TextField source="sourceOther" />
                                    }
                                    {ctlrProps.record && ctlrProps.record.sourceContact &&
                                        <TextField source="sourceContact" />
                                    }

                                    {/* 2 form - Torture */}
                                    <h3 className="form-title">Tortura</h3>

                                    {ctlrProps.record && ctlrProps.record.tortureOrMaltreatment &&
                                        <TextField source="tortureOrMaltreatment" label={translate("resources.reports.fields.tortureOrMaltreatment")} />
                                    }

                                    {ctlrProps.record && ctlrProps.record.tortureOrMaltreatment === 'Tortura' && ctlrProps.record.tortureType && ctlrProps.record.tortureType.length !== 0 ? (
                                        <TextListField source="tortureType" label={translate("resources.reports.fields.tortureType")} />
                                    ) : ''}
                                    
                                    {ctlrProps.record && ctlrProps.record.tortureOrMaltreatment === 'Tortura' && ctlrProps.record.tortureTypeOther && ctlrProps.record.tortureType.includes("tortureTypeOther") &&
                                        <TextField source="tortureTypeOther" />
                                    }

                                    {ctlrProps.record && ctlrProps.record.tortureOrMaltreatment === 'Maltratos' && ctlrProps.record.maltreatmentType && ctlrProps.record.maltreatmentType.length !== 0 ? (
                                        <TextListField source="maltreatmentType" label={translate("resources.reports.fields.maltreatmentType")} />
                                    ) : ''}

                                    {ctlrProps.record && ctlrProps.record.tortureOrMaltreatment === 'Maltratos' && ctlrProps.record.maltreatmentTypeOther && ctlrProps.record.maltreatmentType.includes("maltreatmentTypeOther") &&
                                        <TextField source="maltreatmentTypeOther" />
                                    }

                                    {ctlrProps.record && ctlrProps.record.factDescription &&
                                        <TextField source="factDescription" />
                                    }

                                    {ctlrProps.record && ctlrProps.record.tortureOrMaltreatment === 'Tortura' && ctlrProps.record.tortureType.includes('Física') &&
                                        <TextListField source="tortureTypePhy" label={translate("resources.reports.fields.tortureTypePhy")} />
                                    }
                                    {ctlrProps.record && ctlrProps.record.tortureOrMaltreatment === 'Tortura' && ctlrProps.record.tortureType.includes('Física') && ctlrProps.record.tortureTypePhy.includes('tortureTypePhyOther') &&
                                        <TextField source="tortureTypePhyOther" />
                                    }

                                    {ctlrProps.record && ctlrProps.record.tortureOrMaltreatment === 'Tortura' && ctlrProps.record.tortureType.includes('Física') && ctlrProps.record.tortureDamage &&
                                        <TextListField source="tortureDamage" label={translate("resources.reports.fields.tortureDamage")} />
                                    }
                                    {ctlrProps.record && ctlrProps.record.tortureOrMaltreatment === 'Tortura' && ctlrProps.record.tortureType.includes('Física') && ctlrProps.record.tortureDamage.includes('tortureDamageOther') &&
                                        <TextField source="tortureDamageOther" />
                                    }

                                    {ctlrProps.record && ctlrProps.record.tortureOrMaltreatment === 'Tortura' && ctlrProps.record.tortureType.includes('Psicológica') && ctlrProps.record.tortureTypePsy &&
                                        <TextListField source="tortureTypePsy" label={translate("resources.reports.fields.tortureTypePsy")} />
                                    }
                                    {ctlrProps.record && ctlrProps.record.tortureOrMaltreatment === 'Tortura' && ctlrProps.record.tortureType.includes('Psicológica') && ctlrProps.record.tortureTypePsy.includes('tortureTypePsyOther') &&
                                        <TextField source="tortureTypePsyOther" />
                                    }

                                    {ctlrProps.record && ctlrProps.record.tortureOrMaltreatment === 'Tortura' && ctlrProps.record.tortureType.includes('Sexual') && ctlrProps.record.tortureTypeSex &&
                                        <TextListField source="tortureTypeSex" label={translate("resources.reports.fields.tortureTypeSex")} />
                                    }
                                    {ctlrProps.record && ctlrProps.record.tortureOrMaltreatment === 'Tortura' && ctlrProps.record.tortureType.includes('Sexual') && ctlrProps.record.tortureTypeSex.includes('tortureTypeSexOther') &&
                                        <TextField source="tortureTypeSexOther" />
                                    }

                                    {ctlrProps.record && ctlrProps.record.tortureTime && ctlrProps.record.tortureTime.length !== 0 &&
                                        <TextListField source="tortureTime" label={translate("resources.reports.fields.tortureTime")} />
                                    }
                                    {ctlrProps.record && ctlrProps.record.tortureTimeOther && ctlrProps.record.tortureTime.includes("tortureTimeOther") !== 0 &&
                                        <TextField source="tortureTimeOther" />
                                    }

                                    {ctlrProps.record && ctlrProps.record.tortureCustody && ctlrProps.record.tortureCustody.length !== 0 &&
                                        <TextListField source="tortureCustody" label={translate("resources.reports.fields.tortureCustody")}/>
                                    }
                                    {ctlrProps.record && ctlrProps.record.tortureCustodyOther && ctlrProps.record.tortureCustody.includes("tortureCustodyOther") !== 0 &&
                                        <TextField source="tortureCustodyOther" />
                                    }

                                    <ReferenceField source="tortureAt" reference="placeGroups" linkType={(vCoordinator() || vDefender()) ? 'show' : false}>
                                        <TextField source="name" />
                                    </ReferenceField>
                                    {ctlrProps.record && ctlrProps.record.tortureAtOther &&
                                        <TextField source="tortureAtOther" />
                                    }

                                    {ctlrProps.record && ctlrProps.record.tortureAtPlaceId &&
                                        (vObserver() ? (
                                            <FunctionField source="tortureAtPlaceId" reference="places" render={record => <TextOnly source="name" reference="places" id={record.tortureAtPlaceId} />} /> 
                                        ) : (
                                            <ReferenceField source="tortureAtPlaceId" reference="places" linkType={(vCoordinator() || vDefender()) ? 'show' : false}>
                                                <TextField source="name" />
                                            </ReferenceField>
                                        ))
                                    }
                                    {ctlrProps.record && ctlrProps.record.tortureAtPlaceOther &&
                                        <TextField source="tortureAtPlaceOther" />
                                    }

                                    {ctlrProps.record && ctlrProps.record.aggressorId &&
                                        (vObserver() ? (
                                            <FunctionField source="aggressorId" reference="aggressors" render={record => <TextOnly source="name" reference="aggressors" id={record.aggressorId} />} /> 
                                        ) : (
                                            <ReferenceField source="aggressorId" reference="aggressors" linkType={(vCoordinator() || vDefender()) ? 'show' : false}>
                                                <TextField source="name" />
                                            </ReferenceField>
                                        ))
                                    }
                                    {ctlrProps.record && ctlrProps.record.aggressorOther &&
                                        <TextField source="aggressorOther" />
                                    }

                                    {ctlrProps.record && ctlrProps.record.aggressorInstitution &&
                                        <TextField source="aggressorInstitution" />
                                    }

                                    {ctlrProps.record && ctlrProps.record.aggressorName &&
                                        <TextField source="aggressorName" />
                                    }

                                    {ctlrProps.record && ctlrProps.record.medicName &&
                                        <TextField source="medicName" />
                                    }

                                    <BooleanField source="isPhoto" />

                                    {/* 3 form - Measures */}

                                    {(ctlrProps.record && (
                                        ctlrProps.record.urgentRequestType && ctlrProps.record.urgentRequestType.length
                                        || ctlrProps.record.caseNumber || ctlrProps.record.caseTitle || ctlrProps.record.caseFiscalAgent
                                    )) ? (
                                        <h3 className="form-title">Medidas</h3>
                                    ) : ''}

                                    {ctlrProps.record && ctlrProps.record.urgentRequestType && ctlrProps.record.urgentRequestType.length !== 0 &&
                                        <TextListField source="urgentRequestType" label={translate("resources.reports.fields.urgentRequestType")}/>
                                    }
                                    {ctlrProps.record && ctlrProps.record.urgentRequestType && ctlrProps.record.urgentRequestType.includes('urgentRequestTypeOther') && ctlrProps.record.urgentRequestTypeOther &&
                                        <TextField source="urgentRequestTypeOther" />
                                    }

                                    {ctlrProps.record && ctlrProps.record.caseNumber &&
                                        <TextField source="caseNumber" />
                                    }

                                    {ctlrProps.record && ctlrProps.record.caseTitle &&
                                        <TextField source="caseTitle" />
                                    }

                                    {ctlrProps.record && ctlrProps.record.caseFiscalAgent &&
                                        <TextField source="caseFiscalAgent" />
                                    }

                                    {/* 4 form - Victim */}
                                    <h3 className="form-title">Víctima</h3>

                                    {ctlrProps.record && ctlrProps.record.victimName &&
                                        <TextField source="victimName" />
                                    }

                                    {ctlrProps.record && ctlrProps.record.victimCin &&
                                        <TextField source="victimCin" />
                                    }

                                    {ctlrProps.record && ctlrProps.record.victimNationality &&
                                        <TextField source="victimNationality" />
                                    }

                                    {ctlrProps.record && ctlrProps.record.victimBirthdate &&
                                        <RenderField render={(record) => (
                                            <>
                                                <div>
                                                    <label style={{
                                                        color: "rgba(0, 0, 0, 0.54)",
                                                        fontFamily: "Roboto, Helvetica, Arial, sans-serif",
                                                        fontSize: "12px",
                                                        fontWeight: 400
                                                    }}>{translate(`resources.reports.fields.victimBirthdate`)} </label> <br/>
                                                    <span style={{
                                                        fontSize: "14px"
                                                    }}>{dateFieldRender(record.victimBirthdate)}</span>
                                                </div>
                                            </>
                                        )}></RenderField>
                                    }

                                    {ctlrProps.record && ctlrProps.record.victimAge &&
                                        <TextField source="victimAge" />
                                    }

                                    {ctlrProps.record && ctlrProps.record.victimGender &&
                                        <TextField source="victimGender" />
                                    }

                                    {ctlrProps.record && ctlrProps.record.victimGender 
                                    && ctlrProps.record.victimGender === 'Femenino' &&
                                        <BooleanField source="isVictimPregnant" />
                                    }

                                    <BooleanField source="isVictimLgbti" />

                                    <BooleanField source="isVictimNative" />
                                    {ctlrProps.record 
                                    && ctlrProps.record.isVictimNative
                                    && ctlrProps.record.victimTribe 
                                    && (ctlrProps.record.victimTribe.includes('victimTribeOther') 
                                        ? (<div><label className="label">{translate("resources.reports.fields.victimTribe")}</label><div style={{textAlign: 'left', marginTop: '5px'}}>Otro</div></div>) 
                                        : (<TextField source="victimTribe" />)
                                    )}
                                    {ctlrProps.record 
                                    && ctlrProps.record.victimTribe 
                                    && ctlrProps.record.victimTribe === 'victimTribeOther'
                                    && ctlrProps.record.victimTribeOther &&
                                        <TextField source="victimTribeOther" />
                                    }

                                    <BooleanField source="isVictimDisabled" />
                                    {ctlrProps.record
                                    && ctlrProps.record.isVictimDisabled
                                    && ctlrProps.record.victimDisabilityType
                                    && (ctlrProps.record.victimDisabilityType === 'victimDisabilityTypeOther'
                                        ? (<div><label className="label">{translate("resources.reports.fields.victimDisabilityType")}</label><div style={{textAlign: 'left', marginTop: '5px'}}>Otro</div></div>) 
                                        : (<TextField source="victimDisabilityType" />)
                                    )}
                                    {ctlrProps.record 
                                    && ctlrProps.record.victimDisabilityType 
                                    && ctlrProps.record.victimDisabilityType === 'victimDisabilityTypeOther' 
                                    && ctlrProps.record.victimDisabilityTypeOther &&
                                        <TextField source="victimDisabilityTypeOther" />
                                    }

                                    <BooleanField source="isVictimAfro" />

                                    {ctlrProps.record 
                                    && ctlrProps.record.isWillingToFileReport 
                                    && (ctlrProps.record.isWillingToFileReport === 'isWillingToFileReportOther' 
                                        ? (<div><label className="label">{translate("resources.reports.fields.isWillingToFileReport")}</label><div style={{textAlign: 'left', marginTop: '5px'}}>Otro</div></div>) 
                                        : (<TextField source="isWillingToFileReport" />)
                                    )}
                                    {ctlrProps.record && ctlrProps.record.isWillingToFileReport && ctlrProps.record.isWillingToFileReport.includes('isWillingToFileReportOther') && ctlrProps.record.isWillingToFileReportOther &&
                                        <TextField source="isWillingToFileReportOther" />
                                    }
                                    
                                    <br />

                                    {(attachedFiles && attachedFiles.docs && attachedFiles.docs.length) ? (<>
                                        <DocGridList docs={attachedFiles.docs} onDelete={false} />
                                    </>) : ''}

                                    {(attachedFiles && attachedFiles.pics && attachedFiles.pics.length) ? (<>
                                        <ImageGridList images={attachedFiles.pics} onDelete={false} isPrinting={isPrinting} />
                                    </>) : ''}

                                    {(attachedFiles && ((attachedFiles.docs && attachedFiles.docs.length) || (attachedFiles.pics && attachedFiles.pics.length))) ? (<>
                                        <br />
                                    </>) : ''}
                                    
                                    <Buttons />
                                    
                                </SimpleShowLayout>
                                
                            </ShowView>
                        }
                    </ShowController>
                </div>
            )}
        </PrintContent>
    );
}

export default translate(ReportShow);
