import React from "react";
import { Button } from "@material-ui/core";
import { AddAPhoto } from "@material-ui/icons";

const onClick = reportId => {
    //console.log("reportId", reportId);
};

const ImagesButton = ({ record }) => {
    const reportId = record.id;
    const url = `/#/images/${reportId}`;
    return (
        <a href={url} style={{textDecoration: "none"}}>
            <Button
                size="small"
                color="primary"
                onClick={() => onClick(reportId)}
            >
                <AddAPhoto size="small" />
                &nbsp;Imágenes
            </Button>
        </a>
    );
};

export default ImagesButton;
