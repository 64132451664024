import React from "react";
import {
    ReferenceField,
    FunctionField,
    TextField,
    ShowController,
    SimpleShowLayout,
    ShowView
} from "react-admin";

export const PlaceShow = props => (
    <ShowController {...props}>
        {controllerProps =>
            <ShowView {...props} {...controllerProps}>
                <SimpleShowLayout>
                    <ReferenceField source="placeGroupId" reference="placeGroups" linkType="show">
                        <TextField source="name" />
                    </ReferenceField>
                    <FunctionField
                        source="departament"
                        render={record => [
                            'Alto Paraguay',
                            'Alto Paraná',
                            'Amambay',
                            'Boquerón',
                            'Caaguazú',
                            'Caazapá',
                            'Canindeyú',
                            'Central',
                            'Concepción',
                            'Cordillera',
                            'Guairá',
                            'Itapúa',
                            'Misiones',
                            'Ñeembucú',
                            'Paraguarí',
                            'Presidente',
                            'San Pedro'
                        ][record.departament_number]}
                    />
                    <TextField source="district" />
                    <TextField source="name" />
                    <TextField source="lng" />
                    <TextField source="lat" />
                </SimpleShowLayout>
            </ShowView>
        }
    </ShowController>
);

export default PlaceShow;
