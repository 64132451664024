export const myEs = {
    "": "",
    Unauthorized: "Sin autorización",
    bad_credentials: "Credenciales incorrectas",
    not_active_user: "Cuenta desactivada",
    datetime: "Fecha/hora de elab. del form.",
    datetimeModified: "Fecha de última modificacion",
    datetimeModifiedGrid: "Última modificación",
    recover_password_title: "Reestablecer contraseña",
    send_recover_password: "Enviar correo electrónico",
    email_recover_password: "Correo electrónico",
    email_recover_password_desc: "Te enviaremos un correo para reestablecer tu contraseña",
    email_recover_sended_1: "Te enviamos un correo con un enlace de recuperación de contraseña",
    email_recover_sended_2: "Deberás revisar tu correo y hacer clic en el enlace, eso te permitirá crear tu contraseña",
    email_recover_end: "Ir al inicio",
    forgot_password: "¿Olvidaste tu contraseña?",
    pwd: {
        min: "Mínimo de 10 caracteres",
        max: "Máximo de 30 caracteres",
        strong: 'La contraseña debe ser más fuerte (debe contener mayúsculas, minúsculas y números)',
        pass_not_match: 'Las contraseñas no coinciden',
        pass_not_same: 'La nueva contraseña debe ser diferente a la anterior'
    },
    old_password: "Contraseña actual",
    new_password: "Cambiar contraseña",
    confirm_password: "Confirmar contraseña",
    changepassword_fail: 'Contraseña actual incorrecta',
    password: "Contraseña",
    restore_passwod: "Cambiar",
    code: "Ingrese el código recibido por correo electrónico",
    codeSent: "Código enviado por correo electrónico",
    requestCode: "Enviar código por correo electrónico",
    title_reason_rejected: "Rechazar",
    reason_rejected: "Explique motivo del rechazo",
    label_comment_reason_rejected: "Comentario",
    save_button_reason_rejected: "Guardar",
    notification_report_evaluation: "Reporte enviado a evaluación",
    notification_report_accepted: "Reporte aceptado",
    notification_report_comments: "Reporte comentado",
    notification_report_rejected: "Reporte rechazado",
    notification_report_resolved: "Reporte resuelto",
    title_reason_comments: "Comentar",
    reason_comments: "Explique la razón del comentario",
    label_comment_reason_comments: "Comentar",
    save_button_reason_comments: "Guardar",
    close: "Cerrar",
    save_button: "Guardar",
    language: "Idiomas",
    maps: "Mapa",
    title_map: "Buscar reportes",
    filter_status: "Filtrar por estado",
    filter_defender: "Filtrar por reportante",
    filter_date: "Filtrar por fecha",
    filter_places: "Filtrar por lugar",
    point_place: "Lugar",
    point_captureDatetime: "Fecha hecho",
    point_gender: "Sexo",
    point_status: "Estado",
    all: "Todos",
    draft: "Borrador",
    evaluation: "Evaluación",
    accepted: "Aceptado",
    comments: "Comentarios",
    rejected: "Rechazado",
    resolved: "Resuelto",
    stats: "Estadísticas",
    male: "Masculino",
    female: "Femenino",
    age_under18: "Menores de 18",
    age_between18_24: "18-24",
    age_between25_30: "25-30",
    age_between31_35: "31-25",
    age_between36_49: "36-49",
    age_between50_60: "50-60",
    age_over61: "Mayores de 60",
    stats_state: "Estado",
    stats_total: "Cantidad",
    stats_draft: "Borrador",
    stats_evaluation: "Evaluación",
    stats_accepted: "Aceptado",
    stats_comments: "Comentarios",
    stats_rejected: "Rechazado",
    stats_resolved: "Resuelto",
    stats_title_reports: "Reportes por estado",
    sex_title_reports: "Reportes por sexo",
    stats_sex: "Sexo",
    age_title_reports: "Reportes por edades (campo edad)",
    stats_age: 'Edad',
    places_title_reports: "10 lugares con más reportes",
    stats_places: 'Lugar',
    aggressors_title_reports: "10 instituciones agresoras más reportadas",
    visualizations: "Visualizaciones",
    total_report: "Total",
    by_states_reports: "Por estado",
    by_sex_reports: "Por sexo",
    by_places_reports: "10 lugares con más reportes",
    by_aggressors_reports: "10 instituciones agresoras con más reportes",
    profile: "Mi perfil",
    settings: "Configuraciones",
    sessions: "Sesiones abiertas",
    mySessions: "Mis sesiones abiertas",
    token: "Token",
    action: "Acción",
    closeSessions: "Cerrar sesión",
    noOpenSessions: "Sin sesiones abiertas",
    reset: "Cambiar contraseña",
    changepassword_finish: "Se ha reestablecido la contraseña correctamente",
    coordinator: "Coordinador",
    defender: "Reportante",
    observer: "Veedor",
    public: "Público",
    validation: {
        notification: "Reporte validado"
    },
    LOGIN_FAILED: "Credenciales incorrectas",
    NOT_ACTIVE_USER: "La cuenta no se encuentra activa",
    otp: {
        title: "Autenticación de doble factor OTP",
        generate: {
            subtitle: "¿Cómo configurar la app de autenticación?",
            step1: "1. Instale una app 2FA (ej: Aegis, Raivo)",
            step2: "2. En la app cree una nueva entrada, presionando +",
            step3: "3. Escanee el código QR que aparece abajo:",
            scanTitle: "Código QR a escanear:",
            typeTitle: "Si no puede escanear, use la siguiente llave en su app:",
            secretKey: "Llave secreta",
            verifyCodeTitle: "Código de verificación inicial",
            verifyCodeDesc: "Para seguir, ingrese el código de autenticación",
            otpToken: "Código de autenticación",
            authCode: "Código de verificación",
        },
        validate: {
            validateFailed: 'Código incorrecto',
            INVALID_OTP_TOKEN: 'Código incorrecto',
            otpTokenMissing: "Ingrese código de autenticación",
            tokenInputDesc: "Abra la app generadora de códigos para obtener el código de autenticación",
            tokenInput: "Código de autenticación",
            otpEnabledMissing: "Necesita configurar la autenticación 2FA para iniciar sesión"
        }
    },
    print: {
        title: 'Impresión de Reportes',
        btn: 'Imprimir'
    },
    report_tab_defender: "General",
    report_tab_torture: "Tortura",
    report_tab_measures: "Medidas",
    report_tab_victim: "Víctima",
    report_tab_files: "Adjuntos",
    resources: {
        institutions: {
            name: "Institución |||| Instituciones",
            fields: {
                name: "Nombre"
            }
        },
        users: {
            name: "Cuenta |||| Cuentas",
            fields: {
                firstname: "Nombre",
                lastname: "Apellido",
                institutionId: "Institución",
                position: "Cargo",
                employer: "Lugar de trabajo",
                number: "Número de trabajador/a",
                role: "Rol",
                password: "Contraseña",
                confirmpassword: "Confirmar contraseña",
                isActive: "Está activa",
                otp_enabled: "Autenticación 2FA"
            }
        },
        reports: {
            name: "Reporte |||| Reportes",
            fields: {
                id: "Código",
                states: "Estado",
                status: "Estado",
                institutionId: "Institución",
                userId: "Reportante",
                userNumber: "Número del Reportante",
                accountName: "Reportante",
                standardDatetime: "Fecha",
                place: "Lugar de elab. del form.",
                placeIdOther: "Otro lugar de elab. del form.",
                placeOther: "Otro lugar",
                source: "Reportante",
                sourceName: "Nombre del Reportante",
                sourceContact: "Contacto del Reportante",
                captureDatetime: "Fecha y hora del hecho",
                tortureOrMaltreatment: "Tipo de hecho",
                maltreatmentType: "¿Qué derecho se vulneró?",
                maltreatmentTypeOther: "Otro - ¿Qué otro derecho se vulneró?",
                factDescription: "Describa brevemente el hecho",
                tortureType: "Tortura",
                tortureTypeOther: "Otro tipo de tortura",
                tortureTypePhy: "Tipo de tortura física recibida",
                tortureTypePhyOther: "Otro tipo de tortura física recibida",
                tortureDamage: "¿En qué parte del cuerpo lo lastimaron?",
                tortureDamageOther: "Otra parte del cuerpo lastimada",
                tortureTypePsy: "Tipo de tortura psicológica recibida",
                tortureTypePsyOther: "Otro tipo de tortura psicológica recibida",
                tortureTypeSex: "Tipo de tortura sexual recibida",
                tortureTypeSexOther: "Otro tipo de tortura sexual recibida",
                tortureTime: "¿En qué momento ocurrió?",
                tortureTimeOther: "Otro - ¿En qué otro momento ocurrió?",
                tortureCustody: "Bajo qué custodia ocurrió",
                tortureCustodyOther: "Otro - Bajo qué otra custodia ocurrió",
                tortureAt: "¿Donde ocurrió el hecho?",
                tortureAtOther: "Otro - En qué otro lugar ocurrió el hecho",
                tortureAtPlaceId: "¿Donde ocurrió el hecho? - Lugar",
                tortureAtPlaceOther: "Otro lugar, institución o localidad no listado",
                aggressorId: "Si el agresor es agente, seleccione de donde",
                aggressorOther: "Otro - El agresor es agende de",
                aggressorInstitution: "Si el/la agresor/a pertenece a una institución, mencionarla aquí",
                aggressorName: "Nombre y apellido del agresor",
                medicName: "Nombre del médico forense",
                isPhoto: "¿Se realizaron tomas fotográficas?",
                urgentRequestType: "Medidas urgentes de protección solicitadas",
                urgentRequestTypeOther: "Otras medidas urgentes de protección solicitadas",
                caseNumber: "Número de causa",
                caseTitle: "Carátula",
                caseFiscalAgent: "Fiscal asignado",
                victimName: "Nombre y apellido",
                victimCin: "C.I.N",
                victimNationality: "Nacionalidad",
                victimBirthdate: "Fecha de nacimiento",
                victimAge: "Edad",
                victimGender: "Sexo",
                isVictimPregnant: "Embarazada",
                isVictimLgbti: "LGBTI",
                isVictimNative: "Pertenece a la comunidad indígena",
                victimTribe: "Pueblo originario",
                victimTribeOther: "Otro - Pueblo originario",
                isVictimDisabled: "Persona con discapacidad",
                victimDisabilityType: "Tipo de discapacidad",
                victimDisabilityTypeOther: "Otro tipo de discapacidad",
                isVictimAfro: "Grupo afrodescendiente",
                isWillingToFileReport: "¿La víctima está dispuesta a denunciar bajo consentimiento informado?",
                isWillingToFileReportOther: "Otro - ¿Está dispuesta a denunciar?",
                comment: "Comentario"
            }
        },
        places: {
            name: "Lugar |||| Lugares",
            fields: {
                departament: "Departamento",
                departament_number: "Nº de departamento",
                district: "Distrito",
                description: "Nombre",
                name: "Nombre",
                lat: "Latitud",
                lng: "Longitud",
                placeGroupId: "Grupo de lugares"
            }
        },
        placeGroups: {
            name: "Grupo de Lugares |||| Grupos de Lugares",
            fields: {
                name: "Nombre"
            }
        },
        aggressors: {
            name: "Agresor |||| Agresores",
            fields: {
                name: "Nombre"
            }
        },
        locations: {
            name: "Localidad |||| Localidades",
            fields: {
                name: "Nombre"
            }
        },
        tortureTypes: {
            name: "Tipo de tortura |||| Tipos de tortura",
            fields: {
                description: "Descripción"
            }
        },
        states: {
            name: "Estado |||| Estados",
            fields: {
                description: "Descripción"
            }
        },
        logs: {
            name: "Registro |||| Registros",
            fields: {
                datetime: "Fecha y hora",
                userId: "Cuenta",
                type: "Tipo",
                model: "Entidad",
                modelId: "Id",
                changes: "Descripción"
            }
        },
        sessions: {
            name: "Sesión |||| Sesiones",
            fields: {
                agent: "Agente de usuario",
                datetime: "Fecha y hora",
                userId: "Cuenta",
                client: "Cliente",
                ip: "Dirección IP",
                token: "Token"
            }
        },
        settings: {
            name: "Configuración |||| Configuraciones",
            fields: {
                change_status_hours: "Cambio de estado en horas",
                languages: "Idiomas disponibles",
                daysOfInactivity: "Cantidad máxima de días de inactividad"
            }
        }
    }
};

export const myEn = {
    "": "",
    Unauthorized: "Unauthorized",
    bad_credentials: "Bad credentials",
    not_active_user: "Not active account",
    datetime: "Date and time the form was prepared",
    datetimeModified: "Date mofified",
    datetimeModifiedGrid: "Last mofified",
    captureDatetime: "Date and time of capture",
    recover_password_title: "Recover password",
    send_recover_password: "Send email",
    email_recover_password: "Email",
    email_recover_password_desc: "We'll send you an email to reset your password",
    email_recover_sended_1: "We sent you an email with a password reset link",
    email_recover_sended_2: "Deberás revisar tu correo y hacer clic en el enlace, eso te permitirá crear tu contraseña",
    email_recover_end: "Go home",
    forgot_password: "Did you forget your password?",
    pwd: {
        min: "Minimum of de 8 chars",
        max: "Maximum of 30 chars",
        strong: 'The password must be stronger (Must contain uppercase, lowercase and numbers).',
        pass_not_match: 'Passwords don not match'
    },
    old_password: "Current password",
    new_password: "Change password",
    password: "Password",
    confirmpassword: "Confirm password",
    changepassword_fail: 'Incorrect password',
    password: "Password",
    restore_passwod: "Change",
    code: "Code",
    codeSent: "Code sent by email",
    requestCode: "Send code via email",
    title_reason_rejected: "To reject",
    reason_rejected: "Explain reason for rejection.",
    label_comment_reason_rejected: "Comment",
    save_button_reason_rejected: "Save",
    notification_report_evaluation: "Report sent to evaluation",
    notification_report_accepted: "Report accepted",
    notification_report_comments: "Report commented",
    notification_report_rejected: "Report rejected",
    notification_report_resolved: "Report solved",
    title_reason_comments: "Comment",
    reason_comments: "Explain comment.",
    label_comment_reason_comments: "Comment",
    save_button_reason_comments: "Save",
    close: "Close",
    language: "Languages",
    maps: "Map",
    title_map: "Search reports",
    filter_status: "Filter by state",
    filter_defender: "Filter by defender",
    filter_date: "Filter by date",
    filter_places: "Filter by places",
    point_place: "Place",
    point_captureDatetime: "capture date time",
    point_gender: "Sex",
    point_status: "Status",
    all: "All",
    draft: "Draft",
    evaluation: "Evaluation",
    accepted: "Accepted",
    comments: "Comments",
    rejected: "Rejected",
    resolved: "Resolved",
    stats: "Statistics",
    male: "Male",
    female: "Female",
    age_under18: "Under 18",
    age_between18_24: "18-24",
    age_between25_30: "25-30",
    age_between31_35: "31-25",
    age_between36_49: "36-49",
    age_between50_60: "50-60",
    age_over61: "Over 60",
    stats_state: "State",
    stats_total: "Total",
    stats_draft: "Draft",
    stats_evaluation: "Evaluation",
    stats_accepted: "Accepted",
    stats_comments: "Comments",
    stats_rejected: "Rejected",
    stats_resolved: "Resolved",
    stats_title_reports: "Reports by state",
    sex_title_reports: "Reports by sex",
    stats_sex: "Sex",
    age_title_reports: "Reports by age (age field)",
    stats_age: 'Age',
    places_title_reports: "10 Places with more reports",
    stats_places: 'Place',
    aggressors_title_reports: "10 most denounced institutions (aggressors)",
    visualizations: "Visualizations",
    total_report: "Total",
    by_states_reports: "By state",
    by_sex_reports: "By sex",
    by_places_reports: "10 places with more reports",
    by_aggressors_reports: "10 aggresors with more reports",
    profile: "My Profile",
    settings: "Settings",
    sessions: "Open sessions",
    mySessions: "My open sessions",
    token: "Token",
    action: "Action",
    closeSessions: "Close session",
    noOpenSessions: "No open sessions",
    reset: "Change password",
    coordinator: "Coordinator",
    defender: "Reporter",
    observer: "Observer",
    public: "Public",
    validation: {
        notification: "Report validated"
    },
    LOGIN_FAILED: "Incorrect credentials",
    NOT_ACTIVE_USER: "User not active",
    otp: {
        title: "OTP two factor authentication",
        generate: {
            subtitle: "Setup authenticator app",
            step1: "1. Install TOTP 2FA app in your mobile (ie: Aegis Authenticator)",
            step2: "2. Open yout app, select + button",
            step3: "3. Select \"Scan QR code\".",
            scanTitle: "Scan QR code",
            typeTitle: "Or write token in the app",
            secretKey: "Secret key",
            verifyCodeTitle: "Verify code",
            verifyCodeDesc: "Para guardar la configuracion ingrese el código de autenticación",
            otpToken: "Authentication code",
            authCode: "Verification code",
        },
        validate: {
            validateFailed: 'Código incorrecto',
            INVALID_OTP_TOKEN: 'Código incorrecto',
            otpTokenMissing: "Ingrese código 2FA",
            tokenInputDesc: "Abra la App de autenticación 2FA desde su móvil para obtener el código de inicio",
            tokenInput: "Código de inicio",
            otpEnabledMissing: "Necesita configurar la autenticación 2FA para iniciar sesión"
        }
    },
    print: {
        title: 'Report print',
        btn: 'Print'
    },
    report_tab_defender: "General",
    report_tab_torture: "Torture",
    report_tab_measures: "Measures",
    report_tab_victim: "Victim",
    report_tab_files: "Files",
    resources: {
        institutions: {
            name: "Institución |||| Instituciones",
            fields: {
                name: "Name"
            }
        },
        users: {
            name: "User |||| Users",
            fields: {
                firstname: "Name",
                lastname: "Last name",
                institutionId: "Institution",
                position: "Cargo",
                employer: "Work",
                number: "Worker number",
                role: "Role",
                password: "Password",
                confirmpassword: "Confirm password",
                isActive: "Is active",
                otp_enabled: "OTP two factor authentication"
            }
        },
        reports: {
            name: "Report |||| Reports",
            fields: {
                id: "Código",
                states: "State",
                status: "Status",
                institutionId: "Institution",
                userId: "Reporter",
                userNumber: "Reporter's Number",
                accountName: "Reporter",
                standardDatetime: "Date",
                place: "Lugar de elab. del formulario",
                placeIdOther: "Otro lugar de elab. del formulario",
                placeOther: "Otro lugar",
                source: "Reportante",
                sourceName: "Nombre del Reportante",
                sourceContact: "Contacto del Reportante",
                captureDatetime: "Fecha y hora del hecho",
                tortureOrMaltreatment: "Tipo de hecho",
                maltreatmentType: "¿Qué derecho se vulneró?",
                maltreatmentTypeOther: "Otro - ¿Qué otro derecho se vulneró?",
                factDescription: "Describa brevemente el hecho",
                tortureType: "Tortura",
                tortureTypeOther: "Otro tipo de tortura",
                tortureTypePhy: "Tipo de tortura física recibida",
                tortureTypePhyOther: "Otro tipo de tortura física recibida",
                tortureDamage: "¿En qué parte del cuerpo lo lastimaron?",
                tortureDamageOther: "Otra parte del cuerpo lastimada",
                tortureTypePsy: "Tipo de tortura psicológica recibida",
                tortureTypePsyOther: "Otro tipo de tortura psicológica recibida",
                tortureTypeSex: "Tipo de tortura sexual recibida",
                tortureTypeSexOther: "Otro tipo de tortura sexual recibida",
                tortureTime: "¿En qué momento ocurrió?",
                tortureTimeOther: "Otro - ¿En qué otro momento ocurrió?",
                tortureCustody: "Bajo qué custodia ocurrió",
                tortureCustodyOther: "Otro - Bajo qué otra custodia ocurrió",
                tortureAt: "¿Donde ocurrió el hecho?",
                tortureAtOther: "Otro - En qué otro lugar ocurrió el hecho",
                tortureAtPlaceId: "¿Donde ocurrió el hecho? - Lugar",
                tortureAtPlaceOther: "Otro lugar, institución o localidad no listado",
                aggressorId: "Si el agresor es agente, seleccione de donde",
                aggressorOther: "Otro - El agresor es agende de",
                aggressorInstitution: "Si el/la agresor/a pertenece a una institución, mencionarla aquí",
                aggressorName: "Nombre y apellido del agresor",
                medicName: "Nombre del médico forense",
                isPhoto: "¿Se realizaron tomas fotográficas?",
                urgentRequestType: "Medidas urgentes de protección solicitadas",
                urgentRequestTypeOther: "Otras medidas urgentes de protección solicitadas",
                caseNumber: "Número de causa",
                caseTitle: "Carátula",
                caseFiscalAgent: "Fiscal asignado",
                victimName: "Nombre y apellido",
                victimCin: "C.I.N",
                victimNationality: "Nacionalidad",
                victimBirthdate: "Fecha de nacimiento",
                victimAge: "Edad",
                victimGender: "Sexo",
                isVictimPregnant: "Embarazada",
                isVictimLgbti: "LGBTI",
                isVictimNative: "Pertenece a la comunidad indígena",
                victimTribe: "Pueblo originario",
                victimTribeOther: "Otro - Pueblo originario",
                isVictimDisabled: "Persona con discapacidad",
                victimDisabilityType: "Tipo de discapacidad",
                victimDisabilityTypeOther: "Otro tipo de discapacidad",
                isVictimAfro: "Grupo afrodescendiente",
                isWillingToFileReport: "¿La víctima está dispuesta a denunciar bajo consentimiento informado?",
                isWillingToFileReportOther: "Otro - ¿Está dispuesta a denunciar?",
                comment: "Comentario"
            }
        },
        places: {
            name: "Place |||| Places",
            fields: {
                departament: "Department",
                departament_number: "Department number",
                district: "District",
                description: "Name",
                name: "Name",
                lat: "Latitude",
                lng: "Longitude",
                placeGroupId: "Placegroup",
            }
        },
        placeGroups: {
            name: "Place group |||| Place groups",
            fields: {
                name: "Name"
            }
        },
        aggressors: {
            name: "Aggresor |||| Aggresors",
            fields: {
                name: "Name"
            }
        },
        locations: {
            name: "Location |||| Locations",
            fields: {
                name: "Nombre"
            }
        },
        tortureTypes: {
            name: "Torture Type |||| Torture Types",
            fields: {
                description: "Description"
            }
        },
        states: {
            name: "State |||| States",
            fields: {
                description: "Description"
            }
        },
        logs: {
            name: "Log |||| Logs",
            fields: {
                datetime: "Date and time",
                userId: "User",
                type: "Type",
                model: "Entity",
                changes: "Description"
            }
        },
        sessions: {
            name: "Session |||| Sessions",
            fields: {
                agent: "User agent",
                datetime: "Date and time",
                userId: "User",
                client: "Client",
                ip: "IP",
                token: "Token"
            }
        },
        settings: {
            name: "Configuración |||| Configuraciones",
            fields: {
                change_status_hours: "Cambio de estado en horas",
                languages: "Idiomas disponibles del sistema",
                daysOfInactivity: "Cantidad máxima de días de inactividad"
            }
        }
    }
};

export const myGn = {
    "": "",
    Unauthorized: "Sin autorización",
    bad_credentials: "Credenciales incorrectas",
    not_active_user: "Cuenta desactivada",
    datetime: "Fecha y hora de elab. el formulario",
    datetimeModified: "Fecha de última modificacion",
    datetimeModifiedGrid: "Última modificación",
    captureDatetime: "Date and time of capture",
    recover_password_title: "Reestablecer contraseña",
    send_recover_password: "Enviar correo electrónico",
    email_recover_password: "Correo electrónico",
    email_recover_password_desc: "Te enviaremos un correo para reestablecer tu contraseña",
    email_recover_sended_1: "Te enviamos un correo con un enlace de recuperación de contraseña",
    email_recover_sended_2: "Deberás revisar tu correo y hacer clic en el enlace, eso te permitirá crear tu contraseña",
    email_recover_end: "Ir al inicio",
    forgot_password: "¿Olvidaste tu contraseña?",
    pwd: {
        min: "Mínimo de 10 caracteres",
        max: "Máximo de 30 caracteres",
        strong: 'La contraseña debe ser más fuerte (debe contener mayúsculas, minúsculas y números)',
        pass_not_match: 'Las contraseñas no coinciden'
    },
    old_password: "Contraseña actual",
    new_password: "Cambiar contraseña",
    password: "Contraseña",
    confirmpassword: "Confirmar contraseña",
    changepassword_fail: 'Contraseña actual incorrecta',
    password: "Contraseña",
    restore_passwod: "Cambiar",
    code: "Ingrese el código recibido por correo electrónico",
    codeSent: "Código enviado por correo electrónico",
    requestCode: "Enviar código por correo electrónico",
    title_reason_rejected: "Rechazar",
    reason_rejected: "Explique motivo del rechazo.",
    label_comment_reason_rejected: "Comentario",
    save_button_reason_rejected: "Guardar",
    notification_report_evaluation: "Reporte enviado a evaluación",
    notification_report_accepted: "Reporte aceptado",
    notification_report_comments: "Reporte comentado",
    notification_report_rejected: "Reporte rechazado",
    notification_report_resolved: "Reporte resuelto",
    title_reason_comments: "Comentar",
    reason_comments: "Explique la razón del comentario.",
    label_comment_reason_comments: "Comentar",
    save_button_reason_comments: "Guardar",
    close: "Cerrar",
    language: "Ñeʼẽ",
    maps: "Mapa",
    title_map: "Buscar reportes",
    filter_status: "Filtrar por estado",
    filter_defender: "Filtrar por reportante",
    filter_date: "Filtrar por fecha",
    filter_places: "Filtrar por lugar",
    point_place: "Lugar",
    point_captureDatetime: "Fecha hecho",
    point_gender: "Sexo",
    point_status: "Estado",
    all: "Todos",
    draft: "Borrador",
    evaluation: "Evaluación",
    accepted: "Aceptado",
    comments: "Comentarios",
    rejected: "Rechazado",
    resolved: "Resuelto",
    stats: "Estadísticas",
    male: "Masculino",
    female: "Femenino",
    age_under18: "Menor a 18",
    age_between18_24: "18-24",
    age_between25_30: "25-30",
    age_between31_35: "31-25",
    age_between36_49: "36-49",
    age_between50_60: "50-60",
    age_over61: "Mayores a 60",
    stats_state: "Estado",
    stats_total: "Cantidad",
    stats_draft: "Borrador",
    stats_evaluation: "Evaluación",
    stats_accepted: "Aceptado",
    stats_comments: "Comentarios",
    stats_rejected: "Rechazado",
    stats_resolved: "Resuelto",
    stats_title_reports: "Reportes por estado",
    sex_title_reports: "Reportes por sexo",
    stats_sex: "Sexo",
    age_title_reports: "Reportes por edades (basada en el campo edad)",
    stats_age: 'Edad',
    places_title_reports: "10 Lugares con más reportes",
    stats_places: 'Lugar',
    aggressors_title_reports: "10 instituciones más reportadas (agresores)",
    visualizations: "Visualizaciones",
    total_report: "Total",
    by_states_reports: "Por estado",
    by_sex_reports: "Por sexo",
    by_places_reports: "10 lugares con más reportes",
    by_aggressors_reports: "10 agresores con más reportes",
    profile: "Mi perfil",
    settings: "Configuraciones",
    sessions: "Sesiones abiertas",
    mySessions: "Mis sesiones abiertas",
    token: "Token",
    action: "Acción",
    closeSessions: "Cerrar sesión",
    noOpenSessions: "Sin sesiones abiertas",
    reset: "Cambiar contraseña",
    coordinator: "Coordinador",
    defender: "Defensor",
    observer: "Veedor",
    public: "Público",
    validation: {
        notification: "Reporte validado"
    },
    LOGIN_FAILED: "Credenciales incorrectas",
    NOT_ACTIVE_USER: "La cuenta no se encuentra activa",
    otp: {
        title: "Autenticación de doble factor OTP",
        generate: {
            subtitle: "Configurar app de autenticación",
            step1: "1. Instalar app de 2FA(TOTP)",
            step2: "2. En la app elegir +",
            step3: "3. Escanear el QR que aparece abajo:",
            scanTitle: "Escanear código QR",
            typeTitle: "Si no puede escanear, use la siguiente llave en su app:",
            secretKey: "Llave secreta",
            verifyCodeTitle: "Código de verificación inicial",
            verifyCodeDesc: "Para guardar la configuracion ingrese el código de autenticación",
            otpToken: "Código de autenticación",
            authCode: "Código de verificación",
        },
        validate: {
            validateFailed: 'Código incorrecto',
            INVALID_OTP_TOKEN: 'Código incorrecto',
            otpTokenMissing: "Ingrese código de autenticación",
            tokenInputDesc: "Abra la app generadora de códigos para obtener el código de autenticación",
            tokenInput: "Código de autenticación",
            otpEnabledMissing: "Necesita configurar la autenticación 2FA para iniciar sesión"
        }
    },
    print: {
        title: 'Impresión de Reportes',
        btn: 'Imprimir'
    },
    report_tab_defender: "General",
    report_tab_torture: "Tortura",
    report_tab_measures: "Medidas",
    report_tab_victim: "Víctima",
    report_tab_files: "Adjuntos",
    resources: {
        institutions: {
            name: "Institución |||| Instituciones",
            fields: {
                name: "Nombre"
            }
        },
        users: {
            name: "Opitavaʼekue |||| Opitavaʼekue",
            fields: {
                firstname: "Nombre",
                lastname: "Apellido",
                institutionId: "Institución",
                position: "Cargo",
                employer: "Lugar de trabajo",
                number: "Número de defensor",
                role: "Rol",
                password: "Contraseña",
                confirmpassword: "Confirmar contraseña"
            }
        },
        reports: {
            name: "Reporte |||| Reportes",
            fields: {
                id: "Código",
                states: "Estado",
                status: "Estado",
                institutionId: "Institución",
                userId: "Reportante",
                userNumber: "Número del Reportante",
                accountName: "Reportante",
                standardDatetime: "Fecha",
                place: "Lugar de elab. del formulario",
                // placeId: "Lugar de elab. del formulario",
                placeIdOther: "Otro lugar de elab. del formulario",
                placeOther: "Otro lugar",
                source: "Reportante",
                sourceName: "Nombre del Reportante",
                sourceContact: "Contacto del Reportante",
                captureDatetime: "Fecha y hora del hecho",
                tortureOrMaltreatment: "Tipo de hecho",
                maltreatmentType: "¿Qué derecho se vulneró?",
                maltreatmentTypeOther: "Otro - ¿Qué otro derecho se vulneró?",
                factDescription: "Describa brevemente el hecho",
                tortureType: "Tortura",
                tortureTypeOther: "Otro tipo de tortura",
                tortureTypePhy: "Tipo de tortura física recibida",
                tortureTypePhyOther: "Otro tipo de tortura física recibida",
                tortureDamage: "¿En qué parte del cuerpo lo lastimaron?",
                tortureDamageOther: "Otra parte del cuerpo lastimada",
                tortureTypePsy: "Tipo de tortura psicológica recibida",
                tortureTypePsyOther: "Otro tipo de tortura psicológica recibida",
                tortureTypeSex: "Tipo de tortura sexual recibida",
                tortureTypeSexOther: "Otro tipo de tortura sexual recibida",
                tortureTime: "¿En qué momento ocurrió?",
                tortureTimeOther: "Otro - ¿En qué otro momento ocurrió?",
                tortureCustody: "Bajo qué custodia ocurrió",
                tortureCustodyOther: "Otro - Bajo qué otra custodia ocurrió",
                tortureAt: "¿Donde ocurrió el hecho?",
                tortureAtOther: "Otro - En qué otro lugar ocurrió el hecho",
                tortureAtPlaceId: "¿Donde ocurrió el hecho? - Lugar",
                tortureAtPlaceOther: "Otro lugar, institución o localidad no listado",
                aggressorId: "Si el agresor es agente, seleccione de donde",
                aggressorOther: "Otro - El agresor es agende de",
                aggressorInstitution: "Si el/la agresor/a pertenece a una institución, mencionarla aquí",
                aggressorName: "Nombre y apellido del agresor",
                medicName: "Nombre del médico forense",
                isPhoto: "¿Se realizaron tomas fotográficas?",
                urgentRequestType: "Medidas urgentes de protección solicitadas",
                urgentRequestTypeOther: "Otras medidas urgentes de protección solicitadas",
                caseNumber: "Número de causa",
                caseTitle: "Carátula",
                caseFiscalAgent: "Fiscal asignado",
                victimName: "Nombre y apellido",
                victimCin: "C.I.N",
                victimNationality: "Nacionalidad",
                victimBirthdate: "Fecha de nacimiento",
                victimAge: "Edad",
                victimGender: "Sexo",
                isVictimPregnant: "Embarazada",
                isVictimLgbti: "LGBTI",
                isVictimNative: "Pertenece a la comunidad indígena",
                victimTribe: "Pueblo originario",
                victimTribeOther: "Otro - Pueblo originario",
                isVictimDisabled: "Persona con discapacidad",
                victimDisabilityType: "Tipo de discapacidad",
                victimDisabilityTypeOther: "Otro tipo de discapacidad",
                isVictimAfro: "Grupo afrodescendiente",
                isWillingToFileReport: "¿La víctima está dispuesta a denunciar bajo consentimiento informado?",
                isWillingToFileReportOther: "Otro - ¿Está dispuesta a denunciar?",
                comment: "Comentario"
            }
        },
        places: {
            name: "Lugar |||| Lugares",
            fields: {
                departament: "Departamento",
                departament_number: "Nro. de departamento",
                district: "Distrito",
                description: "Nombre",
                name: "Nombre",
                lat: "Latitud",
                lng: "Longitud",
                placeGroupId: "Placegroup"
            }
        },
        placeGroups: {
            name: "Place group |||| Place groups",
            fields: {
                name: "Nombre"
            }
        },
        aggressors: {
            name: "Agresor |||| Agresores",
            fields: {
                name: "Nombre"
            }
        },
        locations: {
            name: "Localidad |||| Localidades",
            fields: {
                name: "Nombre"
            }
        },
        tortureTypes: {
            name: "Tipo de tortura |||| Tipos de tortura",
            fields: {
                description: "Descripción"
            }
        },
        states: {
            name: "Estado |||| Estados",
            fields: {
                description: "Descripción"
            }
        },
        logs: {
            name: "Registro |||| Registros",
            fields: {
                datetime: "Fecha y hora",
                userId: "Cuenta",
                type: "Tipo",
                model: "Entidad",
                changes: "Descripción"
            }
        },
        sessions: {
            name: "Sesión |||| Sesiones",
            fields: {
                agent: "Agente de usuario",
                datetime: "Fecha y hora",
                userId: "Cuenta",
                client: "Cliente",
                ip: "IP",
                token: "Token"
            }
        },
        settings: {
            name: "Configuración |||| Configuraciones",
            fields: {
                change_status_hours: "Cambio de estado en horas",
                languages: "Idiomas disponibles del sistema",
                daysOfInactivity: "Cantidad máxima de días de inactividad"
            }
        }
    }
};

export const myPt = {
    "": "",
    Unauthorized: "Sin autorización",
    bad_credentials: "Credenciales incorrectas",
    not_active_user: "Cuenta desactivada",
    datetime: "Fecha y hora de elab. del formulario",
    datetimeModified: "Fecha de última modificacion",
    datetimeModifiedGrid: "Última modificación",
    captureDatetime: "Date and time of capture",
    recover_password_title: "Recuperar senha",
    send_recover_password: "Enviar correo electrónico",
    email_recover_password: "Correo electrónico",
    email_recover_password_desc: "Te enviaremos un correo para reestablecer tu contraseña",
    email_recover_sended_1: "Te enviamos un correo con un enlace de recuperación de contraseña",
    email_recover_sended_2: "Deberás revisar tu correo y hacer clic en el enlace, eso te permitirá crear tu contraseña",
    email_recover_end: "Ir al inicio",
    forgot_password: "Esqueceu sua senha?",
    pwd: {
        min: "Mínimo de 10 caracteres",
        max: "Máximo de 30 caracteres",
        strong: 'La contraseña debe ser más fuerte (debe contener mayúsculas, minúsculas y números)',
        pass_not_match: 'Las contraseñas no coinciden'
    },
    old_password: "Contraseña actual",
    new_password: "Mudar senha",
    password: "Senha",
    confirmpassword: "Confirmar senha",
    changepassword_fail: 'Contraseña actual incorrecta',
    password: "Contraseña",
    restore_passwod: "Cambiar",
    code: "Ingrese el código recibido por correo electrónico",
    codeSent: "Código enviado por correo electrónico",
    requestCode: "Enviar código por correo electrónico",
    title_reason_rejected: "Rejeitar",
    reason_rejected: "Explique o motivo da rejeição.",
    label_comment_reason_rejected: "Commentario",
    save_button_reason_rejected: "Guardar",
    notification_report_evaluation: "Reporte enviado a evaluación",
    notification_report_accepted: "Reporte aceptado",
    notification_report_comments: "Reporte comentado",
    notification_report_rejected: "Reporte rechazado",
    notification_report_resolved: "Reporte resuelto",
    title_reason_comments: "Comentar",
    reason_comments: "Explique la razón del comentario",
    label_comment_reason_comments: "Comentar",
    save_button_reason_comments: "Guardar",
    close: "Cerrar",
    language: "Idiomas",
    maps: "Mapas",
    title_map: "Buscar reportes",
    filter_status: "Filtrar por estado",
    filter_defender: "Filtrar por reportante",
    filter_date: "Filtrar por fecha",
    filter_places: "Filtrar por lugar",
    point_place: "Lugar",
    point_captureDatetime: "Fecha hecho",
    point_gender: "Sexo",
    point_status: "Estado",
    all: "Todos",
    draft: "Borrador",
    evaluation: "Avaliação",
    accepted: "Aceito",
    comments: "Comentários",
    rejected: "Rejeitado",
    resolved: "Resolvido",
    stats: "Estatísticas",
    male: "Masculino",
    female: "Femenino",
    age_under18: "Menor a 18",
    age_between18_24: "18-24",
    age_between25_30: "25-30",
    age_between31_35: "31-25",
    age_between36_49: "36-49",
    age_between50_60: "50-60",
    age_over61: "Mayores a 60",
    stats_state: "Estado",
    stats_total: "Cantidad",
    stats_draft: "Borrador",
    stats_evaluation: "Avaliação",
    stats_accepted: "Aceito",
    stats_comments: "Comentários",
    stats_rejected: "Rejeitado",
    stats_resolved: "Resolvido",
    stats_title_reports: "Reclamações por estado",
    sex_title_reports: "Reclamações por sexo",
    stats_sex: "Sexo",
    age_title_reports: "Reclamações por idade (com base na data de idade)",
    stats_age: 'Idade',
    places_title_reports: "10 Lugareas con mais reclamações",
    stats_places: 'Lugar',
    aggressors_title_reports: "10 instituições mais reportadas (agressores)",
    visualizations: "Visualizações",
    total_report: "Total",
    by_states_reports: "Por estado",
    by_sex_reports: "Por sexo",
    by_places_reports: "10 lugares con más reportes",
    by_aggressors_reports: "10 agressores con más reportes",
    profile: "Mi perfil",
    settings: "Configuraciones",
    sessions: "Sesiones abiertas",
    mySessions: "Mis sesiones abiertas",
    token: "Token",
    action: "Acción",
    closeSessions: "Cerrar sesión",
    noOpenSessions: "Sin sesiones abiertas",
    reset: "Mudar senha",
    coordinator: "Coordinador",
    defender: "Defensor",
    observer: "Veedor",
    public: "Público",
    validation: {
        notification: "Reclamação validada"
    },
    LOGIN_FAILED: "Credenciales incorrectas",
    NOT_ACTIVE_USER: "La cuenta no se encuentra activa",
    otp: {
        title: "Autenticación de doble factor OTP",
        generate: {
            subtitle: "Configurar app de autenticación",
            step1: "1. Instalar app de 2FA(TOTP)",
            step2: "2. En la app elegir +",
            step3: "3. Escanear el QR que aparece abajo:",
            scanTitle: "Escanear código QR",
            typeTitle: "Si no puede escanear, use la siguiente llave en su app:",
            secretKey: "Llave secreta",
            verifyCodeTitle: "Código de verificación inicial",
            verifyCodeDesc: "Para guardar la configuracion ingrese el código de autenticación",
            otpToken: "Código de autenticación",
            authCode: "Código de verificación",
        },
        validate: {
            validateFailed: 'Código incorrecto',
            INVALID_OTP_TOKEN: 'Código incorrecto',
            otpTokenMissing: "Ingrese código de autenticación",
            tokenInputDesc: "Abra la app generadora de códigos para obtener el código de autenticación",
            tokenInput: "Código de autenticación",
            otpEnabledMissing: "Necesita configurar la autenticación 2FA para iniciar sesión"
        }
    },
    print: {
        title: 'Impresión de Reportes',
        btn: 'Imprimir'
    },
    report_tab_defender: "General",
    report_tab_torture: "Tortura",
    report_tab_measures: "Medidas",
    report_tab_victim: "Víctima",
    report_tab_files: "Adjuntos",
    resources: {
        institutions: {
            name: "Institución |||| Instituciones",
            fields: {
                name: "Nombre"
            }
        },
        users: {
            name: "Usuário |||| Usuários",
            fields: {
                firstname: "Nome",
                lastname: "Apelido",
                institutionId: "Institución",
                position: "Carga",
                employer: "Local de trabalho",
                number: "Número do defensor",
                role: "Papel",
                password: "Senha",
                confirmpassword: "Confirmar senha"
            }
        },
        reports: {
            name: "Reclamação |||| Reclamações",
            fields: {
                id: "Código",
                states: "Estado",
                status: "Estado",
                institutionId: "Institución",
                userId: "Reportante",
                userNumber: "Número del Reportante",
                accountName: "Reportante",
                standardDatetime: "Fecha",
                place: "Lugar de elab. del formulario",
                placeIdOther: "Otro lugar de elab. del formulario",
                placeOther: "Otro lugar",
                source: "Reportante",
                sourceName: "Nombre del Reportante",
                sourceContact: "Contacto del Reportante",
                captureDatetime: "Fecha y hora del hecho",
                tortureOrMaltreatment: "Tipo de hecho",
                maltreatmentType: "¿Qué derecho se vulneró?",
                maltreatmentTypeOther: "Otro - ¿Qué otro derecho se vulneró?",
                factDescription: "Describa brevemente el hecho",
                tortureType: "Tortura",
                tortureTypeOther: "Otro tipo de tortura",
                tortureTypePhy: "Tipo de tortura física recibida",
                tortureTypePhyOther: "Otro tipo de tortura física recibida",
                tortureDamage: "¿En qué parte del cuerpo lo lastimaron?",
                tortureDamageOther: "Otra parte del cuerpo lastimada",
                tortureTypePsy: "Tipo de tortura psicológica recibida",
                tortureTypePsyOther: "Otro tipo de tortura psicológica recibida",
                tortureTypeSex: "Tipo de tortura sexual recibida",
                tortureTypeSexOther: "Otro tipo de tortura sexual recibida",
                tortureTime: "¿En qué momento ocurrió?",
                tortureTimeOther: "Otro - ¿En qué otro momento ocurrió?",
                tortureCustody: "Bajo qué custodia ocurrió",
                tortureCustodyOther: "Otro - Bajo qué otra custodia ocurrió",
                tortureAt: "¿Donde ocurrió el hecho?",
                tortureAtOther: "Otro - En qué otro lugar ocurrió el hecho",
                tortureAtPlaceId: "¿Donde ocurrió el hecho? - Lugar",
                tortureAtPlaceOther: "Otro lugar, institución o localidad no listado",
                aggressorId: "Si el agresor es agente, seleccione de donde",
                aggressorOther: "Otro - El agresor es agende de",
                aggressorInstitution: "Si el/la agresor/a pertenece a una institución, mencionarla aquí",
                aggressorName: "Nombre y apellido del agresor",
                medicName: "Nombre del médico forense",
                isPhoto: "¿Se realizaron tomas fotográficas?",
                urgentRequestType: "Medidas urgentes de protección solicitadas",
                urgentRequestTypeOther: "Otras medidas urgentes de protección solicitadas",
                caseNumber: "Número de causa",
                caseTitle: "Carátula",
                caseFiscalAgent: "Fiscal asignado",
                victimName: "Nombre y apellido",
                victimCin: "C.I.N",
                victimNationality: "Nacionalidad",
                victimBirthdate: "Fecha de nacimiento",
                victimAge: "Edad",
                victimGender: "Sexo",
                isVictimPregnant: "Embarazada",
                isVictimLgbti: "LGBTI",
                isVictimNative: "Pertenece a la comunidad indígena",
                victimTribe: "Pueblo originario",
                victimTribeOther: "Otro - Pueblo originario",
                isVictimDisabled: "Persona con discapacidad",
                victimDisabilityType: "Tipo de discapacidad",
                victimDisabilityTypeOther: "Otro tipo de discapacidad",
                isVictimAfro: "Grupo afrodescendiente",
                isWillingToFileReport: "¿La víctima está dispuesta a denunciar bajo consentimiento informado?",
                isWillingToFileReportOther: "Otro - ¿Está dispuesta a denunciar?",
                comment: "Comentario"
            }
        },
        places: {
            name: "Lugar |||| Lugares",
            fields: {
                departament: "Departamento",
                departament_number: "Nro. de departamento",
                district: "Distrito",
                description: "Nome",
                name: "Nome",
                lat: "Latitude",
                lng: "Longitude",
                placeGroupId: "Placegroup"
            }
        },
        placeGroups: {
            name: "Place group |||| Place groups",
            fields: {
                name: "Nome"
            }
        },
        aggressors: {
            name: "Agresor |||| Agresores",
            fields: {
                name: "Nombre"
            }
        },
        locations: {
            name: "Localidad |||| Localidades",
            fields: {
                name: "Nombre"
            }
        },
        tortureTypes: {
            name: "Tipo de tortura |||| Tipos de tortura",
            fields: {
                description: "Descrição"
            }
        },
        states: {
            name: "Estado |||| Estados",
            fields: {
                description: "Descrição"
            }
        },
        logs: {
            name: "Registro |||| Registros",
            fields: {
                datetime: "Fecha y hora",
                userId: "Usuário",
                type: "Tipo",
                model: "Entidade",
                changes: "Descrição"
            }
        },
        sessions: {
            name: "Sesión |||| Sesiones",
            fields: {
                agent: "Agente de usuario",
                datetime: "Fecha y hora",
                userId: "Cuenta",
                client: "Cliente",
                ip: "IP",
                token: "Token"
            }
        },
        settings: {
            name: "Configuración |||| Configuraciones",
            fields: {
                change_status_hours: "Cambio de estado en horas",
                languages: "Idiomas disponibles del sistema",
                daysOfInactivity: "Cantidad máxima de días de inactividad"
            }
        }
    }
};
