import React, { Component } from 'react';
import Visualizations from "./components/Visualizations";
import './css/Visualization.css';

export default class Visualization extends Component {
	render() {
		return (
			<div className="content-visualization">
				<div className="title-visualization">
					Visualizaciones
                </div>
                <div>
					<Visualizations />
				</div>
			</div>
		)
	}
}