
import { token, userId } from "./storage";
import request from "./request";

const checkToken = (cb) => {
    return new Promise((resolve, reject) => {
        return resolve(true);
        // if (!userId || !token) {
        //     return reject('not auth data');
        // }
        // request({
        //     method: "get",
        //     url: `/Accounts/${userId}/accessTokens/${token}`
        // })
        //     .then(res => {
        //         resolve(true);
        //     })
        //     .catch(error => {
        //         if (cb) {
        //             cb(error);
        //         } else {
        //             console.log("\n\n[ checkToken ]\n\n")
        //             // localStorage.clear();
        //             // window.location.replace('/')
        //         }
        //         reject(false);
        //     });
    });
};

export default checkToken;