import React from "react";
import { connect } from "react-redux";
import { MenuItemLink, getResources, translate } from "react-admin";
import { withRouter } from "react-router-dom";
import VisualizationsIcon from "@material-ui/icons/Equalizer";
import StatsIcon from "@material-ui/icons/Receipt";
import PlaceIcon from "@material-ui/icons/Place";
import * as COLORS from "../constants/colors";

const styles = {
    MyMenu: {
        backgroundColor: COLORS.BG_CREAM
    }
};

const MyMenu = ({ translate, resources, onMenuClick, logout, permissions }) => {
    return (
        <div style={styles.MyMenu}>
            {resources.map(resource => {
                return (
                    <div key={Math.random()}>
                        {resource.hasList && (
                            <MenuItemLink
                                to={`/${resource.name}`}
                                leftIcon={
                                    resource.icon ? (
                                        <resource.icon />
                                    ) : (
                                        <StatsIcon />
                                    )
                                }
                                primaryText={translate(
                                    resource.name.includes("/")
                                        ? `resources.${
                                              resource.options.label
                                          }.name`
                                        : `resources.${resource.name}.name`,

                                    { smart_count: 2 }
                                )}
                                onClick={onMenuClick}
                            />
                        )}
                    </div>
                );
            })}

            <MenuItemLink
                to="/maps"
                leftIcon={<PlaceIcon />}
                primaryText={translate("maps")}
                onClick={onMenuClick}
            />

            <MenuItemLink
                to="/stats"
                leftIcon={<StatsIcon />}
                primaryText={translate("stats")}
                onClick={onMenuClick}
            />

            <MenuItemLink
                to="/visualizations"
                leftIcon={<VisualizationsIcon />}
                primaryText={translate("visualizations")}
                onClick={onMenuClick}
            />

            {/* <MenuItemLink
                to="/sessions"
                leftIcon={<PowerSettingsNew />}
                primaryText={translate("sessions")}
                onClick={onMenuClick}
            /> */}
        </div>
    );
};

const mapStateToProps = state => ({
    resources: getResources(state)
});

export default withRouter(connect(mapStateToProps)(translate(MyMenu)));
