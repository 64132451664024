import React from "react";
import { Route } from "react-router-dom";
import { Admin, Resource } from "react-admin";
import PostIcon from "@material-ui/icons/Book";
import UserIcon from "@material-ui/icons/Group";
import AddLocation from "@material-ui/icons/AddLocation";
import MapIcon from "@material-ui/icons/Map";
import SecurityIcon from "@material-ui/icons/Security";
import AlarmIcon from "@material-ui/icons/Alarm";
import PowerSettingsNew from "@material-ui/icons/PowerSettingsNew";
import IdleTimer from "react-idle-timer";
import spanishMessages from "ra-language-spanish";
import englishMessages from "ra-language-english";
import dataProvider from "../providers/dataProvider";
import authProvider from "../providers/authProvider";
import checkToken from "../utils/checkToken";
import {
    ReportCreate,
    ReportEdit,
    ReportList,
    ReportShow,
    AccountList,
    LogList,
    AccountShow,
    AccountEdit,
    AccountCreate,
    InstitutionList,
    InstitutionCreate,
    InstitutionEdit,
    InstitutionShow,
    PlaceCreate,
    PlaceShow,
    PlaceEdit,
    PlaceList,
    PlacegroupCreate,
    PlacegroupShow,
    PlacegroupEdit,
    PlacegroupList,
    LocationCreate,
    LocationShow,
    LocationEdit,
    LocationList,
    AggressorList,
    AggressorCreate,
    AggressorShow,
    AggressorEdit,
    Stats,
    Visualizations,
    Maps,
    ResetEmail,
    ResetForm,
    MyLoginPage,
    profile,
    SessionList,
    Images,
    Docs,
    Settings,
    ChangePassword
} from "../common";
import MyLayout from "../layout/MyLayout";
import { myEs, myEn, myGn, myPt } from "../constants/Translations";
import { IDLE_TIME } from "../constants/env";

import { userId, token } from "../utils/storage";
import logout from "../utils/session";
import { Domain } from "@material-ui/icons";

import "../css/defaultLayout.css";

const messages = {
    es: { ...spanishMessages, ...myEs },
    en: { ...englishMessages, ...myEn },
    gn: { ...spanishMessages, ...myGn },
    pt: { ...spanishMessages, ...myPt }
};

const i18nProvider = locale => messages[locale];

class Coordinator extends React.Component {
    constructor(props) {
        super(props);
        this.idleTimer = null;
    }

    idleLogout = () => {
        logout(userId, token);
    };

    render() {
        checkToken();

        return (
            <div>
                <IdleTimer
                    ref={ref => {
                        this.idleTimer = ref;
                    }}
                    element={document}
                    onIdle={() => this.idleLogout()}
                    debounce={250}
                    timeout={IDLE_TIME}
                />

                <Admin
                    loginPage={MyLoginPage}
                    dataProvider={dataProvider}
                    authProvider={authProvider}
                    locale="es"
                    i18nProvider={i18nProvider}
                    customRoutes={[
                        <Route
                            path="/visualizations"
                            component={Visualizations}
                        />,
                        <Route path="/stats" component={Stats} />,
                        <Route path="/maps" component={Maps} />,
                        <Route path="/images/:id" component={Images} />,
                        <Route path="/docs/:id" component={Docs} />,
                        <Route
                            key={userId}
                            path="/myProfile"
                            component={profile.edit}
                        />,
                        <Route
                            path="/settings"
                            component={Settings}
                        />,
                        <Route path="/resetForm" component={ResetForm} noLayout/>,
                        <Route path="/resetEmail/:resetPassKey" component={ResetEmail} />,
                        <Route path="/changePassword" component={ChangePassword} />
                    ]}
                    appLayout={MyLayout(this.props.lang)}
                >

                    <Resource
                        name="reports"
                        list={ReportList}
                        show={ReportShow}
                        create={ReportCreate}
                        edit={ReportEdit}
                        icon={PostIcon}
                    />

                    <Resource
                        name="users"
                        list={AccountList}
                        create={AccountCreate}
                        edit={AccountEdit}
                        show={AccountShow}
                        icon={UserIcon}
                    />
                    
                    <Resource
                        name="institutions"
                        list={InstitutionList}
                        create={InstitutionCreate}
                        show={InstitutionShow}
                        edit={InstitutionEdit}
                        icon={Domain}
                    />

                    <Resource
                        name="locations"
                        list={LocationList}
                        create={LocationCreate}
                        show={LocationShow}
                        edit={LocationEdit}
                        icon={MapIcon}
                    />

                    <Resource
                        name="places"
                        list={PlaceList}
                        create={PlaceCreate}
                        show={PlaceShow}
                        edit={PlaceEdit}
                        icon={AddLocation}
                    />

                    <Resource
                        name="placeGroups"
                        list={PlacegroupList}
                        create={PlacegroupCreate}
                        show={PlacegroupShow}
                        edit={PlacegroupEdit}
                        icon={AddLocation}
                    />

                    <Resource
                        name="aggressors"
                        list={AggressorList}
                        create={AggressorCreate}
                        show={AggressorShow}
                        edit={AggressorEdit}
                        icon={SecurityIcon}
                    />

                    <Resource
                        name="sessions"
                        list={SessionList}
                        icon={PowerSettingsNew}
                    />

                    <Resource
                        name="logs"
                        list={LogList}
                        icon={AlarmIcon}
                    />

                    <Resource name="states" />
                
                    <Resource name="settings" />
                </Admin>
            </div>
        );
    }
}

export default Coordinator;
