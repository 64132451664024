import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Button from "@material-ui/core/Button";
import { showNotification } from "react-admin";
import { push } from "react-router-redux";
import CommentBox from "./CommentBox";
import { Check } from "@material-ui/icons";
import request from "../utils/request";

class CommentButton extends Component {
    state = {
        reportId: null,
        type: null,
        comment: null,
        open: false
    };

    componentDidMount = () => {
        const { record } = this.props;
        this.setState({
            reportId: record.id,
            type: "comments",
            comment: ""
        });
    };

    handleSaveComment = (reportId, newState) => {
        const { push, showNotification } = this.props;
        request({
            method: "put",
            url: `/reports/${reportId}/states`,
            data: newState
        })
            .then(response => {
                showNotification("notification_report_comments");
                // to refresh
                push("/accounts"); 
                push("/reports");
            })
            .catch(e => {
                showNotification("ERROR VALIDACIÓN", "warning");
            });
    };

    render() {
        const { reportId, type, comment, translate } = this.state;
        return (
            <div style={{display: 'inline-block'}}>
                <Button
                    onClick={() => this.setState({ open: true }) }
                    size="small"
                    variant="contained"
                    style= {this.props.style}
                    color={this.props.color}
                >
                    <Check size="small" />
                    Comentar
                </Button>
                <CommentBox
                    reportId={reportId}
                    type={type}
                    comment={comment}
                    open={this.state.open}
                    setOpen={(val) => this.setState({ ...this.state, open: val }) }
                    handleSaveComment={this.handleSaveComment}
                    translationKeyDialogTitle={"title_reason_comments"}
                    translationKeyDialogContentText={"reason_comments"}
                    translationKeyTextField={"label_comment_reason_comments"}
                    translationKeyButtonSubmit={"save_button_reason_comments"}
                    translationKeyButtonClose={"close"}
                />
            </div>
        );
    }
}

CommentButton.propTypes = {
    push: PropTypes.func,
    record: PropTypes.object,
    showNotification: PropTypes.func
};

export default connect(
    null,
    {
        showNotification,
        push
    }
)(CommentButton);
