import React from "react";
import {
    List,
    Datagrid,
    TextField,
    Filter,
    NumberInput,
    ShowButton,
    EditButton,
    DeleteButton
} from "react-admin";
import { userId } from '../utils/storage';

const Filters = props => (
    <Filter {...props}>
        <NumberInput label="ID" source="id" reference="aggressor" />
    </Filter>
);

const AggressorList = props => (
    <List {...props} bulkActionButtons={false} filters={<Filters/>} sort={{ field: 'id', order: 'ASC' }} exporter={false}>
        <Datagrid>
            <TextField source="id" />
            <TextField source="name" />
            <ShowButton />
            <EditButton />
            {userId == 1 ? (
                <DeleteButton />
            ) : null}
        </Datagrid>
    </List>
);

export default AggressorList