import React, { Component } from "react";
import { Card, CardContent, Typography } from "@material-ui/core";
import { translate } from "react-admin";
import { Table } from "react-bootstrap";
import _ from "lodash";
import "../css/Stats.css";
import { vDefender } from "../utils/auth";
import request from '../utils/request';


class Stats extends Component {
    componentDidMount() {
        this.getStats();
    }

    state = {
        total: 0,
        states: {}, // se genera aquí
        tortureType: {},
        aggressors: [],
        places: [],
        ages: {},
        genders: {},
    };

    getStats = () => {
        request({
            method: 'get',
            url: `/reports/visualizations_admin`
        }).then((res) => {
            const states = {
                Borrador: res.states['draft'] || 0,
                Evaluación: res.states['evaluation'] || 0,
                Aceptado: res.states['accepted'] || 0,
                Comentarios: res.states['comments'] || 0,
                Rechazado: res.states['rejected'] || 0,
                Resuelto: res.states['resolved'] || 0
            }
            this.setState({
                total: res.total,
                tortureType: res.tortureType,
                aggressors: res.aggressors,
                places: res.places,
                ages: res.ages,
                genders: res.genders,
                states
            });
        });
    };

    sortable_array = object => {
        let sortable = [];

        for (let value in object) {
            sortable.push([value, object[value]]);
        }
        sortable.sort(function(a, b) {
            return b[1] - a[1];
        });
        return sortable;
    };

    porcentage_array = (array, total) => {
        let array_porcentage = [];
        let porcentage;
        let count = 0;
        let porcentage_value = 0;

        if (total === null || total === undefined) {
            total = 0;
            if (array[0] instanceof Array) {
                for (let value in array) {
                    total += array[value][1];
                }
            } else {
                for (let value in array) {
                    total += array[value];
                }
            }
            array_porcentage["total"] = total;
        }
        for (let value in array) {
            if (array[value] instanceof Array) {
                porcentage = (array[value][1] * 100) / total;
                porcentage_value = porcentage.toFixed(2);
                if(isNaN(porcentage)){
                    porcentage_value = 0;
                }
                array_porcentage[[count++][0]] = parseFloat(
                    porcentage_value
                );
            } else {
                porcentage = (array[value] * 100) / total;
                porcentage_value = porcentage.toFixed(2);
                if(isNaN(porcentage)){
                    porcentage_value = 0;
                }
                array_porcentage[value] = parseFloat(porcentage_value);
            }
        }
        return array_porcentage;
    };

    render() {
        const { translate } = this.props;
        const styles = {
            tileCard: {
                paddingTop: 20,
                paddingBottom: 20,
                textAlign: "center",
                fontSize: "22px",
                color: "#5e6ab1"
            }
        };

        let total_report = this.state.total;
        let states = this.state.states;
        let genders = this.state.genders;
        let ages = this.state.ages;

        let sortable_places = this.sortable_array(this.state.places);
        let places_porcentage = this.porcentage_array(sortable_places);
        let sortable_aggressors = this.sortable_array(this.state.aggressors);
        let aggressors_porcentage = this.porcentage_array(sortable_aggressors);
        let states_porcentage = this.porcentage_array(this.state.states, this.state.total);
        let genders_porcentage = this.porcentage_array(this.state.genders);
        let ages_porcentage = this.porcentage_array(this.state.ages);

        return (
            <React.Fragment>
                <Card>
                    <CardContent>
                        <Typography style={styles.tileCard}>
                            {translate("stats")}
                        </Typography>
                        <div className="section">
                            <div className="title-table">
                                {translate("stats_title_reports")}
                            </div>
                            <Table responsive>
                                <thead>
                                    <tr>
                                        <th>{translate("stats_state")}</th>
                                        <th>{translate("stats_total")}</th>
                                        <th>%</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>{translate("stats_draft")}</td>
                                        <td>{states.Borrador}</td>
                                        <td>
                                            {states_porcentage["Borrador"]}%
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>{translate("stats_evaluation")}</td>
                                        <td>{states.Evaluación}</td>
                                        <td>
                                            {states_porcentage["Evaluación"]}%
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>{translate("stats_accepted")}</td>
                                        <td>{states.Aceptado}</td>
                                        <td>
                                            {states_porcentage["Aceptado"]}%
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>{translate("stats_comments")}</td>
                                        <td>{states.Comentarios}</td>
                                        <td>
                                            {states_porcentage["Comentarios"]}%
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>{translate("stats_rejected")}</td>
                                        <td>{states.Rechazado}</td>
                                        <td>
                                            {states_porcentage["Rechazado"]}%
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>{translate("stats_resolved")}</td>
                                        <td>{states.Resuelto}</td>
                                        <td>
                                            {states_porcentage["Resuelto"]}%
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>{translate("total_report")}</td>
                                        <td>{total_report}</td>
                                        <td>100%</td>
                                    </tr>
                                </tbody>
                            </Table>
                        </div>
                        <br />
                        <br />
                        <div className="section">
                            <div className="title-table">
                                {translate("sex_title_reports")}
                            </div>
                            <Table responsive>
                                <thead>
                                    <tr>
                                        <th>{translate("stats_sex")}</th>
                                        <th>{translate("stats_total")}</th>
                                        <th>%</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>{translate("male")}</td>
                                        <td>{genders.Masculino}</td>
                                        <td>
                                            {genders_porcentage["Masculino"]}%
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>{translate("female")}</td>
                                        <td>{genders.Femenino}</td>
                                        <td>
                                            {genders_porcentage["Femenino"]}%
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>{translate("total_report")}</td>
                                        <td>{genders_porcentage["total"]}</td>
                                        <td>100%</td>
                                    </tr>
                                </tbody>
                            </Table>
                        </div>
                        <br />
                        <br />
                        <div className="section">
                            <div className="title-table">
                                {translate("age_title_reports")}
                            </div>
                            <Table responsive>
                                <thead>
                                    <tr>
                                        <th>{translate("stats_age")}</th>
                                        <th>{translate("stats_total")}</th>
                                        <th>%</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>{translate("age_under18")}</td>
                                        <td>{ages["<18"]}</td>
                                        <td>{ages_porcentage["<18"]}%</td>
                                    </tr>
                                    <tr>
                                        <td>{translate("age_between18_24")}</td>
                                        <td>{ages["18-24"]}</td>
                                        <td>{ages_porcentage["18-24"]}%</td>
                                    </tr>
                                    <tr>
                                        <td>{translate("age_between25_30")}</td>
                                        <td>{ages["25-30"]}</td>
                                        <td>{ages_porcentage["25-30"]}%</td>
                                    </tr>
                                    <tr>
                                        <td>{translate("age_between31_35")}</td>
                                        <td>{ages["31-35"]}</td>
                                        <td>{ages_porcentage["31-35"]}%</td>
                                    </tr>
                                    <tr>
                                        <td>{translate("age_between36_49")}</td>
                                        <td>{ages["36-49"]}</td>
                                        <td>{ages_porcentage["36-49"]}%</td>
                                    </tr>
                                    <tr>
                                        <td>{translate("age_between50_60")}</td>
                                        <td>{ages["50-60"]}</td>
                                        <td>{ages_porcentage["50-60"]}%</td>
                                    </tr>
                                    <tr>
                                        <td>{translate("age_over61")}</td>
                                        <td>{ages[">61"]}</td>
                                        <td>{ages_porcentage[">61"]}%</td>
                                    </tr>
                                    <tr>
                                        <td>{translate("total_report")}</td>
                                        <td>{ages_porcentage["total"]}</td>
                                        <td>100%</td>
                                    </tr>
                                </tbody>
                            </Table>
                        </div>
                        <br />
                        <br />
                        <div className="section">
                            <div className="title-table">
                                {translate("places_title_reports")}
                            </div>
                            <Table responsive>
                                <thead>
                                    <tr>
                                        <th>{translate("stats_places")}</th>
                                        <th>{translate("stats_total")}</th>
                                        <th>%</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            {Array.isArray(sortable_places[0])
                                                ? sortable_places[0][0]
                                                : "-"}
                                        </td>
                                        <td>
                                            {Array.isArray(sortable_places[0])
                                                ? sortable_places[0][1]
                                                : "-"}
                                        </td>
                                        <td>
                                            {places_porcentage[0]
                                                ? places_porcentage[0] + "%"
                                                : "0%"}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            {Array.isArray(sortable_places[1])
                                                ? sortable_places[1][0]
                                                : "-"}
                                        </td>
                                        <td>
                                            {Array.isArray(sortable_places[1])
                                                ? sortable_places[1][1]
                                                : "-"}
                                        </td>
                                        <td>
                                            {places_porcentage[1]
                                                ? places_porcentage[1] + "%"
                                                : "0%"}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            {Array.isArray(sortable_places[2])
                                                ? sortable_places[2][0]
                                                : "-"}
                                        </td>
                                        <td>
                                            {Array.isArray(sortable_places[2])
                                                ? sortable_places[2][1]
                                                : "-"}
                                        </td>
                                        <td>
                                            {places_porcentage[2]
                                                ? places_porcentage[2] + "%"
                                                : "0%"}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            {Array.isArray(sortable_places[3])
                                                ? sortable_places[3][0]
                                                : "-"}
                                        </td>
                                        <td>
                                            {Array.isArray(sortable_places[3])
                                                ? sortable_places[3][1]
                                                : "-"}
                                        </td>
                                        <td>
                                            {places_porcentage[3]
                                                ? places_porcentage[3] + "%"
                                                : "0%"}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            {Array.isArray(sortable_places[4])
                                                ? sortable_places[4][0]
                                                : "-"}
                                        </td>
                                        <td>
                                            {Array.isArray(sortable_places[4])
                                                ? sortable_places[4][1]
                                                : "-"}
                                        </td>
                                        <td>
                                            {places_porcentage[4]
                                                ? places_porcentage[4] + "%"
                                                : "0%"}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            {Array.isArray(sortable_places[5])
                                                ? sortable_places[5][0]
                                                : "-"}
                                        </td>
                                        <td>
                                            {Array.isArray(sortable_places[5])
                                                ? sortable_places[5][1]
                                                : "-"}
                                        </td>
                                        <td>
                                            {places_porcentage[5]
                                                ? places_porcentage[5] + "%"
                                                : "0%"}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            {Array.isArray(sortable_places[6])
                                                ? sortable_places[6][0]
                                                : "-"}
                                        </td>
                                        <td>
                                            {Array.isArray(sortable_places[6])
                                                ? sortable_places[6][1]
                                                : "-"}
                                        </td>
                                        <td>
                                            {places_porcentage[6]
                                                ? places_porcentage[6] + "%"
                                                : "0%"}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            {Array.isArray(sortable_places[7])
                                                ? sortable_places[7][0]
                                                : "-"}
                                        </td>
                                        <td>
                                            {Array.isArray(sortable_places[7])
                                                ? sortable_places[7][1]
                                                : "-"}
                                        </td>
                                        <td>
                                            {places_porcentage[7]
                                                ? places_porcentage[7] + "%"
                                                : "0%"}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            {Array.isArray(sortable_places[8])
                                                ? sortable_places[8][0]
                                                : "-"}
                                        </td>
                                        <td>
                                            {Array.isArray(sortable_places[8])
                                                ? sortable_places[8][1]
                                                : "-"}
                                        </td>
                                        <td>
                                            {places_porcentage[8]
                                                ? places_porcentage[8] + "%"
                                                : "0%"}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            {Array.isArray(sortable_places[9])
                                                ? sortable_places[9][0]
                                                : "-"}
                                        </td>
                                        <td>
                                            {Array.isArray(sortable_places[9])
                                                ? sortable_places[9][1]
                                                : "-"}
                                        </td>
                                        <td>
                                            {places_porcentage[9]
                                                ? places_porcentage[9] + "%"
                                                : "0%"}
                                        </td>
                                    </tr>
                                </tbody>
                            </Table>
                        </div>
                        <br />
                        <br />
                        <div className="section">
                            <div className="title-table">
                                {translate("aggressors_title_reports")}
                            </div>
                            <Table responsive>
                                <thead>
                                    <tr>
                                        <th>{translate("stats_places")}</th>
                                        <th>{translate("stats_total")}</th>
                                        <th>%</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            {Array.isArray(sortable_aggressors[0])
                                                ? sortable_aggressors[0][0]
                                                : "-"}
                                        </td>
                                        <td>
                                            {Array.isArray(sortable_aggressors[0])
                                                ? sortable_aggressors[0][1]
                                                : "-"}
                                        </td>
                                        <td>
                                            {aggressors_porcentage[0]
                                                ? aggressors_porcentage[0] + "%"
                                                : "0%"}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            {Array.isArray(sortable_aggressors[1])
                                                ? sortable_aggressors[1][0]
                                                : "-"}
                                        </td>
                                        <td>
                                            {Array.isArray(sortable_aggressors[1])
                                                ? sortable_aggressors[1][1]
                                                : "-"}
                                        </td>
                                        <td>
                                            {aggressors_porcentage[1]
                                                ? aggressors_porcentage[1] + "%"
                                                : "0%"}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            {Array.isArray(sortable_aggressors[2])
                                                ? sortable_aggressors[2][0]
                                                : "-"}
                                        </td>
                                        <td>
                                            {Array.isArray(sortable_aggressors[2])
                                                ? sortable_aggressors[2][1]
                                                : "-"}
                                        </td>
                                        <td>
                                            {aggressors_porcentage[2]
                                                ? aggressors_porcentage[2] + "%"
                                                : "0%"}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            {Array.isArray(sortable_aggressors[3])
                                                ? sortable_aggressors[3][0]
                                                : "-"}
                                        </td>
                                        <td>
                                            {Array.isArray(sortable_aggressors[3])
                                                ? sortable_aggressors[3][1]
                                                : "-"}
                                        </td>
                                        <td>
                                            {aggressors_porcentage[3]
                                                ? aggressors_porcentage[3] + "%"
                                                : "0%"}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            {Array.isArray(sortable_aggressors[4])
                                                ? sortable_aggressors[4][0]
                                                : "-"}
                                        </td>
                                        <td>
                                            {Array.isArray(sortable_aggressors[4])
                                                ? sortable_aggressors[4][1]
                                                : "-"}
                                        </td>
                                        <td>
                                            {aggressors_porcentage[4]
                                                ? aggressors_porcentage[4] + "%"
                                                : "0%"}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            {Array.isArray(sortable_aggressors[5])
                                                ? sortable_aggressors[5][0]
                                                : "-"}
                                        </td>
                                        <td>
                                            {Array.isArray(sortable_aggressors[5])
                                                ? sortable_aggressors[5][1]
                                                : "-"}
                                        </td>
                                        <td>
                                            {aggressors_porcentage[5]
                                                ? aggressors_porcentage[5] + "%"
                                                : "0%"}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            {Array.isArray(sortable_aggressors[6])
                                                ? sortable_aggressors[6][0]
                                                : "-"}
                                        </td>
                                        <td>
                                            {Array.isArray(sortable_aggressors[6])
                                                ? sortable_aggressors[6][1]
                                                : "-"}
                                        </td>
                                        <td>
                                            {aggressors_porcentage[6]
                                                ? aggressors_porcentage[6] + "%"
                                                : "0%"}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            {Array.isArray(sortable_aggressors[7])
                                                ? sortable_aggressors[7][0]
                                                : "-"}
                                        </td>
                                        <td>
                                            {Array.isArray(sortable_aggressors[7])
                                                ? sortable_aggressors[7][1]
                                                : "-"}
                                        </td>
                                        <td>
                                            {aggressors_porcentage[7]
                                                ? aggressors_porcentage[7] + "%"
                                                : "0%"}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            {Array.isArray(sortable_aggressors[8])
                                                ? sortable_aggressors[8][0]
                                                : "-"}
                                        </td>
                                        <td>
                                            {Array.isArray(sortable_aggressors[8])
                                                ? sortable_aggressors[8][1]
                                                : "-"}
                                        </td>
                                        <td>
                                            {aggressors_porcentage[8]
                                                ? aggressors_porcentage[8] + "%"
                                                : "0%"}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            {Array.isArray(sortable_aggressors[9])
                                                ? sortable_aggressors[9][0]
                                                : "-"}
                                        </td>
                                        <td>
                                            {Array.isArray(sortable_aggressors[9])
                                                ? sortable_aggressors[9][1]
                                                : "-"}
                                        </td>
                                        <td>
                                            {aggressors_porcentage[9]
                                                ? aggressors_porcentage[9] + "%"
                                                : "0%"}
                                        </td>
                                    </tr>
                                </tbody>
                            </Table>
                        </div>
                    </CardContent>
                </Card>
            </React.Fragment>
        );
    }
}

export default translate(Stats);
