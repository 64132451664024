import React, { Component } from 'react';
import {
	Card,
	CardTitle,
	Row,
	Col,
	CardImg,
	Container,
	CardBody,
	CardLink
} from 'reactstrap';
// import IMG_MANOS from '../../../assets/logo_manos.png';
import IMG_POCKET from '../../../assets/image/pocket_defensores_web.jpg';
import IMG_AFICHE from '../../../assets/image/afiche_defensores_web.jpg';
import Player from './components/Player';

const audio = '/mp3/Defensores_podcast_web.mp3';

export default class Resources extends Component {
	render() {
		const styles = {
			title_resources: {
				paddingTop: "90px",
				marginRight: "auto",
				marginBottom: '10px',
				marginLeft: "auto",
				textAlign: "center",
				color: "white",
				fontSize: "30px",
    			fontFamily: 'Roboto Slab',
			},
			card: {
				paddingTop: '10px',
				paddingRight: '10px',
				paddingLeft: '10px',
				marginBottom: '15px',
				border: "1px solid #d3d3d3",
				borderRadius: "15px"
			},
			card_title: {
				fontSize: 20,
				fontFamily: 'Roboto Slab',
				marginTop: "10px",
				textAlign: "left",
				color: "#5e6ab1"
			},
			card_link: {
				paddingLeft: "35%",
			},
			card_text: {
				paddingTop: "20px",
				paddingLeft: "10%",
				paddingRight: "10%"
			}
		};

		return (
			<React.Fragment>
				<div style={styles.title_resources}>
					Recursos did&aacute;cticos
				</div>
				<Container>
					<Row>
						<Col>
							<Card style={styles.card}>
								<CardBody>
									<CardTitle style={styles.card_title}>Instructivo de bolsillo</CardTitle>
									<CardImg top height="80%" src={IMG_POCKET} title="Instructivo" alt="Instructivo de bolsillo en PDF para descargar" />
									<CardLink style={styles.card_link} href="/pdf/pocket_defensores.pdf">Descargar</CardLink>
								</CardBody>
							</Card>
						</Col>
						<Col>
							<Card style={styles.card}>
								<CardBody>
									<CardTitle style={styles.card_title}>Afiche explicativo</CardTitle>
									<CardImg top height="80%" src={IMG_AFICHE} title="Afiche" alt="Afiche explicativo en PDF para descargar" />
									<CardLink style={styles.card_link} href="/pdf/afiche_defensores.pdf">Descargar</CardLink>
								</CardBody>
							</Card>
						</Col>
						<Col>
							<Card style={styles.card}>
								<CardBody>
									<CardTitle style={styles.card_title}>Testimonio</CardTitle>
									<Player audio={audio} />
									<CardLink style={styles.card_link} href={audio} download>Descargar</CardLink>
								</CardBody>
							</Card>
						</Col>
					</Row>
				</Container>
			</React.Fragment>
		)
	}
}
